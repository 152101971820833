import axiosInstance from '../RestClient';

const BASE_URI = '/assetSale';
class AssetSaleService {
	async list() {
		const uri = `${BASE_URI}/`;
		try {
			const response = await axiosInstance.get(uri);
			return response;
		} catch (e) {
			console.error(e);
		}
	}
	async search(query) {
		const uri = `${BASE_URI}/search?${query}`;
		const response = await axiosInstance.get(uri);
		return response;
	}
	async create(payload) {
		const response = await axiosInstance.post(`${BASE_URI}/`, payload);
		return response;
	}
	async edit(recordId, payload) {
		const response = await axiosInstance.put(`${BASE_URI}/${recordId}`, payload);
		return response;
	}
	
	async getDetails(recordId) {
		const uri = `${BASE_URI}/${recordId}`;
		const response = await axiosInstance.get(uri);
		return response;
	}
	async uploadDocument(formData) {
		const multiPart = { 'content-type': 'multipart/form-data' };
		const uri = `${BASE_URI}/uploadDocument`;
		const response = await axiosInstance.post(uri, formData, { ...multiPart });
		return response;
	}
	async getSalesForProperty(propertyId) {
		const uri = `${BASE_URI}/minesales/${propertyId}`;
		const response = await axiosInstance.get(uri);
		return response;
	}
	async getDebtsForAssetSale(assetSaleId) {
		const uri = `${BASE_URI}/assetSaleDebts/${assetSaleId}`;
		const response = await axiosInstance.get(uri);
		return response;
	}
	async delete(recordId) {
		const uri = `${BASE_URI}/${recordId}`;
		const response = await axiosInstance.delete(uri);
		return response;
	}
}

export default new AssetSaleService();

import React, { useContext,useEffect, useReducer, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Helmet } from 'react-helmet-async';
import { MdLibraryAdd } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import CustomTypeahead from '../../components/CustomTypeahead';
import FormatedNumberInput from '../../components/FormatedNumberInput';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import GenericDropdown from '../../components/SelectDropdown';
import AssetService from '../../services/asset.service';
import AssetSaleService from '../../services/assetSale.service';
import { getError } from '../../utils';
import moment from 'moment';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_SUCCESS':
		return { ...state, asset: action.payload, loadingFetch: false };
	case 'FETCH_FAIL':
	case 'FETCH_ASSETS_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_ASSETS_SUCCESS':
		return { ...state, assets: action.payload, loadingFetch: false };
	case 'FETCH_ASSETS_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false,
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };
	default:
		return state;
	}
};
function EditAssetSale() {
	const params = useParams();
	const { id } = params;
	const { dispatch: ctxDispatch } = useContext(Store);

	const [{ loadingFetch, loadingCreate, assets ,errorFetch}, dispatch] = useReducer(reducer, {
		assets: [],
		loadingFetch: true,
		loadingCreate: false,
		errorFetch: '',
	});


	const navigate = useNavigate();
	const currencies = ['USD', 'UYU'];
	const [currency, setCurrency] = useState();
	const [amount, setAmount] = useState(0);
	const [document, setDocument] = useState();
	const [purchaser, setPurchaser] = useState();
	const [initialPayment, setInitialPayment] = useState(0);
	const [uploading, setUploading] = useState(false);
	const [isInvalid, setIsInvalid] = useState(false);
	const [asset, setAsset] = useState();
	const [multipleLots, setMultipleLots] = useState();
	const [lot, setLot] = useState();
	const [date, setDate] = useState();




	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_ASSETS_REQUEST' });
			try {
				const result = await AssetService.list();
				dispatch({ type: 'FETCH_ASSETS_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_ASSETS_FAIL', payload: error.message });
			}
		};
		const fetchAssetSale = async () => {
			const result = await AssetSaleService.getDetails(id);
			dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
			setCurrency(result.data.currency);
			setDate(result.data.date);
			setAmount(result.data.amount);
			setDocument(result.data.document);
			setPurchaser(result.data.purchaser);
			setInitialPayment([result.data.initialPayment]);
			setAsset([result.data.asset]);
			setCurrency(result.data.currency);
			setMultipleLots(result.data.multipleLots);
			setLot(result.data.lot);
		};
		fetchAssetSale();
		fetchData();
	}, []);

	const EditAssetSaleHandler = async () => {
		if (!lot) {
			toast.error('Debe completar el campo padrón.');
			return;
		}
		try {
			dispatch({ type: 'UPDATE_REQUEST' });
			const { data } = await AssetSaleService.edit(id, {
				document,
				asset: asset,
				lot,
				date,
			});
			dispatch({
				type: 'UPDATE_SUCCESS',
			});
			ctxDispatch({ type: 'CONTACT_UPDATE_SUCCESS', payload: data });
			toast.success('Activo actualizado Correctamente');
			navigate(-1);
		} catch (ex) {
			dispatch({ type: 'CREATE_FAIL' });
			toast.error(getError(ex));
		}
	};

	const uploadDocument = async () => {
		setUploading(true);
		if (document) {
			const file = document[0];
			const formData = new FormData();
			let r = Math.random().toString(36).substring(7);
			formData.append('file', file, file.name.replaceAll(' ') + r);
			formData.append('documentId', document._id);
			try {
				const response = await AssetSaleService.uploadDocument(formData);
				setDocument(response.data);
				setUploading(false);
				toast.success('Archivo Subido');
			} catch (ex) {
				setUploading(false);
				toast.error(getError(ex));
			}
		}
		{
			toast.error('Debe seleccionar un archivo');
		}
	};
	const handleSelectedCurrency = (e) => {
		setCurrency(e.value);
	};

	const handleAssetChange = (selected) => {
		const selectedAsset = selected[0];
		const isValid = selectedAsset ? assets.includes(selectedAsset) : false;
		setIsInvalid(!isValid);
		setAsset(selected[0]);
		if (selected[0]) {
			setMultipleLots(selected[0].multipleLots);
			if (!selected[0].multipleLots) {
				setLot(selected[0].lot);
			}
		}
	};
	return (
		<div>
			<Modal size='lg' show={true} animation={false}>
				{(loadingCreate || loadingFetch || uploading) ? (
					<LoadingBox></LoadingBox>
				) : (
					errorFetch ? (
						<MessageBox variant='danger'>{errorFetch}</MessageBox>
					) : null
				)}				<Helmet>
					<title>Venta de activo</title>
				</Helmet>
				<div className='container admin-con align-items-center'>
					<h1>
						<MdLibraryAdd></MdLibraryAdd>Venta de activo
					</h1>
					<Form className='admin-form' onSubmit={(e) => e.preventDefault()}>
						<Row className='mb-3'>
							<Form.Group className='mb-3 col-3 form-group required' controlId='Date'>
								<Form.Label>Fecha</Form.Label>
								<Form.Control
									type='date'
									value={moment.utc(date).format('YYYY-MM-DD')}
									onChange={(e) => setDate(e.target.value)}
									disabled
								></Form.Control>
							</Form.Group>
							<Form.Group as={Col} className='mb-3 col-6 form-group required' controlId='name'>
								<Form.Label>Monto</Form.Label>
								<FormatedNumberInput
									type='number'
									disabled={true}
									value={amount}
									onChange={(e) => setAmount(e.target.value)}
									min={0}
								></FormatedNumberInput>
							</Form.Group>
							<Form.Group as={Col} className='mb-3 col-6' controlId='asset'>
								<Form.Label>Activo</Form.Label>
								<CustomTypeahead
									id='assets'
									labelKey={(option) => `${option.name}`}
									onChange={handleAssetChange}
									options={assets}
									placeholder='Buscar Activo... '
									selected={asset ? [asset] : null} // se agrega []
									isInvalid={isInvalid}
									linkToCreate='/AdminScreen/newAsset'
									newOption='nuevo Activo Fijo'
								/>
								{isInvalid && <div style={{ color: 'red' }}>Activo invalido</div>}
							</Form.Group>
							<Form.Group className='mb-3 col-6' controlId='periodicity'>
								<Form.Label>Padrón</Form.Label>
								<Form.Control
									type='text'
									defaultValue={lot}
									disabled={!multipleLots}
									onChange={(e) => setLot(e.target.value)}
								></Form.Control>
							</Form.Group>
						</Row>
						<Row>
							<Form.Group as={Col} className='mb-3 col-6 form-group required' controlId='email'>
								<Form.Label>Comprador</Form.Label>
								<Form.Control
									defaultValue={purchaser ? purchaser.name : ''}
									disabled
								></Form.Control>
							</Form.Group>
							<Form.Group as={Col} className='mb-3 col-4' controlId='currency'>
								<GenericDropdown
									label='Moneda'
									items={currencies}
									selected={currency}
									onChange={handleSelectedCurrency}
								/>
							</Form.Group>
							<Form.Group
								as={Col}
								className='mb-3 col-6 form-group required'
								controlId='initialPayment'
							>
								<Form.Label>Entrega Inicial</Form.Label>
								<FormatedNumberInput
									type='number'
									onChange={(e) => setInitialPayment(e.target.value)}
									min={0}
									max={amount}
									value={initialPayment}
									disabled={true}
								></FormatedNumberInput>
							</Form.Group>
						</Row>
						<Row>
							<Form.Group className='col-4 mb-3' controlid='documento'>
								<Form.Label>Documento</Form.Label>
								<Form.Control
									type='file'
									required
									value={document}
									onChange={(e) => setDocument(e.target.files)}
								></Form.Control>
								<Form.Control.Feedback type='invalid'>Seleccione Archivo</Form.Control.Feedback>
							</Form.Group>
							<Button
								className='col-2'
								style={{ height: '30px', marginTop: '32px', padding: '0' }}
								onClick={() => uploadDocument(document)}
							>
								subir
							</Button>
						</Row>
						<Row className='modal-btns'>
							<Col>
								<Button className='cancelar' onClick={() => navigate(-1)}>
									Cancelar
								</Button>
							</Col>
							<Col>
								<Button className='guardar' onClick={EditAssetSaleHandler}>
									Guardar
								</Button>
							</Col>
						</Row>
					</Form>
				</div>
			</Modal>
		</div>
	);
}
export default EditAssetSale;
import React, { useLayoutEffect, useReducer } from 'react';
import { createGlobalStyle } from 'styled-components';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import MessageBox from './components/MessageBox';
import SignInScreen from './screens/SignInScreen';
import ResetPassword from './screens/ResetPassword';
import SignupScreen from './screens/SignUpScreen.jsx';
import ProfileScreen from './screens/ProfileScreen';
import AdminScreen3 from './screens/AdminUsers';
import AdminFooter from './components/AdminFooter';
import ScrollToTop from './components/ScrollToTop';
import ChangePassword from './screens/ChangePassword';
import VerifyUser from './screens/VerifyUser';
import AdminNavbar from './components/AdminNavbar';
import TabNav from './components/TabNav';
import AccountDetail from './screens/AccountDetail/AccountDetail';
import AdminConfigScreen from './screens/AdminConfigScreen/AdminConfigScreen';
import LoadingBox from './components/LoadingBox';
import AddUser from './screens/Users/NewUser';
import EditUser from './screens/Users/EditUser';
import UsersList from './screens/Users/UsersList';
import AddContact from './screens/Contacts/NewContact';
import EditContact from './screens/Contacts/EditContact';
import ContactList from './screens/Contacts/ContactList';
import ContactInfo from './screens/Contacts/ContactInfo';
import AddAccount from './screens/Accounts/NewAccount';
import EditAccount from './screens/Accounts/EditAccount';
import AccountList from './screens/Accounts/AccountList';
import DebtDetails from './screens/DebtDetail/DebtDetails';
import DebtList from './screens/Debts/DebtList';
import AddDebt from './screens/Debts/NewDebt';
import EditDebt from './screens/Debts/EditDebt';
import EditFirstDueDate from './screens/Debts/EditFirstDueDate';
import CreditDetails from './screens/CreditDetail/CreditDetails';
import CreditList from './screens/Credits/CreditList';
import AddCredit from './screens/Credits/NewCredit';
import EditCredit from './screens/Credits/EditCredit';
import PaymentList from './screens/Payments/PaymentList';
import AddPayment from './screens/Payments/NewPayment';
import EditPayment from './screens/Payments/EditPayment';
import PaymentDetail from './screens/Payments/PaymentDetail';
import CreditPaymentList from './screens/CreditPayments/CreditPaymentList';
import AddCreditPayment from './screens/CreditPayments/NewCreditPayment';
import EditCreditPayment from './screens/CreditPayments/EditCreditPayment';
import CreditPaymentDetail from './screens/CreditPayments/CreditPaymentDetail';
import ContractDetail from './screens/ContractDetail/ContractDetail';
import ContractList from './screens/Contracts/ContractList';
import AddContract from './screens/Contracts/NewContract';
import EditContract from './screens/Contracts/EditContract';
import InterestList from './screens/Interests/InterestList';
import AddInterest from './screens/Interests/NewInterest';
import EditInterest from './screens/Interests/EditInterest';
import InterestInfo from './screens/Interests/InterestInfo';
import AssetList from './screens/Assets/AssetList';
import AddAsset from './screens/Assets/NewAsset';
import EditAsset from './screens/Assets/EditAsset';
import AssetDetails from './screens/AssetDetail/AssetDetails';
import AddAssetSale from './screens/AssetSales/AddAssetSale.jsx';
import EditAssetSale from './screens/AssetSales/EditAssetSale.jsx';
import AssetSaleDetail from './screens/AssetSaleDetail/AssetSaleDetail.jsx';
import BillsList from './screens/Bills/BillsList';
import AddBill from './screens/Bills/NewBills';
import EditBill from './screens/Bills/EditBills';
import BillDetail from './screens/Bills/BillDetail';
import ConfirmPayment from './screens/Bills/ConfirmPayment';
import NewInterestNew from './screens/Interests/NewInterestNew';
import NewPaymentNew from './screens/Payments/NewPaymentNew';
import NewBillsNew from './screens/Bills/NewBillsNew';
import NewCreditPaymentNew from './screens/CreditPayments/NewCreditPaymentNew';
import NewWarranty from './screens/Warranties/addWarranty';
import NewDebtDue from './screens/DebtDues/NewDebtDue';
import Reports from './screens/Reports';
import Predictions from './screens/Predictions.js';
import colorService from './services/color.service.js';
import { CustomBrowserRouter } from './CustomBrowserRouter.jsx';
import { history } from './history.js';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, colors: action.payload, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	default:
		return state;
	}
};



function App() {
	const [{ loading, error, colors }, dispatch] = useReducer(reducer, {
		colors: {},
		loading: true,
		error: '',
	});

	//getting theme colors-to do: load before page

	useLayoutEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const { data } = await colorService.list();
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: error.message });
			}
		};
		fetchData();
	}, []);

	//setting theme colors
	const GlobalStyles = createGlobalStyle`
:root {
  --primaryColor: ${colors.primaryColor};
  --secondaryColor: ${colors.secondaryColor};;
  --background-color: ${colors.backgroundColor};
  --utilsColor:${colors.utilsColor};
  --navFontColor:${colors.navFontColor};
  --shadowNavColor:${colors.shadowNavColor};
  --IconsColor:${colors.iconsColor};
  --titlesColor:${colors.titlesColor}
  --btnFontColor:${colors.btnFontColor}
}
`;

	return (
		<>
			{' '}
			{loading ? (
				<LoadingBox></LoadingBox>
			) : error ? (
				<MessageBox variant='danger'>{error}</MessageBox>
			) : (
				<>
					<GlobalStyles />
					{/* <BrowserRouter> */}
					<CustomBrowserRouter history={history}>
						<ScrollToTop></ScrollToTop>
						<div className='d-flex flex-column site-container'>
							<AdminNavbar></AdminNavbar>
							<main>
								<TabNav></TabNav>
								<Routes>
									<Route path='/' element={<SignInScreen />}></Route>
									{/* <Route path='/clientscreen' element={<ClientScreen />}></Route>
                  <Route path='/cart' element={<CartScreen />}></Route>
                  <Route
                    path='/product/:slug'
                    element={<ProductScreen />}
                  ></Route> */}
									<Route path='/signin' element={<SignInScreen />}></Route>
									<Route
										path='/reset-password/'
										element={<ResetPassword />}
									></Route>
									{/* <Route path='/reset-password/:email' element={<ResetPassword />}></Route> */}
									{/* <Route
                    path='/shipping'
                    element={<ShippingAddressScreen />}
                  ></Route> */}
									{/* <Route
                    path='/shippingCalculator'
                    element={<ShippingCalculator />}
                  ></Route> */}
									<Route path='/signup' element={<SignupScreen />}></Route>
									<Route path='/profile' element={<ProfileScreen />}></Route>
									<Route
										path='/change-password/:token'
										element={<ChangePassword />}
									></Route>
									<Route
										path='/verify-user/:token'
										element={<VerifyUser />}
									></Route>
									{/* <Route
                    path='/payment'
                    element={<PaymentMethodScreen />}
                  ></Route>
                  <Route
                    path='/placeorder'
                    element={<PlaceOrderScreen />}
                  ></Route> */}
									{/* <Route path='/order/:id' element={<OrderScreen />}></Route>
                  <Route
                    path='/orderhistory'
                    element={<OrderHistoryScreen />}
                  ></Route> */}
									{/* <Route path='/search' element={<SearchScreen />}></Route>
                  <Route path='/envios' element={<Envios />}></Route>
                  <Route path='/contacto' element={<Contacto />}></Route> */}
									<Route path='/AdminScreen' element={<AccountList />}></Route>
									{/* <Route path='/AdminScreen/orders' element={<AdminOrders />}></Route> */}
									<Route path='/AdminScreen/reports' element={<Reports />}></Route>
									<Route path='/AdminScreen/predictions' element={<Predictions />}></Route>
									<Route path='/AdminScreen/predictions/debtDues/search' element={<Predictions />}></Route>
									<Route path='/AdminScreen/predictions/creditDues/search' element={<Predictions />}></Route>
									<Route path='/AdminScreen/reports/debts/search' element={<Reports />}></Route>
									<Route path='/AdminScreen/reports/credits/search' element={<Reports />}></Route>
									<Route path='/AdminScreen/reports/contracts/search' element={<Reports />}></Route>
									<Route path='/AdminScreen/reports/debtDues/search' element={<Reports />}></Route>
									<Route path='/AdminScreen/reports/creditDues/search' element={<Reports />}></Route>
									<Route path='/AdminScreen/reports/accountsDetail/search' element={<Reports />}></Route>
									<Route path='/AdminScreen/reports/assets/search' element={<Reports />}></Route>
									<Route path='/AdminScreen/reports/assetSales/search' element={<Reports />}></Route>
									<Route path='/AdminScreen/reports/payments/search' element={<Reports />}></Route>
									<Route path='/AdminScreen/accounts' element={<AccountList />}></Route>
									<Route path='/AdminScreen/accounts/search' element={<AccountList />}></Route>
									<Route path='/AdminScreen/accounts/account/:id' element={<AccountDetail />}></Route>
									<Route path='/AdminScreen/newAccount' element={<AddAccount />}></Route>
									<Route path='/AdminScreen/accounts/editAccount/:id' element={<EditAccount />}></Route>
									<Route path='/AdminScreen/accounts/account/:id/newContact' element={<AddContact />}></Route>
									<Route path='/AdminScreen/accounts/account/:id/newCredit' element={<AddCredit />}></Route>
									<Route path='/AdminScreen/accounts/account/:id/newDebt' element={<AddDebt />}></Route>
									<Route path='/AdminScreen/accounts/account/:id/newContract' element={<AddContract />}></Route>
									<Route path='/AdminScreen/accounts/account/:id/newAsset' element={<AddAsset />}></Route>
									<Route path='/AdminScreen/newUser' element={<AddUser />}></Route>
									<Route path='/AdminScreen/users/editUser/:id' element={<EditUser />}></Route>
									<Route path='/AdminScreen/users' element={<UsersList />}></Route>
									<Route path='/AdminScreen/newContact' element={<AddContact />}></Route>
									<Route path='/AdminScreen/contacts' element={<ContactList />}></Route>
									<Route path='/AdminScreen/contacts/editContact/:id' element={<EditContact />}></Route>
									<Route path='/AdminScreen/contacts/contact/:id' element={<ContactInfo />}></Route>
									<Route path='/AdminScreen/debts' element={<DebtList />}></Route>
									<Route path='/AdminScreen/debts/search' element={<DebtList />}></Route>
									<Route path='/AdminScreen/debts/debt/:id' element={<DebtDetails />}></Route>
									<Route path='/AdminScreen/newDebt' element={<AddDebt />}></Route>
									<Route path='/AdminScreen/debts/editDebt/:id' element={<EditDebt />}></Route>
									<Route path='/AdminScreen/debts/editFirstDueDate/:id' element={<EditFirstDueDate />}></Route>
									<Route path='/AdminScreen/debts/debt/:id/newInterest' element={<NewInterestNew />}></Route>
									<Route path='/AdminScreen/debts/debt/:id/newPayment' element={<NewPaymentNew />}></Route>
									<Route path='/AdminScreen/debts/debt/:id/newDue' element={<NewDebtDue />}></Route>
									<Route path='/AdminScreen/credits/credit/:id/newCreditPayment' element={<NewCreditPaymentNew />}></Route>
									<Route path='/AdminScreen/credits/credit/:id/newWarranty' element={<NewWarranty />}></Route>
									<Route path='/AdminScreen/credits' element={<CreditList />}></Route>
									<Route path='/AdminScreen/credits/search' element={<CreditList />}></Route>
									<Route path='/AdminScreen/credits/credit/:id' element={<CreditDetails />}></Route>
									<Route path='/AdminScreen/newCredit' element={<AddCredit />}></Route>
									<Route path='/AdminScreen/credits/editCredit/:id' element={<EditCredit />}></Route>
									<Route path='/AdminScreen/creditPayments' element={<CreditPaymentList />}></Route>
									<Route path='/AdminScreen/newCreditPayment' element={<AddCreditPayment />}></Route>
									<Route path='/AdminScreen/creditPayments/editCreditPayment/:id' element={<EditCreditPayment />}></Route>
									<Route path='/AdminScreen/creditPayments/creditPayment/:id' element={<CreditPaymentDetail />}></Route>
									<Route path='/AdminScreen/payments' element={<PaymentList />}></Route>
									<Route path='/AdminScreen/newPayment' element={<AddPayment />}></Route>
									<Route path='/AdminScreen/payments/editPayment/:id' element={<EditPayment />}></Route>
									<Route path='/AdminScreen/payments/payment/:id' element={<PaymentDetail />}></Route>
									<Route path='/AdminScreen/bills' element={<BillsList />}></Route>
									<Route path='/AdminScreen/newBill' element={<AddBill />}></Route>
									<Route path='/AdminScreen/bills/editBill/:id' element={<EditBill />}></Route>
									<Route path='/AdminScreen/bills/confirmPayment/:id' element={<ConfirmPayment />}></Route>
									<Route path='/AdminScreen/bills/bill/:id' element={<BillDetail />}></Route>
									<Route path='/AdminScreen/contracts' element={<ContractList />}></Route>
									<Route path='/AdminScreen/contracts/search' element={<ContractList />}></Route>
									<Route path='/AdminScreen/contracts/contract/:id' element={<ContractDetail />}></Route>
									<Route path='/AdminScreen/newContract' element={<AddContract />}></Route>
									<Route path='/AdminScreen/contracts/editContract/:id' element={<EditContract />}></Route>
									<Route path='/AdminScreen/contracts/contract/:id/newDebt' element={<AddDebt />}></Route>
									<Route path='/AdminScreen/interests' element={<InterestList />}></Route>
									<Route path='/AdminScreen/interests/interest/:id' element={<InterestInfo />}></Route>
									<Route path='/AdminScreen/newInterest' element={<AddInterest />}></Route>
									<Route path='/AdminScreen/interests/editInterest/:id' element={<EditInterest />}></Route>
									<Route path='/AdminScreen/assets' element={<AssetList />}></Route>
									<Route path='/AdminScreen/assets/search' element={<AssetList />}></Route>
									<Route path='/AdminScreen/assets/asset/:id' element={<AssetDetails />}></Route>
									<Route path='/AdminScreen/newAsset' element={<AddAsset />}></Route>
									<Route path='/AdminScreen/assets/editAsset/:id' element={<EditAsset />}></Route>
									<Route path='/AdminScreen/assets/asset/:id/newBill' element={<NewBillsNew />}></Route>
									<Route path='/AdminScreen/assets/asset/:id/newContract' element={<AddContract />}></Route>
									<Route path='/AdminScreen/assetSales/assetSale/:id' element={<AssetSaleDetail />}></Route>
									<Route path='/AdminScreen/assets/asset/:id/assetSale' element={<AddAssetSale />}></Route>
									<Route path='/AdminScreen/newAssetSale' element={<AddAssetSale />}></Route>
									<Route path='/AdminScreen/assetSales/assetSale/:id/newDebt' element={<AddDebt />}></Route>
									<Route path='/AdminScreen/assetSales/editAssetSale/:id' element={<EditAssetSale />}></Route>

									<Route
										path='/AdminScreen/AdminUsers'
										element={<AdminScreen3 />}
									></Route>



									<Route
										path='/AdminScreen/Config'
										element={<AdminConfigScreen />}
									></Route>

								</Routes>
							</main>
						</div>
						<AdminFooter></AdminFooter>
						{/* </BrowserRouter> */}
					</CustomBrowserRouter>
				</>
			)}
		</>
	);
}

export default App;

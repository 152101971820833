import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Button, Card, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Helmet } from 'react-helmet-async';
import { MdOutlineEdit, MdWarning } from 'react-icons/md';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import { getError } from '../../utils';

import moment from 'moment';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useNavigate, useParams } from 'react-router-dom';
import CustomTypeahead from '../../components/CustomTypeahead';
import FormatedNumberInput from '../../components/FormatedNumberInput';
import GenericDropdown from '../../components/SelectDropdown';
import accountService from '../../services/account.service';
import assetSaleService from '../../services/assetSale.service';
import contractService from '../../services/contract.service';
import debtService from '../../services/debt.service';
import { concepts, currencies,periodicities } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_ACCOUNTS_SUCCESS':
		return { ...state, accounts: action.payload, loadingAccounts: false };
	case 'FETCH_CONTRACTS_SUCCESS':
		return {
			...state,
			contracts: action.payload,
			loadingFetch: false,
		};

	case 'FETCH_DEBT_SUCCESS':
		return { ...state, debt: action.payload};
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'FETCH_ASSETSALES_SUCCESS':
		return { ...state, assetSales: action.payload, loadingFetch: false };
	case 'UPDATE_REQUEST':
		return { ...state, loadingUpdate: true, successUpdate: false };
	case 'UPDATE_SUCCESS':
		return { ...state, loadingUpdate: false, successUpdate: true };
	case 'UPDATE_FAIL':
		return { ...state, loadingUpdate: false, successUpdate: false };
	default:
		return state;
	}
};

function EditDebt() {
	const params = useParams();
	const navigate = useNavigate();

	const { id } = params;

	const [
		{ loading, error, debt, loadingAccounts, accounts, contracts, assetSales, loadingUpdate },
		dispatch,
	] = useReducer(reducer, {
		contracts: [],
		accounts: [],
		assetSales: [],
		loadingAccounts: true,
		debt: {},
		loading: true,
		loadingUpdate: false,
		error: '',
	});

	const { dispatch: ctxDispatch } = useContext(Store);

	const [capital, setCapital] = useState();
	const [amount, setAmount] = useState(0);
	const [interest, setInterest] = useState(0);
	const [selectedConcept, setSelectedConcept] = useState();
	const [concept, setConcept] = useState();


	const [dues, setDues] = useState(1);
	const [date, setDate] = useState();
	const [contract, setContract] = useState();
	const [periodicity, setPeriodicity] = useState();
	const [owner, setOwner] = useState();
	const [disabledInput, setDisabledInput] = useState(false);
	const [hasPreviousDebts, setHasPreviousDebts] = useState(false);
	const [previousDebt, setPreviousDebt] = useState();
	const [previousDebtDate, setPreviousDebtDate] = useState();
	const [currency, setCurrency] = useState();
	const [endDate, setEndDate] = useState();
	const [disabledAmount, setDisabledAmount] = useState(false);
	const [disabledOwner, setDisabledOwner] = useState(false);
	const [disabledEndDate, setDisabledEndDate] = useState(false);
	const [isInvalid, setIsInvalid] = useState(false);
	const [isPropertyInvalid, setIsPropertyInvalid] = useState(false);
	const [isAssetInvalid, setIsAssetInvalid] = useState(false);
	const [assetSale, setAssetSale] = useState();
	const [createWithPreviousDues, setCreateWithPreviousDues] = useState();
	const [createWithPreviousPayments, setCreateWithPreviousPayments] = useState();
	const [firstDueExpiration, setFirstDueExpiration] = useState();
	const [numMonths, setNumMonths] = useState();
	const [selectedMonthOption, setSelectedMonthOption] = useState();
	const [dueDay, setDueDay] = useState();
	const [periodicityNumMonths, setPeriodicityNumMonths] = useState();



	const fetchDebtData = async () => {
		const result = await debtService.getDetails(id);
		dispatch({ type: 'FETCH_DEBT_SUCCESS', payload: result.data });
		setAmount(result.data.amount);
		setConcept(result.data.concept);
		setDate(result.data.date);
		setOwner(result.data.owner);
		setContract([result.data.contract]);
		setEndDate(result.data.endDate);
		setPeriodicity(result.data.periodicity);
		setInterest(result.data.interest);
		setCapital(result.data.capital);
		setDues(result.data.dues);
		setHasPreviousDebts(result.data.hasPreviousDebts);
		setPreviousDebt(result.data.previousDebt);
		setPreviousDebtDate(result.data.previousDebtDate);
		setSelectedConcept(result.data.concept);
		setCurrency(result.data.currency);
		setAssetSale([result.data.assetSale]);
		setFirstDueExpiration(result.data.firstDueExpiration);
		setSelectedMonthOption(result.data.selectedMonthOption);
		setDueDay(result.data.dueDay);
		setNumMonths(result.data.numMonths);
		setCreateWithPreviousDues(result.data.createWithPreviousDues);
		setCreateWithPreviousPayments(result.data.createWithPreviousPayments);
	};
	async function fetchData() {
		try {
			dispatch({type: 'FETCH_REQUEST'});
			await Promise.all([fetchDebtData(),fetchAccounts(),fetchContractsData(),fetchAssetSalesData()]);
			dispatch({type: 'FETCH_SUCCESS'});
		}catch(ex) {
			console.error(ex);
			dispatch({type: 'FETCH_FAIL', payload: getError(ex)});
			toast.error(getError(ex));
		}
	}

	const fetchAccounts = async () => {
		const result = await accountService.list();
		dispatch({ type: 'FETCH_ACCOUNTS_SUCCESS', payload: result.data });
	};
	const fetchContractsData = async () => {
		const result = await contractService.list();
		dispatch({ type: 'FETCH_CONTRACTS_SUCCESS', payload: result.data });
	};
	const fetchAssetSalesData = async () => {
		const result = await assetSaleService.list();
		dispatch({ type: 'FETCH_ASSETSALES_SUCCESS', payload: result.data });
	};

	useEffect(() => {
		fetchData();
	}, [id]);

	const editDebtHandler = async () => {
		const emptyFields = [];
		if (selectedConcept === 'otro' && !concept) {
			emptyFields.push('Otro Concepto');
		}
		if (concept == 'alquileres' && !contract) {
			emptyFields.push('Propiedad');
		}
		if(selectedConcept != 'alquileres') {
			if(isNaN(parseInt(interest))) {
				emptyFields.push('Interés');
			}
			if(!capital) {
				emptyFields.push('Capital');
			}
			if (!periodicity) {
				emptyFields.push('Periodicidad');
			}
		}
		if(!dues && (selectedConcept === 'prestamos-amortizable' || selectedConcept === 'ventas-a-plazo')) {
			emptyFields.push('Cuotas');
		} 
		if (!owner) {
			emptyFields.push('Cuenta');
		}
		if (!currency) {
			emptyFields.push('Moneda');
		}
		if (emptyFields.length > 0) {
			const errorMessages = `Debe completar el/los campo/s: ${emptyFields.join(', ')}`;
			toast.error(errorMessages);
			return;
		}
		if (selectedConcept === 'ventas-a-plazo' && assetSale && assetSale.length > 0) {
			const sumDebtsFloat = parseFloat(assetSale[0].sumDebts);
			const capitalFloat = parseFloat(capital);
			const amountFloat = parseFloat(assetSale[0].amount);
			const initialPaymentFloat = parseFloat(assetSale[0].initialPayment);
			if (
				sumDebtsFloat + capitalFloat + initialPaymentFloat > amountFloat &&
				selectedConcept === 'ventas-a-plazo'
			) {
				toast.error(
					'No se puede registrar deuda, ya que supera el monto de la venta:' +
					assetSale[0].amount +
					', Monto en deudas:' +
					assetSale[0].sumDebts +
					', Entrega Inicial:' +
					assetSale[0].initialPayment,
				);
				return;
			}
		}
		try {
			dispatch({ type: 'UPDATE_REQUEST' });
			if (!owner) {
				toast.error('Debe seleccionar una cuenta.');
				return;
			}
			const requestData = {
				amount,
				concept,
				date,
				owner: owner[0] || owner,
				currency,
				periodicity,
			};
			if (periodicity === 'otra') {
				requestData.periodicityNumMonths = periodicityNumMonths;
			}
			//check if is 'alquileres' to set contract
			if (concept == 'alquileres' && contract.length > 0) {
				requestData.contract = contract[0];
				requestData.endDate = endDate;
				requestData.interest = '';
				requestData.dues = '';
				requestData.capital = '';
				requestData.dues = '';
				requestData.guaranteedAmount = '';
			}
			//check if is 'prestamos' to set other fields
			if (selectedConcept.includes('prestamos') || selectedConcept === 'ventas-a-plazo') {
				requestData.interest = interest;
				requestData.capital = capital;
			}
			if (selectedConcept === 'prestamos-interés') {
				requestData.endDate = endDate;
			}
			if (selectedConcept === 'prestamos-amortizable') {
				requestData.dues = dues;
			}
			if (selectedConcept === 'ventas-a-plazo') {
				requestData.assetSale = assetSale[0];
				requestData.dues = dues;
			}

			if (hasPreviousDebts) {
				requestData.hasPreviousDebts = hasPreviousDebts;
				requestData.previousDebt = previousDebt;
				requestData.previousDebtDate = previousDebtDate;
			}
			//check if is dont 'hasPreviousDebts' to unset other fields
			if (!hasPreviousDebts) {
				requestData.hasPreviousDebts = false;
				requestData.previousDebt = '';
				requestData.previousDebtDate = '';
			}
			const { data } = await debtService.edit(debt._id, requestData);
			dispatch({
				type: 'UPDATE_SUCCESS',
			});
			ctxDispatch({ type: 'CONTACT_UPDATE_SUCCESS', payload: data });
			toast.success('Deuda actualizada Correctamente');
			navigate(-1);
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL' });
			toast.error(getError(err));
		}
	};


	const handleSelectedConcept = (e) => {
		dispatch({ type: 'CALC' });
		setSelectedConcept(e.value);
		if (concept === 'prestamos-amortizable' || concept === 'ventas-a-plazo') {
			setDisabledInput(false);
			setDisabledOwner(false);
		}
		if (e.value !== 'otro') {
			setConcept(e.value);
		}
	};

	//for calc button
	function AmountCalc() {
		dispatch({ type: 'CALC' });
		if (concept === 'prestamos-amortizable' || concept === 'ventas-a-plazo') {
			getValorDeCuotaFijaMensualAmortizable();
			setDisabledAmount(false);
			dispatch({ type: 'CALC_SUCCESS' });
		}
		if (concept === 'prestamos-interés') {
			getValorDeCuotaFijaMensualInteres();
			dispatch({ type: 'CALC_SUCCESS' });
		}
	}

	function getValorDeCuotaFijaMensualAmortizable() {
		if (!capital || isNaN(parseInt(interest)) || !dues) {
			toast.error('Debe ingresar Capital, interés y cuotas');
			return;
		} else {
			if (interest == 0) {
				const valorCuota = capital / dues;
				setAmount(valorCuota);
			} else {
				const tasa = interest / 100;
				const valorCuota =
					capital * ((tasa * Math.pow(1 + tasa, dues)) / (Math.pow(1 + tasa, dues) - 1));
				setAmount(Math.ceil(valorCuota));
			}
		}
	}

	function getValorDeCuotaFijaMensualInteres() {
		if (!capital || !interest) {
			toast.error('Debe ingresar Capital e interés');
			return;
		}
		const tasa = interest / 100;
		const valorCuota = capital * tasa;
		setAmount(Math.ceil(valorCuota));
	}

	//account set only if 'alquileres'
	const handleAccountChange = (selected) => {
		if (!selected || selected.length === 0) {
			const selectedAccount = selected[0];
			const isValid = selectedAccount ? accounts.includes(selectedAccount) : false;
			setIsInvalid(!isValid);
			setOwner(null);
			setCurrency('');
			setDisabledAmount(false);
		} else {
			const selectedAccount = selected[0];
			const isValid = selectedAccount ? accounts.includes(selectedAccount) : false;
			setIsInvalid(!isValid);

			setOwner(selected[0]);
			setCurrency(selectedAccount.currency);
			setDisabledAmount(true);
		}
	};

	//contract handle if 'alquileres'
	const handlePropertyChange = (selected) => {
		if (!selected || selected.length === 0) {
			// Reset the state variables if nothing is selected
			const selectedProperty = selected[0];
			const isPropertyInvalid = contracts.includes(selectedProperty);
			setIsPropertyInvalid(!isPropertyInvalid);
			setContract(null);
			setOwner('');
			setAmount('');
			setCurrency('');
			setDisabledAmount(false);
			setDisabledOwner(false);
			setDisabledEndDate(false);
		} else {
			// Update the state variables with the selected property
			const selectedProperty = selected[0];
			const isPropertyInvalid = contracts.includes(selectedProperty);
			setIsPropertyInvalid(!isPropertyInvalid);
			setContract(selected);
			setOwner(selectedProperty.owner);
			setAmount(selectedProperty.amount);
			setCurrency(selectedProperty.currency);
			setEndDate(selectedProperty.periodEnd);
			setDisabledEndDate(true);
			setDisabledAmount(true);
			setDisabledOwner(true);
		}
	};
	const handleAssetSaleChange = (selected) => {
		if (!selected || selected.length === 0) {
			// Reset the state variables if nothing is selected
			const selectedAssetSale = selected[0];
			const isAssetInvalid = assetSales.includes(selectedAssetSale);
			setIsAssetInvalid(!isAssetInvalid);
			setAssetSale(null);
			setOwner('');
			setAmount('');
			setCurrency('');
			setDisabledAmount(false);
			setDisabledOwner(false);
			setDisabledEndDate(false);
		} else {
			const selectedAssetSale = selected[0];
			const isAssetInvalid = assetSales.includes(selectedAssetSale);
			setIsAssetInvalid(!isAssetInvalid);
			setAssetSale(selected);
			setOwner(selected[0].purchaser);
			setCurrency(selected[0].currency);
		}
	};


	return (
		<div>
			<Modal size='xl' show={true} animation={false}>
				{loading || loadingAccounts || loadingUpdate? <LoadingBox></LoadingBox> : 
					error ? <MessageBox variant='danger'>{error}</MessageBox>
						:
						<div className='container admin-con align-items-center'>
							<Helmet>
								<title>Editar Deuda</title>
							</Helmet>
							<h1>
								<MdOutlineEdit></MdOutlineEdit>Editar Deuda
							</h1>
							<Form className='admin-form' onSubmit={(e) => e.preventDefault()}>
								<div className='row align-items-center'>
									<Card className='d-flex mb-3 justify-content-center card-form-debts'>
										<OverlayTrigger
											placement='top'
											overlay={
												<Tooltip id='tooltip-previous-debt'>
													<MdWarning />
											Estos datos solo se pueden editar luego de creada la deuda mediante la
											opción:Cambiar fecha de inicio.
												</Tooltip>
											}
										>
											<Row>
												<h2 className='text-center m-3'> Datos para la generación de cuotas</h2>
											</Row>
										</OverlayTrigger>
										<Row className='d-flex mb-3 justify-content-center'>
											<Form.Group className='mb-3 col-3 form-group required' controlId='Date'>
												<Form.Label>Fecha de inicio de la deuda</Form.Label>
												<Form.Control
													type='date'
													value={date ? moment(date).utc().format('YYYY-MM-DD') : null}
													disabled
												></Form.Control>
											</Form.Group>
											<Form.Group className='mb-3 col-3 form-group required' controlId='DaysDropdown'>
												<Form.Label>Día vencimiento de 1er cuota:</Form.Label>
												<Form.Control value={dueDay ? dueDay : 'No hay datos'} />
											</Form.Group>
											<Form.Group
												className='mb-3 col-2 form-group required'
												controlId='selectedMonthOption'
											>
												<Form.Label>Generar a partir de:</Form.Label>
												<Form.Control
													value={selectedMonthOption ? debt.selectedMonthOption : 'No hay datos'}
												/>
											</Form.Group>
											{debt.selectedMonthOption === 'Otro' && (
												<Form.Group className='mb-3 col-3 form-group required' controlId='numMonths'>
													<Form.Label>En cuantos meses:</Form.Label>
													<Form.Control type='number' min='1' max ='240' value={numMonths}
														onInput={(e) => {
															const inputValue = parseInt(e.target.value, 10);
															const maxValue = 240;
															if (maxValue !== null && inputValue > maxValue) {
																e.target.value = maxValue;
															}
														}} />
												</Form.Group>
											)}
										</Row>
										<Row>
											<div className='d-flex d-inline justify-content-center align-items-center'>
												<p>
													{firstDueExpiration
														? `Primer Vencimiento: ${moment.utc(firstDueExpiration).format('DD-MM-YYYY')}  `
														: ''}
												</p>
											</div>
										</Row>
									</Card>
									{createWithPreviousDues ? (
										<Card className='d-flex align-items-center justify-content-center mb-3 card-form-debts'>
											<Row>
												<h2 className='text-center m-3'>
													<MdWarning />
											Deuda con cuotas previas {createWithPreviousPayments ? 'y pagas' : ''}
												</h2>
											</Row>
											<Row>
												<p>
											Ésta deuda fue creada con fecha anterior, por lo que fueron generadas las
											cuotas previas a la fecha de creación
												</p>
											</Row>
										</Card>
									) : (
										''
									)}
									<Card className='d-flex justify-content-center mb-3 card-form-debts'>
										<Row>
											<h2 className='text-center m-3'>Información contable</h2>
										</Row>
										<Row>
											<Form.Group className='mb-3 col-6 form-group required'>
												<GenericDropdown
													disabled = {true}
													label='Concepto'
													items={concepts}
													selected={concept}
													onChange={handleSelectedConcept}
												/>
											</Form.Group>
											{(selectedConcept || concept) === 'alquileres' ? (
												<Form.Group className='mb-3 col-4 form-group required'>
													<Form.Label>Propiedad:</Form.Label>
													{debt.contract && debt.contract != null ? (
														<>
															<CustomTypeahead
																id='contracts'
																labelKey={(option) => `${option.customId}`}
																onChange={handlePropertyChange}
																options={contracts}
																selected={contract}
																placeholder='Buscar una propiedad...'
																linkToCreate='/AdminScreen/newContract'
																newOption='nuevo alquiler'
															/>
															{isPropertyInvalid && (
																<div style={{ color: 'red' }}>Alquiler no seleccionado o invalido</div>
															)}
														</>
													) : (
														<>
															<CustomTypeahead
																id='contracts'
																labelKey={(option) => `${option.customId}`}
																onChange={handlePropertyChange}
																options={contracts}
																placeholder='Buscar una propiedad...'
																linkToCreate='/AdminScreen/newContract'
																newOption='nuevo alquiler'
															/>
															{isPropertyInvalid && (
																<div style={{ color: 'red' }}>Alquiler no seleccionado o invalido</div>
															)}
														</>
													)}
												</Form.Group>
											) : (
												''
											)}
											{(selectedConcept || concept) === 'ventas-a-plazo' ? (
												<Form.Group className='mb-3 col-6 form-group required'>
													<Form.Label>Venta:</Form.Label>
													{debt.assetSale && debt.assetSale != null ? (
														<>
															<CustomTypeahead
																id='assetSales'
																labelKey={(option) => `${option.customId}`}
																onChange={handleAssetSaleChange}
																options={assetSales}
																selected={assetSale}
																placeholder='Buscar una venta de activo fijo...'
																linkToCreate='/AdminScreen/newAssetSale'
																newOption='nueva venta de activo fijo'
															/>
															{isAssetInvalid && (
																<div style={{ color: 'red' }}>Alquiler invalido</div>
															)}
														</>
													) : (
														<>
															<CustomTypeahead
																id='assetSales'
																labelKey={(option) => `${option.customId}`}
																onChange={handleAssetSaleChange}
																options={assetSales}
																placeholder='Buscar una venta de activo fijo...'
																linkToCreate='/AdminScreen/newAssetSale'
																newOption='nueva venta de activo fijo'
															/>
															{isAssetInvalid && (
																<div style={{ color: 'red' }}>Alquiler no seleccionado o invalido</div>
															)}
														</>
													)}
												</Form.Group>
											) : (
												''
											)}
										</Row>
										<Row>
											<Form.Group className='mb-3 col-4 form-group required'>
												<Form.Label>Deudor</Form.Label>
												{debt.owner && debt.owner != null && debt.owner != '' ? (
													<>
														<CustomTypeahead
															id='accounts'
															labelKey={(option) => `${option.name}`}
															onChange={handleAccountChange}
															options={accounts}
															placeholder='Buscar Cuenta... '
															disabled={disabledOwner || concept === 'alquileres'}
															selected={owner ? [owner] : null}
															isInvalid={isInvalid}
															linkToCreate='/AdminScreen/newAccount'
															newOption='nueva cuenta'
														/>
														{isInvalid && (
															<div style={{ color: 'red' }}>Cuenta no seleccionada o invalida</div>
														)}
													</>
												) : (
													<>
														<CustomTypeahead
															id='accounts'
															labelKey={(option) => `${option.name}`}
															onChange={handleAccountChange}
															options={accounts}
															placeholder='Buscar Cuenta... '
															isInvalid={isInvalid}
															disabled={disabledOwner}
															linkToCreate='/AdminScreen/newAccount'
															newOption='nueva cuenta'
														/>
														{isInvalid && (
															<div style={{ color: 'red' }}>Cuenta no seleccionada o invalida</div>
														)}
													</>
												)}
											</Form.Group>
											<Form.Group as={Col} className='mb-3 col-3 form-group required' controlId='currency' >
												<GenericDropdown
													label='Moneda'
													items={currencies}
													selected={currency}
													onChange={(e) => setCurrency(e.value)}
												/>
											</Form.Group>
											<Form.Group className='mb-3 col-4 form-group required'>
												<GenericDropdown
													label='Periodicidad'
													items={periodicities}
													selected={periodicity}
													onChange={(e) => setPeriodicity(e.value)}
												/>
											</Form.Group>
											{debt.periodicity === 'otra' && (
												<Form.Group className='mb-3 col-md-3' controlId='periodicityNumMonths'>
													<Form.Label>Periodicidad en meses</Form.Label>
													<FormatedNumberInput
														type='number'
														value={debt.periodicityNumMonths}
														onChange={(e) => setPeriodicityNumMonths(e.target.value)}
														min={1}
														noDecimal={true}
													></FormatedNumberInput>
												</Form.Group>
											)}
											{selectedConcept &&
										(selectedConcept.includes('prestamos') ||
											selectedConcept.includes('ventas')) && (
												<>
													<Form.Group className='mb-3 col-4 form-group required' controlId='amount'>
														<Form.Label>Capital</Form.Label>
														<FormatedNumberInput
															type='number'
															value={capital}
															onChange={(e) => setCapital(e.target.value)}
															min={0}
														></FormatedNumberInput>
													</Form.Group>
													<Form.Group className='mb-3 col-4 form-group required' controlId='amount'>
														<Form.Label>Interes %</Form.Label>
														<FormatedNumberInput
															type='number'
															value={interest}
															onChange={(e) => setInterest(e.target.value)}
															min={0}
														></FormatedNumberInput>
													</Form.Group>
													{selectedConcept !== 'prestamos-amortizable' &&
													!selectedConcept.includes('ventas') && (
														<Form.Group className='mb-3 col-3 form-group' controlId='endDate'>
															<Form.Label>Fecha de fin</Form.Label>
															<Form.Control
																type='date'
																disabled={disabledEndDate || concept === 'alquileres'}
																value={endDate ? moment(endDate).format('YYYY-MM-DD') : null}
																onChange={(e) => setEndDate(e.target.value)}
															></Form.Control>
														</Form.Group>
													)}
													{(selectedConcept === 'prestamos-amortizable' ||
													selectedConcept.includes('ventas')) && (
														<Form.Group className='mb-3 col-2 form-group required' controlId='dues'>
															<Form.Label>Cuotas</Form.Label>
															<FormatedNumberInput
																type='number'
																value={dues}
																onChange={(e) => setDues(e.target.value)}
																min={1}
																noDecimal={true}
															></FormatedNumberInput>
														</Form.Group>
													)}
													<Card className='d-flex flex-row mb-3 align-items-center justify-content-evenly card-form-debts'>
														<Button
															className='mb-3 col-2'
															onClick={AmountCalc}
															disabled={disabledInput}
														>
														Calcular
														</Button>
														<Form.Group className='mb-3 col-2 form-group required' controlId='amount'>
															<Form.Label>
															Monto{' '}
																{selectedConcept &&
																(selectedConcept.includes('prestamos') ||
																	selectedConcept.includes('ventas-a-plazo'))
																	? 'cuota'
																	: ''}
															</Form.Label>
															<FormatedNumberInput
																type='number'
																value={amount}
																disabled={disabledAmount || concept === 'alquileres'}
																onChange={(e) => setAmount(e.target.value)}
															></FormatedNumberInput>
														</Form.Group>
													</Card>
												</>
											)}
										</Row>
									</Card>
									<Card className='d-flex  justify-content-between mb-3 card-form-debts'>
										<Row>
											<h2 className='text-center m-3'>Registro fuera del sistema</h2>
										</Row>
										<Row className='d-flex flex-row align-items-center'>
											<OverlayTrigger
												placement='left'
												overlay={
													<Tooltip id='tooltip-previous-debt'>
												Los datos que se completan aqui son únicamente informativos, con el fin de
												registrar la contabilidad fuera del sistema
													</Tooltip>
												}
											>
												<Form.Group
													className=' col-3 form-group d-flex align-items-center'
													controlId='hasPreviousDebts'
													style={{ height: '60px' }}
												>
													<Form.Check
														className='d-flex align-items-center'
														type='checkbox'
														label='Tiene deuda anterior?'
														checked={hasPreviousDebts}
														onChange={(e) => setHasPreviousDebts(e.target.checked)}
													/>
												</Form.Group>
											</OverlayTrigger>
											<Form.Group className='mb-3 col-4 form-group ' controlId='previousDebt'>
												<Form.Label>Deuda Anterior</Form.Label>
												<FormatedNumberInput
													type='number'
													onChange={(e) => setPreviousDebt(e.target.value)}
													disabled={!hasPreviousDebts}
													value={previousDebt}
													min={0}
												></FormatedNumberInput>
											</Form.Group>
											<Form.Group className='mb-3 col-4 form-group ' controlId='previousDebtDate'>
												<Form.Label>Fecha deuda anterior</Form.Label>
												<Form.Control
													type='date'
													disabled={!hasPreviousDebts}
													value={previousDebtDate}
													onChange={(e) => setPreviousDebtDate(e.target.value)}
												></Form.Control>
											</Form.Group>
										</Row>
									</Card>
								</div>
								<Row className='modal-btns'>
									<Col>
										<Button className='cancelar' onClick={() => navigate(-1)}>
									Salir
										</Button>
									</Col>
									<Col>
										<Button className='guardar' onClick={editDebtHandler}>
									Guardar
										</Button>
									</Col>
								</Row>
							</Form>
						</div>
				}
			</Modal>
		</div>
	);
}
export default EditDebt;

import moment from 'moment';
import React, { useEffect, useReducer, useState } from 'react';
import {
	Col,
	Form,
	Modal,
	Row
} from 'react-bootstrap';
import {OverlayTrigger,Tooltip } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet-async';
import { FaFilter } from 'react-icons/fa';
import { MdClose, MdOtherHouses, MdWarning} from 'react-icons/md';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DynamicListTableNew from '../../components/DynamicListTableNew';
import FilterForm from '../../components/FilterForm';
import FormatedNumberInput from '../../components/FormatedNumberInput';
import ListButtons from '../../components/ListButtons';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import SearchBox from '../../components/SearchBox';
import ContractService from '../../services/contract.service';
import { getError } from '../../utils';
import { contractsColumns } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			contracts: action.payload.contracts,
			page: action.payload.page,
			pages: action.payload.pages,
			countContracts: action.payload.countContracts,
			pageSize: action.payload.pageSize,
			ipc: action.payload.ipc,
			loading: false,
		};
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };
	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	case 'UPDATE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'UPDATE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'UPDATE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function ContractList() {
	const [
		{ loading, error, contracts, successDelete, pageSize, countContracts, ipc },
		dispatch,
	] = useReducer(reducer, {
		contracts: [],
		loading: true,
		error: '',
		pageSize: 0,
		countContracts: 0,
		ipc: null,
	});


	const [loadingInitial, setLoadingInitial] = useState(true);
	const navigate = useNavigate();
	const { search } = useLocation();
	const sp = new URLSearchParams(search); // /search?category=Shirts
	const column = sp.get('column') || 'customId';
	const direction = sp.get('direction') || 'asc';
	const currency = sp.get('currency') || 'all';
	const amountTo = sp.get('amountTo') || '';
	const amountFrom = sp.get('amountFrom') || 0;
	const periodInitFrom = sp.get('periodInitFrom') || '';
	const periodInitTo = sp.get('periodInitTo') || '';
	const periodEndFrom = sp.get('periodEndFrom') || '';
	const periodEndTo = sp.get('periodEndTo') || '';
	const query = sp.get('query') || 'all';
	const page = sp.get('page') || 1;
	const [filtered, setFiltered] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [ipcUpdated, setIpcUpdated] = useState(ipc);

	const [closeDate, setCloseDate] = useState(moment.utc().format('YYYY-MM-DD'));

	const [showModal, setShowModal] = useState(false);
	const [adShowModal, setAdShowModal] = useState(false);

	const handleCloseModal = () => setShowModal(false);
	const handleShowModal = () => setShowModal(true);
	const handleCloseAdModal = () => {
		setAdShowModal(false);
	};
	const handleOpenAdModal = () => {
		setAdShowModal(true);
	};
	
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	
	const myTitle = (
		<h1
			className='d-flex justify-content-start align-items-center list-title-dropdown'
			style={{ padding: '4px 12px' }}
		>
			Todos los Alquileres
		</h1>
	);

	const buttonItems = [
		{
			label: 'Agregar Alquiler',
			onClick: () => newContractHandler(),
		},
		{
			label: 'Ajustar Alquileres',
			onClick: () => handleShow(),
		},
		{
			label: 'Finalizar Alquileres',
			onClick: () => handleShowModal(),
		},
	];

	const handleDropdownToggle = () => {
		setIsOpen(!isOpen);
	};

	const [filterOptions, setFilterOptions] = useState({
		formType: 'Alquileres',
		periodInitFrom: periodInitFrom,
		periodInitTo: periodInitTo,
		periodEndFrom: periodEndFrom,
		periodEndTo: periodEndTo,
		amountFrom: amountFrom,
		amountTo: amountTo,
		currency: currency,
		query: query,
	});

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFilterOptions((prevOptions) => ({
			...prevOptions,
			[name]: value,
			formType: filterOptions.formType,
		}));
	};

	const handleApplyFilter = () => {
		const {
			periodInitFrom,
			periodInitTo,
			periodEndFrom,
			periodEndTo,
			amountFrom,
			amountTo,
			currency,
		} = filterOptions;
		const filter = {
			periodInitFrom,
			periodInitTo,
			periodEndFrom,
			periodEndTo,
			amountFrom,
			amountTo,
			currency,
			page: 1,
			query,
		};
		const filterUrl = getFilterUrl(filter);
		navigate(filterUrl);
		setFiltered(true);
	};
	useEffect(() => {
		handleApplyFilter();
	}, [filtered]);

	const handleClearFilter = () => {
		setFilterOptions({
			formType: 'Alquileres',
			periodInitFrom: '',
			periodInitTo: '',
			periodEndFrom: '',
			periodEndTo: '',
			amountFrom: 0,
			amountTo: '',
			currency: 'all',
			query: 'all',
		});
		setFiltered(false);
	};
	const onPageChange = (page) => {
		navigate(getFilterUrl({ page: page }));
	};
	const [checkedContracts, setCheckedContracts] = useState([]);

	const handleSort = (column) => {
		const newSortDirection = column === column && direction === 'desc' ? 'asc' : 'desc';
		const newURL = `/AdminScreen/contracts/search?page=${page}&query=${query}&column=${column}&direction=${newSortDirection}&currency=${currency}&amountFrom=${amountFrom}&amountTo=${amountTo}&periodInitFrom=${periodInitFrom}&periodInitTo=${periodInitTo}&periodEndFrom=${periodEndFrom}&periodEndTo=${periodEndTo}`;
		navigate(newURL);
	};
	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const { data } = await ContractService.search(
					`page=${page}&query=${query}&column=${column}&direction=${direction}&currency=${currency}&amountFrom=${amountFrom}&amountTo=${amountTo}&periodInitFrom=${periodInitFrom}&periodInitTo=${periodInitTo}&periodEndFrom=${periodEndFrom}&periodEndTo=${periodEndTo}`,
				);
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
				setIpcUpdated(data.ipc);
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
				console.error(getError(err));
				toast.error('No se pudo obtener lista');
			} finally {
				setLoadingInitial(false);
			}
		};
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		}
		fetchData();
	}, [
		successDelete,
		page,
		direction,
		column,
		periodInitFrom,
		periodInitTo,
		periodEndFrom,
		periodEndTo,
		amountFrom,
		amountTo,
		currency,
		query,
	]);

	async function deletePaymentHandler(contract) {
		if (window.confirm('Seguro desea eliminar el pago?')) {
			try {
				dispatch({ type: 'DELETE_REQUEST' });
				await ContractService.delete(contract._id);
				dispatch({ type: 'DELETE_SUCCESS' });
				toast.success('Contrato Eliminado');
			} catch (err) {
				dispatch({ type: 'DELETE_FAIL' });
				console.error(getError(error));
				toast.error('Error al eliminar cuenta');
			}
		}
	}

	async function editPaymentHandler(contract) {
		navigate(`/AdminScreen/contracts/editContract/${contract._id}`);
	}
	async function seeDetails(contract) {
		navigate(`/AdminScreen/contracts/contract/${contract._id}`);
	}
	async function newContractHandler() {
		navigate('/AdminScreen/newContract');
	}

	const findCheckedBoxes = () => {
		setCheckedContracts([]);
		let checkboxes = document.getElementsByName('checkedcontracts');
		for (let i = 0; i < checkboxes.length; i++) {
			if (checkboxes[i].checked) {
				checkedContracts.push(checkboxes[i].value);
			}
		}
	};

	async function updateAmountHandler() {
		findCheckedBoxes();
		if (checkedContracts.length === 0) {
			toast.error('Debe Seleccionar al menos un contrato');
			return;
		}
		if (!ipcUpdated) {
			toast.error('Debe registrar IPC');
			return;
		}
		try {
			dispatch({ type: 'UPDATE_REQUEST' });
			await ContractService.updateContractsByIPC(checkedContracts, { ipcUpdated });
			toast.success('Montos actualizados');
			dispatch({ type: 'UPDATE_SUCCESS' });
			handleClose();
		} catch (err) {
			toast.error(getError(err));
			dispatch({ type: 'UPDATE_FAIL' });
		}
	}

	async function closeContractsHandler() {
		findCheckedBoxes();
		if (checkedContracts.length === 0) {
			toast.error('Debe Seleccionar al menos un contrato');
			return;
		}
		if (!closeDate) {
			toast.error('Debe ingresar fecha IPC');
			return;
		}
		try {
			dispatch({ type: 'UPDATE_REQUEST' });
			await ContractService.closeContractsByID(checkedContracts, { closeDate });
			toast.success('Contratos Finalizados');
			dispatch({ type: 'UPDATE_SUCCESS' });
			handleCloseAdModal();
			handleCloseModal();
		} catch (err) {
			toast.error(getError(err));
			dispatch({ type: 'UPDATE_FAIL' });
		}
	}

	const getFilterUrl = (filter) => {
		const {
			query,
			periodInitFrom,
			periodInitTo,
			periodEndFrom,
			periodEndTo,
			amountFrom,
			amountTo,
			currency,
		} = filterOptions;
		const filterQuery = filter.query || query;
		const filterPage = filter.page || 1;
		const filterPeriodInitFrom = periodInitFrom || '';
		const filterPeriodInitTo = periodInitTo || '';
		const filterPeriodEndFrom = periodEndFrom || '';
		const filterPeriodEndTo = periodEndTo || '';
		const filterAmountFrom = amountFrom || '';
		const filterAmountTo = amountTo || '';
		const filterCurrency = currency || '';

		const params = new URLSearchParams();
		params.append('page', filterPage);
		params.append('query', filterQuery);
		params.append('periodInitFrom', filterPeriodInitFrom);
		params.append('periodInitTo', filterPeriodInitTo);
		params.append('periodEndFrom', filterPeriodEndFrom);
		params.append('periodEndTo', filterPeriodEndTo);
		params.append('amountFrom', filterAmountFrom);
		params.append('amountTo', filterAmountTo);
		params.append('currency', filterCurrency);

		return `/AdminScreen/contracts/search?${params.toString()}`;
	};

	return loadingInitial ? (
		<LoadingBox loading={loadingInitial}></LoadingBox>
	) : error ? (
		<MessageBox variant='danger'>{error}</MessageBox>
	) : (
		<div>
			<Helmet>
				<title>Lista de Alquileres</title>
			</Helmet>
			<div className='admin-con'>
				<Row>
					<Col>
						<Row className='d-flex justify-content-center'>
							<Row>
								<h2 className='d-flex justify-content-start align-items-center list-title'>
									<MdOtherHouses></MdOtherHouses>Alquileres
								</h2>
							</Row>
							<Row className='title-btns-row'>
								<div
									className='btn title-dropdown text-align-start p-0'
									style={{ width: 'fit-content' }}
									title={myTitle}
								>
									{myTitle}
								</div>
								<ListButtons buttonItems={buttonItems} />
								<Modal show={show} onHide={handleClose}>
									<Modal.Header closeButton>
										<Modal.Title>Ajustar Alquileres</Modal.Title>
									</Modal.Header>
									<Modal.Body>Indique el porcentaje de ajuste</Modal.Body>

									<OverlayTrigger
										placement='top'
										overlay={
											<Tooltip id='ipcUpdated'>
												<MdWarning />
												El ajuste solo tendrá vigencia desde el día en que se realiza, y a partir de las próximas cuotas a generarse
											</Tooltip>
										}
									>
										<Row>
											<Form.Group as={Row} className='mb-3 align-items-center' controlId='ipcUpdated'>
												<Form.Label column md={3}>
												Porcentaje(%)
												</Form.Label>
												<Col md={5}>
													<FormatedNumberInput
														col={6}
														type='number'
														step='0.01'
														max='100'
														onChange={(e) => setIpcUpdated(e.target.value)}
														value={ipcUpdated}
													/>
												</Col>
											</Form.Group>
										</Row>
									</OverlayTrigger>
									<Modal.Footer>
										<Button variant='secondary' onClick={handleClose}>
											Cancelar
										</Button>
										<Button variant='primary' onClick={() => updateAmountHandler()}>
											Ajustar Alquileres
										</Button>
									</Modal.Footer>
								</Modal>
								<Modal show={showModal} onHide={handleCloseModal}>
									<Modal.Header closeButton>
										<Modal.Title>Confirmar Finalizar Contrato</Modal.Title>
									</Modal.Header>
									<Modal.Body>
										<Form.Group className='mb-3 col-12 form-group required' controlId='date'>
											<Form.Label>Fecha de finalización</Form.Label>
											<Form.Control
												type='date'
												value={closeDate}
												onChange={(e) => setCloseDate(e.target.value)}
											></Form.Control>
										</Form.Group>
									</Modal.Body>
									<Modal.Footer>
										<Button variant='secondary' onClick={handleCloseModal}>
											Volver
										</Button>
										<Button variant='primary' onClick={handleOpenAdModal}>
											Confirmar
										</Button>
									</Modal.Footer>
								</Modal>
								<Modal show={adShowModal}>
									<Modal.Header closeButton>
										<Modal.Title>Advertencia Finalizar Contratos</Modal.Title>
									</Modal.Header>
									<Modal.Body>
						Si la fecha seleccionada es menor a la actual de alguno de los contratos seleccionados, al confirmar ésta fecha de finalización se cancelaran todas las cuotas
						y los pagos vinculados, generados con posterioridad al mes seleccionado.
									</Modal.Body>
									<Modal.Footer>
										<Button variant='secondary' onClick={handleCloseAdModal}>
								Cancelar
										</Button>
										<Button variant='primary' onClick={closeContractsHandler}>
								Confirmar
										</Button>
									</Modal.Footer>
								</Modal>
							</Row>
							<Row className='d-flex justify-content-end align-items-center list-title'>
								{query !== 'all' ? (
									<Col>
										Resultados para: {query}
										<Link
											className='btn-primary-search'
											as={Link}
											to={getFilterUrl({ query: 'all' })}
										>
											<MdClose />
										</Link>
									</Col>
								) : (
									''
								)}
								<Col className='col-lg-3 d-flex justify-content-end '>
									<SearchBox getFilterUrl={getFilterUrl} navigate={navigate} />
								</Col>
								<Col className='col-lg-1 d-flex justify-content-end w-auto'>
									<Button
										className={
											isOpen
												? 'btn btn-dark btn-dark-open btn-sm m-2'
												: 'btn btn-dark btn-dark btn-sm m-2'
										}
										onClick={handleDropdownToggle}
										aria-expanded={isOpen}
									>
										<FaFilter />
									</Button>
								</Col>
							</Row>
						</Row>
					</Col>
				</Row>
				<div className={isOpen ? 'table-container filter-opened' : 'table-container'}>
					{isOpen && (
						<FilterForm
							filterOptions={filterOptions}
							isOpen={isOpen}
							handleInputChange={handleInputChange}
							handleDropdownToggle={handleDropdownToggle}
							clearFilter={() => handleClearFilter('Alquileres')}
							handleApplyFilter={() => handleApplyFilter('Alquileres')}
						/>
					)}
					<DynamicListTableNew
						data={contracts}
						loading={loading}
						error={error}
						count={countContracts}
						onPageChange={onPageChange}
						page={parseInt(page)}
						pageSize={pageSize}
						handleSort={handleSort}
						sortColumn={column}
						direction={direction}
						actionButtons={{
							seeDetails: seeDetails,
							editHandler: editPaymentHandler,
							deleteHandler: deletePaymentHandler,
						}}
						dataName='contracts'
						showCheckboxColumn={true}
						columnInfo={contractsColumns}
					/>
				</div>
			</div>
		</div>
	);
}

export default ContractList;

import moment from 'moment';
import React, { useEffect, useReducer, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Form from 'react-bootstrap/Form';
import { Helmet } from 'react-helmet-async';
import { MdLibraryAdd } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormatedNumberInput from '../../components/FormatedNumberInput';
import LoadingBox from '../../components/LoadingBox';
import debtService from '../../services/debt.service';
import generatedDebtService from '../../services/generatedDebts.service';
import { getError } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_SUCCESS':
		return { ...state, debt: action.payload, loadingFetch: false };
	case 'FETCH_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false,
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };
	default:
		return state;
	}
};
function NewDebtDue() {
	const navigate = useNavigate();

	const [amount, setAmount] = useState();
	const [date, setDate] = useState();
	const params = useParams();
	const { id } = params;
	const [customId, setCustomId] = useState();
	const [expiration, setExpiration] = useState('');
	const [paid, setPaid] = useState('false');
	const [debtInit, setDebtInit] = useState();
	const [debtCloseDate, setDebtCloseDate] = useState();
	const [debtEndDate, setDebtEndDate] = useState();
	const [debtDueDay,setDebtDueDate]=useState();
	const [debtCustomId,setDebtCustomId] = useState();

	const [{ loadingFetch, loadingCreate, debt }, dispatch] = useReducer(reducer, {
		loadingFetch: true,
		debts: {},
		loadingCreate: false,
		error: '',
	});

	useEffect(() => {
		const fetchData = async () => {
			dispatch({ type: 'FETCH_REQUEST' });
			try {
				const result = await debtService.getDetails(id);
				setDebtDueDate(result.data.dueDay);
				setAmount(result.data.amount);
				setDebtCustomId(result.data.customId);
				setDebtInit(moment.utc(result.data.firstDueExpiration));
				if ((result.data.concept === 'prestamos-amortizable') || (result.data.concept === 'ventas-a-plazo')){
					calculateDebtEnd(result.data.firstDueExpiration,result.data.periodicity,result.data.dues,result.data.numMonths);}
				else{
					setDebtCloseDate(result.data.closeDate);
					setDebtEndDate(result.data.endDate);}

				dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: error.message });
			}
		};
		fetchData();
	}, [id]);

	const calculateDebtEnd = async (debtInit, debtIdPeriodicity, debtIdDues,debtIdnumMonths) => {
		let numMonths = 0;
		if (debtIdPeriodicity === 'mensual') { 
			numMonths = 1; 
		} else if (debtIdPeriodicity === 'semestral') { 
			numMonths = 6; 
		} else if (debtIdPeriodicity === 'anual') { 
			numMonths = 12; 
		} else if (debtIdPeriodicity === 'otra') { 
			numMonths = debtIdnumMonths; 
		}
		const totalMonthsToAdd = parseInt(debtIdDues) * numMonths;

		const calculatedEndDate = moment.utc(debtInit).add(totalMonthsToAdd, 'months');
		setDebtEndDate(moment.utc(calculatedEndDate).format('YYYY-MM'));
	};

	const handleDateChange = (newDate) => {
		setDate(newDate);
		const expirationDate = moment.utc(newDate)
			.date(debtDueDay);
		setExpiration(moment.utc(expirationDate).format('YYYY-MM-DD'));
	};
	
	

	const addDueHandler = async () => {
		if (!debt) {
			toast.error('Debe seleccionar deudor.');
			return;
		}
		if (!date) {
			toast.error('Debe seleccionar fecha.');
			return;
		}
		if (!amount) {
			toast.error('Debe seleccionar monto.');
			return;
		}
		if (!paid) {
			toast.error('Debe seleccionar pago.');
			return;
		}
		if (!customId) {
			toast.error('Debe indicar detalle.');
			return;
		}
		if (!expiration) {
			toast.error('Debe seleccionar vencimiento.');
			return;
		}
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			await generatedDebtService.create({
				amount,
				debt,
				date,
				customId,
				paid,
				expiration,
			});
			dispatch({ type: 'CREATE_SUCCESS' });
			toast.success('Cuota creada');
			navigate(-1);
		} catch (ex) {
			toast.error(getError(ex));
		}
	};

	return (
		<div>
			<Modal size='lg' show={true} animation={false}>
				{(loadingCreate || loadingFetch) && <LoadingBox></LoadingBox>}
				<div className='container admin-con align-items-center'>
					<Helmet>
						<title>Agregar Cuota</title>
					</Helmet>
					<h1>
						<MdLibraryAdd></MdLibraryAdd>Agregar Cuota
					</h1>
					<Form className='admin-form' onSubmit={(e) => e.preventDefault()}>
						<div className='row align-items-center'>
							<Form.Group className='mb-3 col-6 form-group required'>
								<Form.Label>Deudor</Form.Label>
								<Form.Group >
									<Form.Control
										defaultValue={debtCustomId}
										disabled
									></Form.Control>
								</Form.Group>
							</Form.Group>
							<Form.Group className='mb-3 col-6 form-group required' controlId='amount'>
								<Form.Label>Monto</Form.Label>
								<FormatedNumberInput
									type='number'
									value={amount ? amount : null}
									onChange={(e) => setAmount(e.target.value)}
								></FormatedNumberInput>
							</Form.Group>
							<Form.Group className='mb-3 col-4 form-group required' controlId='date'>
								<Form.Label>mes de cuota</Form.Label>
								<Form.Control
									type='month'
									onChange={(e) => handleDateChange(e.target.value)}
									min={moment.utc(debtInit).format('YYYY-MM')}
									max={
										debtCloseDate
											? moment.utc(debtCloseDate).format('YYYY-MM')
											: debtEndDate
												? moment.utc(debtEndDate).format('YYYY-MM')
												: undefined
									}
								></Form.Control>
							</Form.Group>
							<Form.Group className='mb-3 col-4 form-group required' controlId='date'>
								<Form.Label>Vencimiento</Form.Label>
								<Form.Control
									type='date'
									disabled
									value={moment.utc(expiration).format('YYYY-MM-DD')}
								></Form.Control>
							</Form.Group>
							<Form.Group className='mb-3 col-4 form-group'>
								<Form.Label>Paga?</Form.Label>
								<Form.Select onChange={(e) => setPaid(e.target.value)} value={paid}>
									<option key='false' value='false'>
										No
									</option>
									<option key='true' value='true'>
										Sí
									</option>
								</Form.Select>
							</Form.Group>
							<Form.Group style={{ marginBottom: '5%' }} className='col-12' controlId='field'>
								<Form.Label>Detalle</Form.Label>
								<Form.Control
									type='text'
									as='textarea'
									rows={10}
									onChange={(e) => setCustomId(e.target.value)}
								></Form.Control>
							</Form.Group>
						</div>
						<Row className='modal-btns'>
							<Col>
								<Button className='cancelar' onClick={() => navigate(-1)}>
									Cancelar
								</Button>
							</Col>
							<Col>
								<Button className='guardar' onClick={addDueHandler}>
									Agregar
								</Button>
							</Col>
						</Row>
					</Form>
				</div>
			</Modal>
		</div>
	);
}
export default NewDebtDue;

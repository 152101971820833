import React, { useReducer } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Helmet } from 'react-helmet-async';
import LoadingBox from '../../components/LoadingBox';
import GenericDropdown from '../../components/SelectDropdown';
import moment from 'moment';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_SUCCESS':
		return { ...state, assetSale: action.payload, loadingFetch: false };
	case 'FETCH_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false,
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };
	default:
		return state;
	}
};
function AssetSaleInfo({ assetSale }) {
	const currencies = ['USD', 'UYU'];

	const [{ loadingFetch, loadingCreate }] = useReducer(reducer, {
		loadingFetch: true,
		loadingCreate: false,
		error: '',
	});
	const asset = assetSale.asset;

	async function verDocumento(assetSale) {
		if (assetSale.document) {
			window.open(assetSale.document.fileLink, '_blank', 'noopener,noreferrer');
		}
	}

	return (
		<div>
			{loadingCreate && loadingFetch && <LoadingBox></LoadingBox>}
			<Helmet>
				<title>Venta de activo</title>
			</Helmet>
			<div className='container admin-con align-items-center'>
				<Form className='admin-form'>
					<Row className='mb-3'>
						<Form.Group className='mb-3 col-3 form-group required' controlId='Date'>
							<Form.Label>Fecha</Form.Label>
							<Form.Control
								type='date'
								value={moment(assetSale.date).utc().format('YYYY-MM-DD')}
								disabled
							></Form.Control>
						</Form.Group>
						<Form.Group as={Col} className='mb-3 col-2' controlId='currency'>
							<GenericDropdown
								label='Moneda'
								items={currencies}
								selected={assetSale.currency}
								disabled
							/>
						</Form.Group>
						<Form.Group as={Col} className='mb-3 col-5 form-group required' controlId='amount'>
							<Form.Label>Monto</Form.Label>
							<Form.Control defaultValue={assetSale.amount} disabled></Form.Control>
						</Form.Group>
						<Form.Group
							as={Col}
							className='mb-3 col-5 form-group required'
							controlId='initialPayment'
						>
							<Form.Label>Entrega Inicial</Form.Label>
							<Form.Control defaultValue={assetSale.initialPayment} disabled></Form.Control>
						</Form.Group>
						<Form.Group as={Col} className='mb-3 col-5 form-group required' controlId='sumDebts'>
							<Form.Label>Total en Deudas</Form.Label>
							<Form.Control defaultValue={assetSale.sumDebts} disabled></Form.Control>
						</Form.Group>
					</Row>
					<Row>
						<Form.Group as={Col} className='mb-3 col-6 form-group required' controlId='email'>
							<Form.Label>Activo</Form.Label>
							<Form.Control
								defaultValue={asset && asset.customId ? asset.customId : ''}
								disabled
							></Form.Control>
						</Form.Group>
						<Form.Group className='mb-3 col-6' controlId='periodicity'>
							<Form.Label>Padrón</Form.Label>
							<Form.Control type='text' defaultValue={assetSale.lot} disabled></Form.Control>
						</Form.Group>
						<Form.Group as={Col} className='mb-3 col-6 form-group required' controlId='email'>
							<Form.Label>Comprador</Form.Label>
							<Form.Control
								defaultValue={assetSale.purchaser ? assetSale.purchaser.name : ''}
								disabled
							></Form.Control>
						</Form.Group>
					</Row>
					<Row>
						<Form.Group>
							<Button
								className='col-2'
								style={{ height: '30px', marginTop: '32px', padding: '0' }}
								disabled={!assetSale.document}
								onClick={() => verDocumento(assetSale)}
							>
								Ver Escritura
							</Button>
						</Form.Group>
					</Row>
				</Form>
			</div>
		</div>
	);
}
export default AssetSaleInfo;

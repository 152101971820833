import React, { useEffect, useReducer, useState } from 'react';
import {
	Button,
	Col,
	Dropdown,
	Row,
	SplitButton
} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { FaFilter } from 'react-icons/fa';
import { MdClose, MdOutlineMoney } from 'react-icons/md';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DynamicListTableNew from '../../components/DynamicListTableNew';
import FilterForm from '../../components/FilterForm';
import ListButtons from '../../components/ListButtons';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import SearchBox from '../../components/SearchBox';
import DebtService from '../../services/debt.service';
import PaymentService from '../../services/payment.service';
import { getError } from '../../utils';
import { debtsColumns } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return {
			...state,
			debts: action.payload.debts,
			page: action.payload.page,
			pages: action.payload.pages,
			countDebts: action.payload.countDebts,
			pageSize: action.payload.pageSize,
			loading: false,
		};
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'DELETE_REQUEST':
		return { ...state, loadingDelete: true, successDelete: false };
	case 'DELETE_SUCCESS':
		return {
			...state,
			loadingDelete: false,
			successDelete: true,
		};
	case 'DELETE_FAIL':
		return { ...state, loadingDelete: false, successDelete: false };

	case 'DELETE_RESET':
		return { ...state, loadingDelete: false, successDelete: false };
	default:
		return state;
	}
};

function DebtList() {

	const [loadingInitial, setLoadingInitial] = useState(true);
	const navigate = useNavigate();
	const { search } = useLocation();
	const sp = new URLSearchParams(search);
	const concept = sp.get('concept') || 'all';
	const currency = sp.get('currency') || 'all';
	const capitalTo = sp.get('capitalTo') || '';
	const capitalFrom = sp.get('capitalFrom') || '';
	const amountTo = sp.get('amountTo') || '';
	const amountFrom = sp.get('amountFrom') || 0;
	const dateFrom = sp.get('dateFrom') || '';
	const dateTo = sp.get('dateTo') || '';
	const endDateFrom = sp.get('endDateFrom') || '';
	const endDateTo = sp.get('endDateTo') || '';
	const query = sp.get('query') || 'all';
	let page = sp.get('page') || 1;
	const column = sp.get('column') || 'customId';
	const direction = sp.get('direction') || 'asc';
	const concepts = ['prestamos-amortizable', 'prestamos-interés', 'alquileres', 'ventas-a-plazo'];
	const [filtered, setFiltered] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	const handleDropdownToggle = () => {
		setIsOpen(!isOpen);
	};

	const [filterOptions, setFilterOptions] = useState({
		formType: 'Deudas',
		concept: concept,
		dateFrom: dateFrom,
		dateTo: dateTo,
		endDateFrom: endDateFrom,
		endDateTo: endDateTo,
		amountFrom: amountFrom,
		amountTo: amountTo,
		capitalFrom: capitalFrom,
		capitalTo: capitalTo,
		currency: currency,
		query: query,
	});

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFilterOptions((prevOptions) => ({
			...prevOptions,
			[name]: value,
			formType: filterOptions.formType,
		}));
	};

	const handleConceptInputChange = (value) => {
		setFilterOptions((prevOptions) => ({
			...prevOptions,
			concept: value,
		}));
	};

	const handleApplyFilter = () => {
		const {
			concept,
			dateTo,
			dateFrom,
			endDateFrom,
			endDateTo,
			amountFrom,
			amountTo,
			capitalFrom,
			capitalTo,
			currency,
		} = filterOptions;
		const filter = {
			concept,
			dateFrom,
			dateTo,
			endDateFrom,
			endDateTo,
			amountFrom,
			amountTo,
			capitalFrom,
			capitalTo,
			currency,
			page: 1,
			query,
		};
		const filterUrl = getFilterUrl(filter);
		navigate(filterUrl);
		setFiltered(true);
	};

	useEffect(() => {
		handleApplyFilter();
	}, [filterOptions.concept, filtered]);

	const handleClearFilter = () => {
		setFilterOptions({
			formType: 'Deudas',
			concept: 'all',
			dateFrom: '',
			dateTo: '',
			endDateFrom: '',
			endDateTo: '',
			amountFrom: 0,
			amountTo: '',
			capitalFrom: '',
			capitalTo: '',
			currency: 'all',
			query: 'all',
		});
		setFiltered(false);
	};

	const [{ loading, error, debts, successDelete, pageSize, countDebts }, dispatch] =
		useReducer(reducer, {
			debts: [],
			loading: true,
			error: '',
			pageSize: 0,
			countDebts: 0,
		});

	const onPageChange = (page) => {
		navigate(getFilterUrl({ page: page }));
	};
	const myTitle = (
		<h1 className='d-flex justify-content-start align-items-center list-title-dropdown'>
			{concept === 'all' ? 'Todas las Deudas' : concept[0].toUpperCase() + concept.substring(1)}
		</h1>
	);

	const buttonItems = [
		{
			label: 'Agregar Deuda',
			onClick: () => newDebtHandler(),
		},
		{
			label: 'Agregar Cobro',
			onClick: () => newPaymentHandler(),
		},
	];

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch({ type: 'FETCH_REQUEST' });
				const { data } = await DebtService.search(
					`page=${page}&concept=${concept}&query=${query}&column=${column}&direction=${direction}&currency=${currency}&capitalFrom=${capitalFrom}&capitalTo=${capitalTo}&amountFrom=${amountFrom}&amountTo=${amountTo}&dateFrom=${dateFrom}&dateTo=${dateTo}&endDateFrom=${endDateFrom}&endDateTo=${endDateTo}`,
				);
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
				console.error(getError(err));
				toast.error('No se pudo obtener lista');
			} finally {
				setLoadingInitial(false);
			}
		};
		if (successDelete) {
			dispatch({ type: 'DELETE_RESET' });
		}
		fetchData();
	}, [
		successDelete,
		concept,
		query,
		page,
		column,
		direction,
		currency,
		capitalTo,
		capitalFrom,
		amountTo,
		amountFrom,
		dateFrom,
		dateTo,
		endDateFrom,
		endDateTo,
	]);

	async function deleteRelatedPayments(debt) {
		let paymentDeleteOk;
		try {
			dispatch({ type: 'DELETE_REQUEST' });
			await PaymentService.deletePaymentsForDebt(debt._id);
			dispatch({ type: 'DELETE_SUCCESS' });
			paymentDeleteOk = true;
		} catch (err) {
			dispatch({ type: 'DELETE_FAIL' });
			paymentDeleteOk = false;
		}
		return paymentDeleteOk;
	}

	const handleSort = (column) => {
		const newSortDirection = column === column && direction === 'desc' ? 'asc' : 'desc';
		const newURL = `/AdminScreen/debts/search?page=${page}&concept=${concept}&query=${query}&column=${column}&direction=${newSortDirection}&currency=${currency}&capitalFrom=${capitalFrom}&capitalTo=${capitalTo}&amountFrom=${amountFrom}&amountTo=${amountTo}&dateFrom=${dateFrom}&dateTo=${dateTo}&endDateFrom=${endDateFrom}&endDateTo=${endDateTo}`;
		navigate(newURL);
	};

	async function deleteDebtHandler(debt) {
		if (
			window.confirm(
				'Seguro desea eliminar deuda? Si elimina deuda, seran eliminados todos los pagos relacionados',
			)
		) {
			const paymentDeleteOk = await deleteRelatedPayments(debt);
			// deleteRelatedInterests(debt)
			try {
				if (paymentDeleteOk) {
					dispatch({ type: 'DELETE_REQUEST' });
					await DebtService.delete(debt._id);
					dispatch({ type: 'DELETE_SUCCESS' });
					toast.success('deuda Eliminada');
				} else {
					toast.error('Error al eliminar la deuda debido a problemas con los pagos relacionados');
				}
			} catch (err) {
				dispatch({ type: 'DELETE_FAIL' });
				console.error(getError(err));
				toast.error('Error al eliminar cuenta');
			}
		}
	}

	async function editDebtHandler(debt) {
		navigate(`/AdminScreen/debts/editDebt/${debt._id}`);
	}

	async function editDateHandler(debt) {
		navigate(`/AdminScreen/debts/editFirstDueDate/${debt._id}`);
	}
	async function seeDetails(debt) {
		navigate(`/AdminScreen/debts/debt/${debt._id}`);
	}
	async function newDebtHandler() {
		navigate('/AdminScreen/newDebt');
	}
	async function newPaymentHandler() {
		navigate('/AdminScreen/newPayment');
	}
	const getFilterUrl = (filter) => {
		const {
			concept,
			query,
			dateFrom,
			dateTo,
			endDateFrom,
			endDateTo,
			amountFrom,
			amountTo,
			capitalFrom,
			capitalTo,
			currency,
		} = filterOptions;
		const filterConcept = concept || concept;
		const filterQuery = filter.query || query;
		const filterPage = filter.page || 1;
		const filterDateFrom = dateFrom || '';
		const filterDateTo = dateTo || '';
		const filterEndDateFrom = endDateFrom || '';
		const filterEndDateTo = endDateTo || '';
		const filterAmountFrom = amountFrom || '';
		const filterAmountTo = amountTo || '';
		const filterCapitalFrom = capitalFrom || '';
		const filterCapitalTo = capitalTo || '';
		const filterCurrency = currency || '';

		const params = new URLSearchParams();
		params.append('page', filterPage);
		params.append('concept', filterConcept);
		params.append('query', filterQuery);
		params.append('dateFrom', filterDateFrom);
		params.append('dateTo', filterDateTo);
		params.append('endDateFrom', filterEndDateFrom);
		params.append('endDateTo', filterEndDateTo);
		params.append('amountFrom', filterAmountFrom);
		params.append('amountTo', filterAmountTo);
		params.append('capitalFrom', filterCapitalFrom);
		params.append('capitalTo', filterCapitalTo);
		params.append('currency', filterCurrency);

		return `/AdminScreen/debts/search?${params.toString()}`;
	};

	return loadingInitial ? (
		<LoadingBox></LoadingBox>
	) : error ? (
		<MessageBox variant='danger'>{error}</MessageBox>
	) : (
		<div>
			<Helmet>
				<title>Lista de Deudas</title>
			</Helmet>
			<div className='admin-con'>
				<Row>
					<Col>
						<Row className='d-flex justify-content-center'>
							<Row>
								<h2 className='d-flex justify-content-start align-items-center list-title'>
									<MdOutlineMoney></MdOutlineMoney>Deudas
								</h2>
							</Row>
							<Row className='title-btns-row'>
								<SplitButton
									className='btn title-dropdown text-align-start p-0'
									style={{ width: 'fit-content' }}
									align='end'
									title={myTitle}
								>
									<Dropdown.Item onClick={() => handleConceptInputChange('all')} eventKey='all'>
										Todos
									</Dropdown.Item>
									{concepts.map((concept, index) => (
										<Dropdown.Item
											key={index}
											value={concept}
											onClick={() => handleConceptInputChange(concept)}
											eventKey={concept}
										>
											{concept}
										</Dropdown.Item>
									))}
								</SplitButton>
								<ListButtons buttonItems={buttonItems} />
							</Row>
							<Row className='d-flex justify-content-end align-items-center list-title'>
								{query !== 'all' ? (
									<Col>
										Resultados para: {query}
										<Link
											className='btn-primary-search'
											as={Link}
											to={getFilterUrl({ query: 'all' })}
										>
											<MdClose />
										</Link>
									</Col>
								) : (
									''
								)}
								<Col className='col-lg-3 d-flex justify-content-end'>
									<SearchBox getFilterUrl={getFilterUrl} navigate={navigate} />
								</Col>

								<Col className='col-lg-1 d-flex justify-content-end w-auto'>
									<Button
										className={
											isOpen
												? 'btn btn-dark btn-dark-open btn-sm m-2'
												: 'btn btn-dark btn-dark btn-sm m-2'
										}
										onClick={handleDropdownToggle}
									>
										{' '}
										<FaFilter />
									</Button>
								</Col>
							</Row>
						</Row>
					</Col>
				</Row>
				<div className={isOpen ? 'table-container filter-opened' : 'table-container'}>
					{isOpen && (
						<FilterForm
							filterOptions={filterOptions}
							handleInputChange={handleInputChange}
							isOpen={isOpen}
							handleDropdownToggle={handleDropdownToggle}
							clearFilter={() => handleClearFilter('Deudas')}
							handleApplyFilter={() => handleApplyFilter('Deudas')}
						/>
					)}
					<DynamicListTableNew
						data={debts}
						loading={loading}
						error={error}
						count={countDebts}
						onPageChange={onPageChange}
						page={parseInt(page)}
						pageSize={pageSize}
						handleSort={handleSort}
						sortColumn={column}
						direction={direction}
						actionButtons={{
							seeDetails: seeDetails,
							editHandler: editDebtHandler,
							editDateHandler:editDateHandler,
							deleteHandler: deleteDebtHandler,
						}}
						dataName='debts'
						showCheckboxColumn={false}
						columnInfo={debtsColumns}
					/>
				</div>
			</div>
		</div>
	);
}

export default DebtList;

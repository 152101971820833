import moment from 'moment';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Button, Card, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Helmet } from 'react-helmet-async';
import { MdLibraryAdd, MdWarning } from 'react-icons/md';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import CustomTypeahead from '../../components/CustomTypeahead';
import FormatedNumberInput from '../../components/FormatedNumberInput';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import GenericDropdown from '../../components/SelectDropdown';
import accountService from '../../services/account.service';
import creditService from '../../services/credit.service';
import { currencies, dueDays, getError, monthOptions, periodicities } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_SUCCESS':
		return { ...state, accounts: action.payload, loadingFetch: false };
	case 'FETCH_FAIL':
		return { ...state, loadingFetch: false, errorFetch: action.payload };
	case 'FETCH_ACCOUNT_REQUEST':
		return { ...state, loadingFetch: true };
	case 'FETCH_ACCOUNT_SUCCESS':
		return { ...state, loadingFetch: false };
	case 'CREATE_REQUEST':
		return { ...state, loadingCreate: true };
	case 'CREATE_SUCCESS':
		return {
			...state,
			loadingCreate: false,
		};
	case 'CREATE_FAIL':
		return { ...state, loadingCreate: false };
	case 'CALC':
		return { ...state, loadingCalc: true };
	case 'CALC_SUCCESS':
		return {
			...state,
			loadingCalc: false,
		};
	case 'CALC_FAIL':
		return { ...state, loadingCalc: false };
	default:
		return state;
	}
};
function NewCredit() {
	const navigate = useNavigate();
	const { state } = useContext(Store);

	const [capital, setCapital] = useState();
	const [amount, setAmount] = useState(0);
	const [interest, setInterest] = useState();
	const [selectedConcept, setSelectedConcept] = useState();
	const [concept, setConcept] = useState();

	const concepts = ['prestamos-amortizable', 'prestamos-interés'];

	const [dues, setDues] = useState(1);
	const [date, setDate] = useState();
	const [contract] = useState();
	const [periodicity, setPeriodicity] = useState('');
	const [owner, setOwner] = useState();
	const [disabledInput, setDisabledInput] = useState(true);
	const [hasPreviousCredits, setHasPreviousCredits] = useState(true);
	const [previousCredit, setPreviousCredit] = useState();
	const [previousCreditDate, setPreviousCreditDate] = useState();
	const [currency, setCurrency] = useState();
	const [endDate, setEndDate] = useState();
	const [disabledAmount, setDisabledAmount] = useState(false);
	const [disabledOwner, setDisabledOwner] = useState(false);
	const [disabledEndDate] = useState(false);

	const currentDate = moment();
	const [createWithPreviousDues, setCreateWithPreviousDues] = useState(false);
	const [createWithPreviousPayments, setCreateWithPreviousPayments] = useState(false);
	const [dueDay, setDueDay] = useState('10'); //first due generation year always next to selected date
	const [firstDueExpiration, setFirstDueExpiration] = useState(); //first due generation date always next to selected date
	const [numMonths, setNumMonths] = useState(0);
	const [selectedMonthOption, setSelectedMonthOption] = useState('Mes actual');
	const [periodicityNumMonths, setPeriodicityNumMonths] = useState();

	const [{ loadingFetch, loadingCreate, loadingCalc, accounts, errorFetch }, dispatch] = useReducer(reducer, {
		loadingFetch: true,
		accounts: [],
		loadingCreate: false,
		loadingCalc: false,
		errorFetch: '',
	});

	const params = useParams();
	const { id } = params;
	const location = useLocation();
	const route = location.pathname;

	useEffect(() => {
		const fetchAccountData = async () => {
			try {
				dispatch({ type: route.includes('account') ? 'FETCH_ACCOUNT_REQUEST' : 'FETCH_REQUEST' });
				if (route.includes('account')) {
					const result = await accountService.getDetails(id);
					setOwner(result.data);
					setDisabledOwner(true);
					setCurrency(result.data.currency);
					dispatch({ type: 'FETCH_ACCOUNT_SUCCESS', payload: result.data });
				} else {
					const result = await accountService.list();
					dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
				}
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: error.message });
				console.error(error);
				toast.error('No se pudo obtener cuenta/s');
			}
		};		
		fetchAccountData();
	}, [id]);

	const generateDate = () => {
		const currentDate = moment.utc(date, 'YYYY-MM-DD');
		let futureDate;

		if (selectedMonthOption === 'Mes actual') {
			futureDate = currentDate.clone();
		} else if (selectedMonthOption === 'Mes siguiente') {
			futureDate = currentDate.clone().add(1, 'month');
		} else if (selectedMonthOption === 'En tres meses' || selectedMonthOption === 'En seis meses') {
			const monthsToAdd = selectedMonthOption === 'En tres meses' ? 3 : 6;
			futureDate = currentDate.clone().add(monthsToAdd, 'month');
		} else if (selectedMonthOption === 'Otro') {
			futureDate = currentDate.clone().add(numMonths, 'month');
		}

		futureDate.date(Math.min(dueDay, futureDate.daysInMonth()));

		setFirstDueExpiration(moment.utc(futureDate).format('YYYY-MM-DD'));
		return firstDueExpiration;
	};

	const dateHandler = () => {
		if (moment.utc(date).isBefore(currentDate)) {
			setCreateWithPreviousDues(true);
		} else {
			setCreateWithPreviousDues(false);
			setCreateWithPreviousPayments(false);
		}
	};

	useEffect(() => {
		generateDate();
		dateHandler();
	}, [date, selectedMonthOption, dueDay, numMonths]);

	const isAddCreditButtonEnabled = () => {
		const emptyFields = [];

		if (!date) {
			emptyFields.push('Fecha');
		}
		if (!selectedConcept && !concept) {
			emptyFields.push('Concepto');
		}
		if (selectedConcept === 'otro' && !concept) {
			emptyFields.push('Otro Concepto');
		}
		if (!amount) {
			emptyFields.push('Monto');
		}
		if (!currency) {
			emptyFields.push('Moneda');
		}
		if (concept == 'alquileres' && !contract) {
			emptyFields.push('Propiedad');
		}
		if (
			(selectedConcept === 'prestamos-amortizable' || selectedConcept === 'prestamos-interés') &&
			(!capital || !interest || !dues)
		) {
			if (!capital) emptyFields.push('Capital');
			if (!interest) emptyFields.push('Interés');
			if (!periodicity) emptyFields.push('Periodicidad');
		}
		if (selectedConcept === 'prestamos-amortizable') {
			if (!dues) emptyFields.push('Cuotas');
		}
		if (!owner) {
			emptyFields.push('Cuenta');
		}
		return emptyFields.length === 0;
	};

	const addCreditHandler = async () => {
		const emptyFields = [];

		if (!date) {
			emptyFields.push('Fecha');
		}
		if (!selectedConcept && !concept) {
			emptyFields.push('Concepto');
		}
		if (selectedConcept === 'otro' && !concept) {
			emptyFields.push('Otro Concepto');
		}
		if (!amount) {
			emptyFields.push('Monto');
		}
		if (!currency) {
			emptyFields.push('Moneda');
		}
		if (concept == 'alquileres' && !contract) {
			emptyFields.push('Propiedad');
		}
		if (
			(selectedConcept === 'prestamos-amortizable' || selectedConcept === 'prestamos-interés') &&
			(!capital || !interest || !dues)
		) {
			if (!capital) emptyFields.push('Capital');
			if (!interest) emptyFields.push('Interés');
			if (!periodicity) emptyFields.push('Periodicidad');
		}
		if (selectedConcept === 'prestamos-amortizable') {
			if (!dues) emptyFields.push('Cuotas');
		}
		if (!owner) {
			emptyFields.push('Cuenta');
		}
		if (emptyFields.length > 0) {
			const errorMessages = `Debe completar el/los campo/s: ${emptyFields.join(', ')}`;
			toast.error(errorMessages);
			return;
		}
		if (moment.utc(firstDueExpiration).isBefore(moment.utc(date))) {
			toast.error('La fecha de vencimiento no puede ser menor a la fecha seleccionada');
			return;
		}
		try {
			dispatch({ type: 'CREATE_REQUEST' });
			if (!owner) {
				toast.error('Debe seleccionar una cuenta.');
				return;
			}
			const requestData = {
				amount,
				concept,
				date: moment.utc(date),
				owner: owner[0] || owner,
				currency,
				guaranteedAmount: 0,
				dueDay,
				selectedMonthOption,
				firstDueExpiration: moment.utc(firstDueExpiration),
				createWithPreviousDues,
				createWithPreviousPayments,
				createdBy: state.userInfo._id,
				periodicity: periodicity,
			};
			//check if is 'alquileres' to set contract
			if (concept == 'alquileres' && contract.length > 0) {
				requestData.contract = contract[0];
				requestData.endDate = endDate;
				requestData.periodicity = '';
				requestData.interest = '';
				requestData.dues = '';
				requestData.capital = '';
				requestData.dues = '';
			}
			//check if is 'prestamos' to set other fields
			if (selectedConcept.includes('prestamos')) {
				requestData.periodicity = periodicity;
				requestData.interest = interest;
				requestData.capital = capital;
			}
			if (selectedConcept === 'prestamos-interés') {
				requestData.endDate = endDate;
			}
			if (selectedConcept === 'prestamos-amortizable') {
				requestData.dues = dues;
			}
			//check if is 'hasPreviousCredits' to set other fields
			if (hasPreviousCredits) {
				requestData.hasPreviousCredits = hasPreviousCredits;
				requestData.previousCredit = previousCredit;
				requestData.previousCreditDate = previousCreditDate;
			}
			//check if is dont 'hasPreviousCredits' to unset other fields
			if (!hasPreviousCredits) {
				requestData.hasPreviousCredits = false;
				requestData.previousCredit = '';
				requestData.previousCreditDate = '';
			}
			if (selectedMonthOption === 'Otro') {
				requestData.numMonths = numMonths;
			}
			if (periodicity === 'otra') {
				requestData.periodicityNumMonths = periodicityNumMonths;
			}



			await creditService.create(
				requestData,
				createWithPreviousDues,
				createWithPreviousPayments,
			);
			dispatch({ type: 'CREATE_SUCCESS' });
			toast.success('Pasividad creada');
			navigate(-1);
		} catch (ex) {
			toast.error(getError(ex));
		}
	};
	const [isInvalid, setIsInvalid] = useState(false);

	function getValorDeCuotaFijaMensualAmortizable() {
		if (!capital || !interest || !dues) {
			toast.error('Debe ingresar Capital, interés y cuotas');
			return;
		} else {
			if (interest == 0) {
				const valorCuota = capital / dues;
				setAmount(valorCuota);
			} else {
				const tasa = interest / 100;
				const valorCuota =
					capital * ((tasa * Math.pow(1 + tasa, dues)) / (Math.pow(1 + tasa, dues) - 1));
				setAmount(Math.ceil(valorCuota));
			}
		}
	}

	function getValorDeCuotaFijaMensualInteres() {
		if (!capital || !interest) {
			toast.error('Debe ingresar Capital e interés');
			return;
		}
		const tasa = interest / 100;
		const valorCuota = capital * tasa;
		setAmount(Math.ceil(valorCuota));
	}

	//for calc button
	function AmountCalc() {
		dispatch({ type: 'CALC' });
		if (concept === 'prestamos-amortizable' || concept === 'ventas-a-plazo') {
			getValorDeCuotaFijaMensualAmortizable();
			setDisabledAmount(false);
			dispatch({ type: 'CALC_SUCCESS' });
		}
		if (concept === 'prestamos-interés') {
			getValorDeCuotaFijaMensualInteres();
			dispatch({ type: 'CALC_SUCCESS' });
		}
	}

	//account set only if 'alquileres'
	const handleAccountChange = (selected) => {
		if (!selected || selected.length === 0) {
			const selectedAccount = selected[0];
			const isValid = selectedAccount ? accounts.includes(selectedAccount) : false;
			setIsInvalid(!isValid);
			setOwner('');
			setCurrency('');
			setDisabledAmount(false);
		} else {
			const selectedAccount = selected[0];
			const isValid = selectedAccount ? accounts.includes(selectedAccount) : false;
			setIsInvalid(!isValid);
			setOwner(selectedAccount);
			setCurrency(selectedAccount.currency);
			setDisabledAmount(true);
		}
	};

	const handleSelectedConcept = (e) => {
		setSelectedConcept(e.value);
		if (e.value === 'prestamos-amortizable' || e.value === 'prestamos-interés') {
			setDisabledInput(false);
			setDisabledOwner(false);
		}
		if (e.value !== 'otro') {
			setConcept(e.value);
		}
	};

	return (
		<div>
			<Modal size='xl' show={true} animation={false}>
				{(loadingFetch || loadingCalc) ? (
					<LoadingBox></LoadingBox>
				) : (
					errorFetch ? (
						<MessageBox variant='danger'>{errorFetch}</MessageBox>
					) : null
				)}				<div className='container admin-con align-items-center'>
					<Helmet>
						<title>Agregar Pasividad</title>
					</Helmet>
					<h1>
						<MdLibraryAdd></MdLibraryAdd>Agregar Pasividad
					</h1>
					<Form className='admin-form' onSubmit={(e) => e.preventDefault()}>
						{loadingCreate && <LoadingBox></LoadingBox>}
						<div className='row align-items-center'>
							<Card className='d-flex mb-3 justify-content-center card-form-debts'>
								<Row>
									<h2 className='text-center m-3'> Datos para la generación de cuotas</h2>
								</Row>
								<Row className='d-flex mb-3 justify-content-center'>
									<OverlayTrigger
										placement='left'
										overlay={
											<Tooltip id='tooltip-previous-debt'>
												<MdWarning />
												Es la fecha de inicio del crédito, si usted selecciona una fecha anterior al
												día de hoy se crearán las cuotas pasadas, si no es lo que desea seleccione
												una fecha igual o mayor a la de hoy.
											</Tooltip>
										}
									>
										<Form.Group className='mb-3 col-3 form-group required' controlId='Date'>
											<Form.Label>Fecha de inicio de credito</Form.Label>
											<Form.Control
												type='date'
												onChange={(e) => setDate(e.target.value)}
											></Form.Control>
										</Form.Group>
									</OverlayTrigger>
									<Form.Group className='mb-3 col-3 form-group required' controlId='DaysDropdown'>
										<GenericDropdown
											label='Día vencimiento de 1er cuota:'
											items={dueDays}
											selected={dueDay}
											onChange={(e) => setDueDay(e.value)}
										/>
									</Form.Group>
									<Form.Group
										className='mb-3 col-2 form-group required'
										controlId='selectedMonthOption'
									>
										<GenericDropdown
											label='Generar a partir de:'
											items={monthOptions}
											selected={selectedMonthOption}
											onChange={(e) => setSelectedMonthOption(e.value)}
										/>
									</Form.Group>
									{selectedMonthOption === 'Otro' && (
										<Form.Group className='mb-3 col-3 form-group required' controlId='numMonths'>
											<Form.Label>Ingrese en cuantos meses:</Form.Label>
											<Form.Control
												type='number'
												min='1'
												max ='240'
												value={numMonths}
												onChange={(e) => setNumMonths(parseInt(e.target.value, 10))}
												onInput={(e) => {
													const inputValue = parseInt(e.target.value, 10);
													const maxValue = 240;
													if (maxValue !== null && inputValue > maxValue) {
														e.target.value = maxValue;
													}
												}}
											/>
										</Form.Group>
									)}
								</Row>
								<Row>
									<div className='d-flex d-inline justify-content-center align-items-center'>
										<p>
											{date
												? `Primer Vencimiento: ${moment.utc(firstDueExpiration).format('DD-MM-YYYY')}  `
												: ''}
										</p>
									</div>
								</Row>
							</Card>
							{createWithPreviousDues ? (
								<Card className='d-flex align-items-center justify-content-center mb-3 card-form-debts'>
									<Row>
										<h2 className='text-center m-3'>
											<MdWarning />
											Deuda con cuotas previas
										</h2>
									</Row>
									<Row>
										<p>
											Usted está por crear un crédito con fecha anterior a la fecha de hoy, si no
											desea crear cuotas previas debe ingresar una fecha igual o posterior al día de
											hoy
										</p>
									</Row>
									<Form.Group
										as={Row}
										className='mb-3 align-items-center col-8'
										controlId='createWithPreviousPayments'
									>
										<Form.Check
											type='checkbox'
											className='mb-3 align-items-center col-5 justify-content-center text-center'
											label='Marcar cuotas previas como pagas'
											checked={createWithPreviousPayments}
											onChange={() => setCreateWithPreviousPayments(!createWithPreviousPayments)}
										/>
									</Form.Group>
								</Card>
							) : (
								''
							)}
							<Card className='d-flex justify-content-center mb-3 card-form-debts'>
								<Row>
									<h2 className='text-center m-3'>Información contable</h2>
								</Row>
								<Row>
									<Form.Group className='mb-3 col-6 form-group required'>
										<GenericDropdown
											label='Concepto'
											items={concepts}
											selected={selectedConcept}
											onChange={handleSelectedConcept}
										/>
									</Form.Group>
								</Row>
								<Row>
									{!route.includes('account') &&(
										<Form.Group className='mb-3 col-6 form-group required'>
											<Form.Label>Acreedor</Form.Label>
											<CustomTypeahead
												id='accounts'
												labelKey={(option) => `${option.name}`}
												onChange={handleAccountChange}
												options={accounts}
												disabled={disabledOwner}
												selected={owner ? [owner] : null}
												placeholder='Buscar Cuenta... '
												isInvalid={isInvalid}
												linkToCreate='/AdminScreen/newAccount'
												newOption='nueva cuenta'
											/>
											{isInvalid && <div style={{ color: 'red' }}>Cuenta invalida</div>}
										</Form.Group>)}
									{route.includes('account') &&(
										<Form.Group className='mb-3 col-6 form-group required'>
											<Form.Label>Acreedor:</Form.Label>
											<Form.Control
												readOnly
												type='text'
												value={owner ? owner.name : ''}
											></Form.Control>
										</Form.Group>)}
									<Form.Group as={Col} className='mb-3 col-6 form-group required' controlId='currency' >
										<GenericDropdown
											label='Moneda'
											items={currencies}
											selected={currency}
											onChange={(e) => setCurrency(e.value)}
										/>
									</Form.Group>
									{selectedConcept && selectedConcept.includes('prestamos') && (
										<>
											<Form.Group className='mb-3 col-md-4 form-group required'>
												<GenericDropdown
													label='Periodicidad'
													items={periodicities}
													selected={periodicity}
													onChange={(e) => setPeriodicity(e.value)}
												/>
											</Form.Group>
											{periodicity === 'otra' && (
												<Form.Group className='mb-3 col-md-3' controlId='periodicityNumMonths'>
													<Form.Label>Periodicidad en meses</Form.Label>
													<FormatedNumberInput
														type='number'
														onChange={(e) => setPeriodicityNumMonths(e.target.value)}
														min={1}
														noDecimal={true}
													></FormatedNumberInput>
												</Form.Group>
											)}
											<Form.Group className='mb-3 col-md-3 form-group required' controlId='capital'>
												<Form.Label>Capital</Form.Label>
												<FormatedNumberInput
													type='number'
													onChange={(e) => setCapital(e.target.value)}
													min={0}
												></FormatedNumberInput>
											</Form.Group>
											<Form.Group
												className='mb-3 col-md-2 form-group required'
												controlId='interest'
											>
												<Form.Label>Interes %</Form.Label>
												<FormatedNumberInput
													type='number'
													onChange={(e) => setInterest(e.target.value)}
													min={0}
												></FormatedNumberInput>
											</Form.Group>
											{selectedConcept !== 'prestamos-amortizable' && (
												<Form.Group className='mb-3 col-md-3 form-group' controlId='endDate'>
													<Form.Label>Fecha de fin</Form.Label>
													<Form.Control
														type='date'
														disabled={disabledEndDate}
														value={endDate ? moment(endDate).format('YYYY-MM-DD') : null}
														onChange={(e) => setEndDate(e.target.value)}
													></Form.Control>
												</Form.Group>
											)}
											{selectedConcept === 'prestamos-amortizable' && (
												<Form.Group className='mb-3 col-md-3 form-group required' controlId='dues'>
													<Form.Label>Cuotas</Form.Label>
													<FormatedNumberInput
														type='number'
														onChange={(e) => setDues(e.target.value)}
														min={1}
														noDecimal={true}
													></FormatedNumberInput>
												</Form.Group>
											)}
											<Card className='d-flex flex-row mb-3 align-items-center justify-content-evenly card-form-debts'>
												<Button
													className='mt-3 col-md-2'
													onClick={AmountCalc}
													disabled={disabledInput}
												>
													Calcular
												</Button>
												<Form.Group
													className='mb-3 col-md-2 form-group required'
													controlId='amount'
												>
													<Form.Label>
														Monto{' '}
														{selectedConcept &&
															(selectedConcept.includes('prestamos') ||
																selectedConcept.includes('ventas-a-plazo'))
															? 'cuota'
															: ''}
													</Form.Label>
													<FormatedNumberInput
														type='number'
														value={amount}
														disabled={disabledAmount}
														onChange={(e) => setAmount(e.target.value)}
														min={0}
													></FormatedNumberInput>
												</Form.Group>
											</Card>
										</>
									)}
								</Row>
							</Card>
							<Card className='d-flex  justify-content-between mb-3 card-form-debts'>
								<Row>
									<h2 className='text-center m-3'>Registro fuera del sistema</h2>
								</Row>
								<Row className='d-flex flex-row align-items-center'>
									<OverlayTrigger
										placement='left'
										overlay={
											<Tooltip id='tooltip-previous-debt'>
												Los datos que se completan aqui son únicamente informativos, con el fin de
												registrar la contabilidad fuera del sistema
											</Tooltip>
										}
									>
										<Form.Group
											className=' col-3 form-group d-flex align-items-center'
											controlId='hasPreviousCredits'
											style={{ height: '60px' }}
										>
											<Form.Check
												className='d-flex align-items-center'
												type='checkbox'
												label='Tiene crédito anterior?'
												checked={hasPreviousCredits}
												onChange={(e) => setHasPreviousCredits(e.target.checked)}
											/>
										</Form.Group>
									</OverlayTrigger>
									<Form.Group className='mb-3 col-4 form-group ' controlId='previousCredit'>
										<Form.Label>Crédito Anterior</Form.Label>
										<FormatedNumberInput
											type='number'
											onChange={(e) => setPreviousCredit(e.target.value)}
											disabled={!hasPreviousCredits}
											min={0}
										></FormatedNumberInput>
									</Form.Group>
									<Form.Group className='mb-3 col-4 form-group ' controlId='previousCreditDate'>
										<Form.Label>Fecha crédito anterior</Form.Label>
										<Form.Control
											type='date'
											disabled={!hasPreviousCredits}
											onChange={(e) => setPreviousCreditDate(e.target.value)}
										></Form.Control>
									</Form.Group>
								</Row>
							</Card>
						</div>
						<Row className='modal-btns'>
							<Col>
								<Button className='cancelar' onClick={() => navigate(-1)}>
									Cancelar
								</Button>
							</Col>
							<Col>
								<Button
									className='guardar'
									disabled={!isAddCreditButtonEnabled()}
									onClick={() => addCreditHandler()}
								>
									Agregar
								</Button>
							</Col>
						</Row>
					</Form>
				</div>
			</Modal>
		</div>
	);
}
export default NewCredit;

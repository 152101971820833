import React, { useState } from 'react';
import { Table, DropdownButton, Dropdown, Form, Modal, Row, Col, Button } from 'react-bootstrap';
import { FaSortUp, FaSortDown } from 'react-icons/fa';
import LoadingTableBox from '../components/LoadingTableBox';
import MessageBox from '../components/MessageBox';
import { Link } from 'react-router-dom';
import Paginator from '../screens/Paginator/Paginator';
import moment from 'moment';
import Pagination from './Pagination';
import { downloadExcel } from 'react-export-table-to-excel';
import { FaFileExcel } from 'react-icons/fa';


const DynamicListTable = ({
	dataName, //DataName for customization
	data,//database
	loading, //for loading spinner
	error,//for Error messageBox
	columnInfo, 
	/* 	Example for columnInfo:[
		{ customId:{type: 'string', label: 'ID', link:true }},
		{ 'debtDue.customId':{type: 'string', label: 'Cuota'}},
		{ amount:{type: 'currency', label: 'Monto'}},
		{ date:{type: 'date', label: 'Fecha'}},
		{ folder:{type: 'string', label: 'Carpeta'}},
		{ observations:{type: 'string', label: 'Observaciones', toExport: true }}
	] */
	count, //for paginator if its from queries filter
	onPageChange, //for paginator if its from queries filter
	page, //for paginator if its from queries filter
	pageSize, //for paginator if its from queries filter
	handleSort, //for central lists, ordering function
	direction,
	sortColumn,
	actionButtons, //seeDetails,editHandler,editDateHandler,seeAttachment and deleteHandler
	showCheckboxColumn, //first column with checkboxes
	frontPaginator, //frontPaginator, set to true if is only frontend 
	posts,//set postsPerPage if its front paginator
	exportOption,//export button for xls, with modal, if you want to export some fields but not to show in the screen, set it toExport to True in every field you want
	selectedFieldToExport,//default selectedField to Export, it always be exported to xls, it can be _id or name for example
}) => {

	//data to show, column mappings
	const columnMap = {};
	const columns = [];
	const types = {};
	const links= {};
	const linkPaths ={};

	//data for export
	let columnInfoToShow = columnInfo;
	let columnInfoToExport = {};
	const columnMapExport = {};
	const columnsExport = [];
	const typesExport = {};

	columnInfoToShow.forEach((col) => {
		const key = Object.keys(col)[0];
		if (col[key].toExport) {
			return;
		}
		columnMap[key] = col[key].label;
		columns.push(key);
		types[key] = col[key].type;
		links[key] =col[key].link;
		linkPaths[key]=col[key].linkPath;
	});

	if(exportOption){
		columnInfoToExport = columnInfo;
		columnInfoToExport.forEach((col) => {
			const key = Object.keys(col)[0];
			columnMapExport[key] = col[key].label;
			columnsExport.push(key);
			typesExport[key] = col[key].type;
		});}

	//gotaccount specific Cases	with currency
	const showCurrency = (currency, value) => {
		if (currency === 'UYU') {
			return '$U ' + value;
		} else if (currency === 'USD') {
			return 'U$S ' + value;
		}
	};

	/* 
	function to get the value to show based in the type specification from columnInfo.
	if it is date it will show DD-MM-YYYY format
	if it is boolean it will show YES or Node
	if it is currency it will show the currency of the database with the value
	if is setted to link:true, it will show as a link, also you can set linkPath in columnInfo
	the rest of types will only show the original value */

	function getFormattedValue(item, column) {
		const keys = column.split('.');
		let nestedValue = item;
		let type = (types[column]);
		for (const key of keys) {
			if (
				nestedValue &&
            nestedValue[key] !== undefined &&
            nestedValue[key] !== 0 &&
            nestedValue[key] !== null &&
            nestedValue[key] !== ''
			) {
				nestedValue = nestedValue[key];
			} else {
				return 'N/A';
			}
		}
		if (type === 'boolean') {
			return nestedValue ? 'Si' : 'No';
		} 
		if (type === 'date') {
			const isValidDateFormat = moment.utc(nestedValue, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid();
			return isValidDateFormat ? moment.utc(nestedValue).format('DD-MM-YYYY') : 'N/A';
		}
		if (type === 'currency') {
			// Handle exceptions for currency for gotaccount
			switch (dataName) {
			case 'relWarranties':
				return showCurrency(item.warranty.debt.currency || item.warranty.asset.currency, nestedValue);
			case 'bills':
				return showCurrency(item.asset.currency, nestedValue);
			case 'debtDues':
				return showCurrency(item.debt.currency, nestedValue );
			case 'creditDues':
				return showCurrency(item.credit.currency, nestedValue);
			default:
				return showCurrency(item.currency, nestedValue);
			}
		}  if (links[column]){
		// usar ruta especifica para link
			if (linkPaths[column]) {
				return (
					<Link to={linkPaths[column]}>
						{nestedValue}
					</Link>
				);
			//caso específico gotaccount
			} else if (links[column] && column === 'owner.name') {
				const linkValue = getFormattedValue(item, 'owner._id');
				return <Link to={`/AdminScreen/accounts/account/${linkValue}`}>{nestedValue}</Link>;
			//casos genericos
			} else {
				const linkValue = getFormattedValue(item, '_id');
				return <Link to={`/AdminScreen/${dataName}/${dataName.slice(0, -1)}/${linkValue}`}>{nestedValue}</Link>;
			}
		}
		return nestedValue || 'N/A';

	}

	//the formating for export only includes date, and boolean
	function getFormattedValueExport(item, column) {
		const keys = column.split('.');
		let nestedValue = item;
		let type = (types[column]);
		for (const key of keys) {
			if (
				nestedValue &&
            nestedValue[key] !== undefined &&
            nestedValue[key] !== 0 &&
            nestedValue[key] !== null &&
            nestedValue[key] !== ''
			) {
				nestedValue = nestedValue[key];
			} else {
				return 'N/A';
			}
		}
		if (type === 'boolean') {
			return nestedValue ? 'Si' : 'No';
		} else if (type === 'date') {
			const isValidDateFormat = moment.utc(nestedValue, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid();
			if (isValidDateFormat) {
				return moment.utc(nestedValue).format('DD-MM-YYYY');
			}
			return 'N/A';
		}  else if (!nestedValue) {
			return 'N/A';}
		return nestedValue;
	}
	
	//to export
	const [selectedFields, setSelectedFields] = useState([selectedFieldToExport || 'customId']);//default selectedField
	const [exportName, setExportName] = useState('');
	const todayDate = moment.utc().format('DD-MM-YYYY');
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [show, setShow] = useState(false);

	const handleDownloadExcel = () => {
		const selectedColumnMap = columnMapExport;
		const header = selectedFields.map((field) => selectedColumnMap[field]);
		const body = data.map((item) =>
			selectedFields.map((field) => getFormattedValueExport(item, field, typesExport[field])),
		);
		downloadExcel({
			fileName: exportName ? `${exportName}` : `${todayDate}- ${dataName}`,
			sheet: 'react-export-table-to-excel',
			tablePayload: {
				header,
				body,
			},
		});
		handleClose();
	};

	//show two colums of checkboxes in modal for export
	const columnsPerRow = 2;

	const rows = Array.from(
		{ length: Math.ceil(columnsExport.length / columnsPerRow) },
		(_, rowIndex) =>
			columnsExport.slice(rowIndex * columnsPerRow, (rowIndex + 1) * columnsPerRow),
	);

	//checkboxes column, se usa en el caso de alquileres
	async function toggleAllCheckboxes() {
		let allCheckboxes = document.getElementById('allCheckboxes');
		let checkboxes = document.getElementsByName(`checked${dataName}`);
		for (let i = 0; i < checkboxes.length; i++) {
			allCheckboxes.checked ? (checkboxes[i].checked = true) : (checkboxes[i].checked = false);
		}
	}

	//manage frontend pagination
	const [currentPage, setCurrentPage] = useState(1);
	const [postsPerPage] = useState(posts? posts : 5); //maxPostsperPage
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);

	//change page
	const paginate = (pageNumber) => setCurrentPage(pageNumber);


	return (
		<>
			{exportOption ? (
				<div className='container m-3'>
					<Row>
						<Col className='d-flex justify-content-end'>
							<Button
								size={'sm'}
								className='mb-1 '
								onClick={handleShow}
								style={{ backgroundColor: 'Green' }}
							>
								<FaFileExcel></FaFileExcel> Exportar
							</Button>
						</Col>
					</Row>
					<Modal show={show} onHide={handleClose}>
						<Modal.Header closeButton>
							<Modal.Title>Exportar datos</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form.Group as={Row} className='mb-3 align-items-center'>
								<Form.Label column md={6}>
									Nombre del archivo:
								</Form.Label>
								<Col>
									<Form.Control
										type='text'
										placeholder='Ingrese el nombre'
										value={exportName}
										onChange={(e) => setExportName(e.target.value)}
									/>
								</Col>
							</Form.Group>
							<>
								<p>Marque los campos que desea incluir</p>
								{rows.map((row, rowIndex) => (
									<Row key={rowIndex}>
										{row.map((column) => (
											<Col key={column} md={6}>
												<Form.Group
													as={Row}
													className='mb-3 align-items-center'
													controlId={`fieldsToInclude_${column}`}
												>
													<Form.Check
														className='col-8'
														type='checkbox'
														value={column}
														label={columnMapExport[column]}
														defaultChecked={column === (selectedFieldToExport || 'customId')}//default selected field, can be _id
														disabled={column === (selectedFieldToExport || 'customId')}//default selected field, can be _id
														onChange={(e) => {
															const selected = e.target.checked;
															setSelectedFields((prevSelectedFields) =>
																selected
																	? [...prevSelectedFields, column]
																	: prevSelectedFields.filter((field) => field !== column),
															);
														}}
													/>
												</Form.Group>
											</Col>
										))}
									</Row>
								))}
							</>
						</Modal.Body>
						<Modal.Footer>
							<Button variant='secondary' onClick={handleClose}>
								Cancelar
							</Button>
							<Button variant='primary' onClick={handleDownloadExcel}>
								Exportar XLS
							</Button>
						</Modal.Footer>
					</Modal>
				</div>
			) : (
				''
			)}
			<Table bordered hover responsive size='sm'>
				<thead>
					<tr>
						{showCheckboxColumn ? (
							<th className='last-col'>
								<Form.Check
									type={'checkbox'}
									id='allCheckboxes'
									onClick={() => toggleAllCheckboxes()}
								></Form.Check>
							</th>
						) : (
							''
						)}
						{columns.map((column) => (
							<th key={column} onClick={frontPaginator ? null : handleSort? () => handleSort(column):null}>
								{columnMap[column]}
								{column === sortColumn && (
									<span>{direction === 'desc' ? <FaSortUp /> : <FaSortDown />}</span>
								)}
							</th>
						))}
						{actionButtons && <th className='last-col text-center'></th>}
					</tr>
				</thead>
				<tbody>
					{loading ? (
						<tr>
							<td colSpan={5}>
								<LoadingTableBox loading={loading} />
							</td>
						</tr>
					) : error ? (
						<tr colSpan={5}>
							<td colSpan={5}>
								<MessageBox variant='danger'>{error}</MessageBox>
							</td>
						</tr>
					) : data.length === 0 ? (
						<tr colSpan={10}>
							<td colSpan={10}>
								{/* <MessageBox variant="light">No hay resultados, si desea crear haga click <Link className="alert-link" to="/AdminScreen/newAccount">aquí</Link></MessageBox> */}
							</td>
						</tr>
					) : frontPaginator ? (
						currentPosts.map((item) => (
							<tr key={item._id}>
								{showCheckboxColumn && (
									<td>
										<Form.Check
											type={'checkbox'}
											name={`checked${dataName}`}
											value={item._id}
											id={`checked${dataName}`}
										/>
									</td>
								)}
								{columns.map((column) => (
									<td key={column}>
										{getFormattedValue(item, column)}
									</td>
								))}
								{actionButtons && (
									<td>
										<DropdownButton drop='start' title=''>
											{actionButtons.seeDetails && (
												<Dropdown.Item eventKey='1' onClick={() => actionButtons.seeDetails(item)}>
													Ver Detalles
												</Dropdown.Item>
											)}
											{actionButtons.editHandler && (
												<Dropdown.Item eventKey='2' onClick={() => actionButtons.editHandler(item)}>
													Editar
												</Dropdown.Item>
											)}
											{actionButtons.editDateHandler && (
												<Dropdown.Item eventKey='2' onClick={() => actionButtons.editDateHandler(item)}>
													Editar Fecha de Inicio
												</Dropdown.Item>
											)}
											{(actionButtons.seeAttachment && item.escritura) ||
												(item.comprobante && (
													<Dropdown.Item
														eventKey='3'
														onClick={() => actionButtons.seeAttachment(item)}
													>
														Ver Documento
													</Dropdown.Item>
												))}
											{actionButtons.deleteHandler && (
												<Dropdown.Item
													eventKey='3'
													onClick={() => actionButtons.deleteHandler(item)}
												>
													Eliminar
												</Dropdown.Item>
											)}
										</DropdownButton>
									</td>
								)}
							</tr>
						))
					) : data && !frontPaginator ? (
						data.map((item) => (
							<tr key={item._id}>
								{showCheckboxColumn && (
									<td>
										<Form.Check
											type={'checkbox'}
											name={`checked${dataName}`}
											value={item._id}
											id={`checked${dataName}`}
										/>
									</td>
								)}
								{columns.map((column) => (
									<td key={column}>
										{getFormattedValue(item, column)}
									</td>
								))}
								{actionButtons && (
									<td>
										<DropdownButton drop='start' title=''>
											{actionButtons.seeDetails && (
												<Dropdown.Item eventKey='1' onClick={() => actionButtons.seeDetails(item)}>
													Ver Detalles
												</Dropdown.Item>
											)}
											{actionButtons.editHandler && (
												<Dropdown.Item eventKey='2' onClick={() => actionButtons.editHandler(item)}>
													Editar
												</Dropdown.Item>
											)}
											{actionButtons.editDateHandler && (
												<Dropdown.Item eventKey='2' onClick={() => actionButtons.editDateHandler(item)}>
													Editar Fecha de Inicio
												</Dropdown.Item>
											)}
											{(actionButtons.seeAttachment && item.escritura) ||
												(item.comprobante && (
													<Dropdown.Item
														eventKey='3'
														onClick={() => actionButtons.seeAttachment(item)}
													>
														Ver Documento
													</Dropdown.Item>
												))}
											{actionButtons.deleteHandler && (
												<Dropdown.Item
													eventKey='3'
													onClick={() => actionButtons.deleteHandler(item)}
												>
													Eliminar
												</Dropdown.Item>
											)}
										</DropdownButton>
									</td>
								)}
							</tr>
						))
					) : (
						''
					)}
				</tbody>
			</Table>
			{frontPaginator ? (
				<Pagination
					postsPerPage={postsPerPage}
					totalPosts={data.length}
					paginate={paginate}
				></Pagination>
			) : (
				<Paginator
					className='pagination-bar'
					totalCount={count}
					onPageChange={onPageChange}
					currentPage={parseInt(page)}
					pageSize={pageSize}
				></Paginator>
			)}
		</>
	);
};

export default DynamicListTable;
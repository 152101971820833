import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Button, Col, Modal, Row, OverlayTrigger, Popover} from 'react-bootstrap';
import { MdInfoOutline } from 'react-icons/md';
import Form from 'react-bootstrap/Form';
import { Helmet } from 'react-helmet-async';
import { MdOutlineEdit } from 'react-icons/md';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import { getError } from '../../utils';
import moment from 'moment';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useNavigate, useParams } from 'react-router-dom';
import CustomTypeahead from '../../components/CustomTypeahead';
import FormatedNumberInput from '../../components/FormatedNumberInput';
import GenericDropdown from '../../components/SelectDropdown';
import accountService from '../../services/account.service';
import assetService from '../../services/asset.service';
import contractService from '../../services/contract.service';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_ACCOUNTS_SUCCESS':
		return { ...state, accounts: action.payload};
	case 'FETCH_ASSETS_SUCCESS':
		return { ...state, assets: action.payload};
	case 'FETCH_CONTRACT_SUCCESS':
		return { ...state, contract: action.payload};
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };

	default:
		return state;
	}
};

function EditContract() {
	const params = useParams();
	const navigate = useNavigate();

	const { id } = params;
	const [{ loading, contract,accounts, assets }, dispatch] =
		useReducer(reducer, {
			accounts: [],
			assets: [],
			contract: {},
			loading: true,
		});
	const { dispatch: ctxDispatch } = useContext(Store);
	const currencies = ['USD', 'UYU'];
	const [periodInit, setPeriodInit] = useState();
	const [periodEnd, setPeriodEnd] = useState();
	const [amount, setAmount] = useState();
	const [owner, setOwner] = useState();
	const [field, setField] = useState('');
	const [rental, setRental] = useState(true);
	const [property, setProperty] = useState();
	const [ubication, setUbication] = useState('');
	const [folder, setFolder] = useState();
	const [observations, setObservations] = useState();
	const [paymentMethod, setPaymentMethod] = useState();
	const [currency, setCurrency] = useState();
	const [hasPreviousDebts, setHasPreviousDebts] = useState(false);
	const [previousDebt, setPreviousDebt] = useState();
	const [previousDebtDate, setPreviousDebtDate] = useState();

	const fetchContract = async () => {
		const result = await contractService.getDetails(id);
		dispatch({ type: 'FETCH_CONTRACT_SUCCESS', payload: result.data });
		setAmount(result.data.amount);
		setPeriodInit(result.data.periodInit);
		setPeriodEnd(result.data.periodEnd);
		setOwner(result.data.owner? [result.data.owner]:'');
		setField(result.data.field);
		setRental(result.data.rental);
		setProperty(result.data.property? [result.data.property]:'');
		setUbication(result.data.ubication);
		setFolder(result.data.folder);
		setObservations(result.data.observations); 
		setPaymentMethod(result.data.paymentMethod);
		setCurrency(result.data.currency);
		setHasPreviousDebts(result.data.hasPreviousDebts);
		setPreviousDebt(result.data.previousDebt);
		setPreviousDebtDate(result.data.previousDebtDate);
	};
	const fetchAccounts = async () => {
		const result = await accountService.list();
		dispatch({ type: 'FETCH_ACCOUNTS_SUCCESS', payload: result.data });
	};

	const fetchAssets = async () => {
		const { data } = await assetService.list();
		dispatch({ type: 'FETCH_ASSETS_SUCCESS', payload: data });
	};

	async function fetchData() {
		dispatch({ type: 'FETCH_REQUEST' });
		try {
			await Promise.all([fetchAccounts(), fetchAssets(), fetchContract()]);
			dispatch({ type: 'FETCH_SUCCESS' });
		} catch (ex) {
			console.error(ex);
			dispatch({ type: 'FETCH_FAIL', payload: '' });
		}
	}
	useEffect(() => {
		fetchData();
	}, [id]);

	const editPaymentHandler = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });

			const requestData = {
				amount,
				periodInit,
				periodEnd,
				owner: owner[0] || owner,
				field,
				rental,
				ubication,
				property: property[0] || property,
				folder,
				observations,
				paymentMethod,
				currency,
			};
			if (hasPreviousDebts) {
				requestData.hasPreviousDebts = hasPreviousDebts;
				requestData.previousDebt = previousDebt;
				requestData.previousDebtDate = previousDebtDate;
			}
			//check if is dont 'hasPreviousDebts' to unset other fields
			if (!hasPreviousDebts) {
				requestData.hasPreviousDebts = false;
				requestData.previousDebt = '';
				requestData.previousDebtDate = '';
			}
			const { data } = await contractService.edit(contract._id, requestData);
			// deletePictureHandler(image);
			dispatch({
				type: 'UPDATE_SUCCESS',
			});
			ctxDispatch({ type: 'CONTACT_UPDATE_SUCCESS', payload: data });
			toast.success('Contrato actualizado Correctamente');
			navigate(-1);
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL' });
			toast.error(getError(err));
		}
	};
	const [isInvalid, setIsInvalid] = useState(false);
	const [isPropertyInvalid, setIsPropertyInvalid] = useState(false);

	const handleAccountChange = (selected) => {
		const selectedAccount = selected[0];
		const isValid = selectedAccount ? accounts.includes(selectedAccount) : false;
		setIsInvalid(!isValid);
		setOwner(selected);
	};

	const handlePropertyChange = (selected) => {
		const selectedProperty = selected[0];
		const isPropertyInvalid = selectedProperty ? assets.includes(selectedProperty) : false;
		setIsPropertyInvalid(!isPropertyInvalid);
		setProperty(selected);
	};

	return (
		<div>
			<Modal size='lg' show={true} animation={false}>
				{(loading) && <LoadingBox></LoadingBox>}
				<div className='container admin-con align-items-center'>
					<Helmet>
						<title>Editar Alquiler</title>
					</Helmet>
					<h1>
						<MdOutlineEdit></MdOutlineEdit>Editar Alquiler
					</h1>
					<Form className='admin-form' onSubmit={(e) => e.preventDefault()}>
						<div className='row align-items-center'>
							<Row>
								<Form.Group
									className='mb-3 col-4 form-group d-flex align-items-center'
									controlId='hasPreviousDebts'
									style={{ height: '60px' }}
								>
									<Form.Check
										className='d-flex align-items-center'
										type='checkbox'
										label='Tiene saldo anterior?'
										checked={hasPreviousDebts}
										onChange={(e) => setHasPreviousDebts(e.target.checked)}
									/>
								</Form.Group>
								{hasPreviousDebts && (
									<>
										<Form.Group className='mb-3 col-4 form-group ' controlId='previousDebt'>
											<Form.Label>saldo Anterior</Form.Label>
											<FormatedNumberInput
												type='number'
												defaultValue={previousDebt}
												onChange={(e) => setPreviousDebt(e.target.value)}
												min={0}
											></FormatedNumberInput>
										</Form.Group>
										<Form.Group className='mb-3 col-4 form-group ' controlId='previousDebtDate'>
											<Form.Label>Fecha saldo anterior</Form.Label>
											<Form.Control
												type='date'
												value={
													previousDebtDate
														? moment(previousDebtDate).utc().format('YYYY-MM-DD')
														: null
												}
												onChange={(e) => setPreviousDebtDate(e.target.value)}
											></Form.Control>
										</Form.Group>
									</>
								)}
							</Row>
							<Form.Group className='mb-3 col-6 form-group required '>
								<Form.Label>Propiedad</Form.Label>
								{contract.property && contract.property != null && contract.property != '' ? (
									<CustomTypeahead
										id='assets'
										labelKey={(option) => `${option.name}`}
										onChange={handlePropertyChange}
										options={assets}
										selected={property ? property : null}
										placeholder='Buscar una propiedad...'
										linkToCreate='/AdminScreen/newAsset'
										newOption='nueva propiedad'
									/>
								) : (
									<CustomTypeahead
										id='assets'
										labelKey={(option) => `${option.name}`}
										onChange={handlePropertyChange}
										options={assets}
										placeholder='Buscar una propiedad...'
										linkToCreate='/AdminScreen/newAsset'
										newOption='nueva propiedad'
									/>
								)}
								{isPropertyInvalid && <div style={{ color: 'red' }}>Propiedad invalida</div>}
							</Form.Group>
							<Form.Group className='mb-3  col-6 form-group required'>
								<Form.Label>Inquilino</Form.Label>
								{owner && contract.owner != null && contract.owner != '' ? (
									<CustomTypeahead
										id='accounts'
										labelKey={(option) => `${option.name}`}
										onChange={handleAccountChange}
										options={accounts}
										placeholder='Buscar Inquilino... '
										isInvalid={isInvalid}
										selected={owner ? owner : null}
										linkToCreate='/AdminScreen/newAccount'
										newOption='nueva cuenta de inquilino'
									/>
								) : (
									<CustomTypeahead
										id='accounts'
										labelKey={(option) => `${option.name}`}
										onChange={handleAccountChange}
										options={accounts}
										placeholder='Buscar Inquilino... '
										isInvalid={isInvalid}
										linkToCreate='/AdminScreen/newAccount'
										newOption='nueva cuenta de inquilino'
									/>
								)}
								{isInvalid && <div style={{ color: 'red' }}>Cuenta invalida</div>}
							</Form.Group>
							<Form.Group className='mb-3 col-3 form-group required' controlId='date'>
								<Form.Label>Inicio</Form.Label>
								<Form.Control
									type='date'
									value={moment.utc(periodInit).format('YYYY-MM-DD')}
									onChange={(e) => setPeriodInit(e.target.value)}
								></Form.Control>
							</Form.Group>
							<Form.Group className='mb-3 col-3 form-group' controlId='date'>
								{!periodEnd?(
									<OverlayTrigger
										trigger='click'
										right='left'
										overlay={
											<Popover id='tooltip-previous-debt' style={{ minWidth: '50%' }}>
												<Popover.Header as='h3'> A tener en cuenta:</Popover.Header>
												<Popover.Body>
										El contrato se creo sin fecha de finalizacion, utilice la opcion -finalizar contrato- para indicar la fecha de fin
												</Popover.Body>
											</Popover>
										}
									>
										<span style={{ cursor: 'pointer' }}>
											<MdInfoOutline />
										</span>
									</OverlayTrigger>):('')}
								<Form.Label>Fin</Form.Label>
								<Form.Control
									type='date'
									value={periodEnd ? moment.utc(periodEnd).format('YYYY-MM-DD') : ''}
									onChange={(e) => setPeriodEnd(e.target.value)}
									min={moment.utc(periodEnd).format('YYYY-MM-DD')}
									disabled={!periodEnd}
								></Form.Control>
							</Form.Group>
							<Form.Group className='mb-3 col-3 form-group required' controlId='amount'>
								<Form.Label>Monto</Form.Label>
								<FormatedNumberInput
									type='number'
									value={amount}
									disabled
									onChange={(e) => setAmount(e.target.value)}
								></FormatedNumberInput>
							</Form.Group>
							<Form.Group className='mb-3 col-3 ' controlId='currency'>
								<GenericDropdown
									label='Moneda'
									items={currencies}
									selected={currency}
									onChange={(e) => setCurrency(e.value)}
								/>
							</Form.Group>
							<Form.Group style={{ marginBottom: '5%' }} className='mb-3 col-12' controlId='field'>
								<Form.Label>Detalles</Form.Label>
								<Form.Control
									type='text'
									as='textarea'
									rows={10}
									defaultValue={field}
									onChange={(e) => setField(e.target.value)}
								></Form.Control>
							</Form.Group>
							<Form.Group className='mb-3' controlId=''>
								<Form.Label>Carpeta</Form.Label>
								<Form.Control
									type='text'
									defaultValue={folder ? folder : null}
									onChange={(e) => setFolder(e.target.value)}
								></Form.Control>
							</Form.Group>
							<Form.Group className='mb-3' controlId=''>
								<Form.Label>Forma de cobro</Form.Label>
								<Form.Control
									type='text'
									defaultValue={paymentMethod}
									onChange={(e) => setPaymentMethod(e.target.value)}
								></Form.Control>
							</Form.Group>
							<Form.Group style={{ marginBottom: '5%' }} className='col-12' controlId='field'>
								<Form.Label>Observaciones</Form.Label>
								<Form.Control
									type='text'
									as='textarea'
									rows={10}
									defaultValue={observations}
									onChange={(e) => setObservations(e.target.value)}
								></Form.Control>
							</Form.Group>
						</div>
						<Row className='modal-btns'>
							<Col>
								<Button className='cancelar' onClick={() => navigate(-1)}>
									Cancelar
								</Button>
							</Col>
							<Col>
								<Button className='guardar' onClick={editPaymentHandler}>
									Actualizar
								</Button>
							</Col>
						</Row>
					</Form>
				</div>
			</Modal>
		</div>
	);
}
export default EditContract;

import React, { useEffect, useReducer, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { MdAddchart } from 'react-icons/md';
import GenericDropdown from '../components/SelectDropdown';
import FilterForm from '../components/FilterForm';
import { getError } from '../utils';
import DynamicTable from '../components/DynamicTable';
import GroupedTable from '../components/GroupedTable';
import Paginator from './Paginator/Paginator';
import ContractService from '../services/contract.service';
import DebtService from '../services/debt.service';
import CreditService from '../services/credit.service';
import GeneratedDebtService from '../services/generatedDebts.service';
import GeneratedCreditService from '../services/generatedCredits.service';
import AccountService from '../services/account.service';
import AssetService from '../services/asset.service';
import AssetSaleService from '../services/assetSale.service';
import PaymentService from '../services/payment.service';

import moment from 'moment';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_CONTRACTS_REQUEST':
		return { ...state, loadingContracts: true };
	case 'FETCH_CONTRACTS_SUCCESS':
		return {
			...state,
			contracts: action.payload.contracts,
			allContracts: action.payload.allContracts,
			page: action.payload.page,
			pages: action.payload.pages,
			countContracts: action.payload.countContracts,
			pageSize: action.payload.pageSize,
			loadingContracts: false,
		};
	case 'FETCH_DEBTS_REQUEST':
		return { ...state, loadingDebts: true };
	case 'FETCH_DEBTS_SUCCESS':
		return {
			...state,
			debts: action.payload.debts,
			allDebts: action.payload.allDebts,
			page: action.payload.page,
			pages: action.payload.pages,
			countDebts: action.payload.countDebts,
			pageSize: action.payload.pageSize,
			loadingDebts: false,
		};
	case 'FETCH_CREDITS_REQUEST':
		return { ...state, loadingCredits: true };
	case 'FETCH_CREDITS_SUCCESS':
		return {
			...state,
			credits: action.payload.credits,
			allCredits: action.payload.allCredits,
			page: action.payload.page,
			pages: action.payload.pages,
			countCredits: action.payload.countCredits,
			pageSize: action.payload.pageSize,
			loadingCredits: false,
		};
	case 'FETCH_PAYMENTS_REQUEST':
		return { ...state, loadingPayments: true };
	case 'FETCH_PAYMENTS_SUCCESS':
		return {
			...state,
			payments: action.payload.payments,
			allPayments: action.payload.allPayments,
			page: action.payload.page,
			pages: action.payload.pages,
			countPayments: action.payload.countPayments,
			pageSize: action.payload.pageSize,
			loadingPayments: false,
		};
	case 'FETCH_DEBTSDUES_REQUEST':
		return { ...state, loadingDebtsDues: true };
	case 'FETCH_DEBTSDUES_SUCCESS':
		return {
			...state,
			debtsDues: action.payload.debtsDues,
			allDebtsDues: action.payload.allDebtsDues,
			page: action.payload.page,
			pages: action.payload.pages,
			countDebtsDues: action.payload.countDebtsDues,
			pageSize: action.payload.pageSize,
			loadingDebtsDues: false,
		};
	case 'FETCH_CREDITSDUES_REQUEST':
		return { ...state, loadingCreditsDues: true };
	case 'FETCH_CREDITSDUES_SUCCESS':
		return {
			...state,
			creditsDues: action.payload.creditsDues,
			allCreditsDues: action.payload.allCreditsDues,
			page: action.payload.page,
			pages: action.payload.pages,
			countCreditsDues: action.payload.countCreditsDues,
			pageSize: action.payload.pageSize,
			loadingCreditsDues: false,
		};
	case 'FETCH_ACCOUNTS_REQUEST':
		return { ...state, loadingAccounts: true };
	case 'FETCH_ACCOUNTS_SUCCESS':
		return {
			...state,
			accounts: action.payload.accounts,
			allAccounts: action.payload.allAccounts,
			accountsIds: action.payload.accountsIds,
			page: action.payload.page,
			pages: action.payload.pages,
			countAccounts: action.payload.countAccounts,
			pageSize: action.payload.pageSize,
			loadingAccounts: false,
		};
	case 'FETCH_ACCOUNTSIDS_REQUEST':
		return { ...state, loadingAccounts: true };
	case 'FETCH_ACCOUNTSIDS_SUCCESS':
		return {
			...state,
			accountsIds: action.payload.accountsIds,
			loadingAccounts: false,
		};
	case 'FETCH_ASSETS_REQUEST':
		return { ...state, loadingAssets: true };
	case 'FETCH_ASSETS_SUCCESS':
		return {
			...state,
			assets: action.payload.assets,
			allAssets: action.payload.allAssets,
			page: action.payload.page,
			pages: action.payload.pages,
			countAssets: action.payload.countAssets,
			pageSize: action.payload.pageSize,
			loadingAssets: false,
		};
	case 'FETCH_ASSETSALES_REQUEST':
		return { ...state, loadingAssetSales: true };
	case 'FETCH_ASSETSALES_SUCCESS':
		return {
			...state,
			assetSales: action.payload.assetSales,
			allAssetSales: action.payload.allAssetSales,
			page: action.payload.page,
			pages: action.payload.pages,
			countAssets: action.payload.countAssetSales,
			pageSize: action.payload.pageSize,
			loadingAssetSales: false,
		};
	case 'FETCH_FAIL':
		return {
			...state,
			loadingDebts: false,
			loadingContracts: false,
			loadingDebtsDues: false,
			loadingCreditsDues: false,
			loadingAccounts: false,
			error: action.payload,
		};
	default:
		return state;
	}
};

function Reports() {
	const [
		{
			error,
			loadingContracts,
			loadingDebts,
			loadingCredits,
			loadingAccounts,
			loadingAssetSales,
			loadingPayments,
			payments,
			allPayments,
			assetSales,
			allAssetSales,
			assets,
			allAssets,
			loadingAssets,
			accounts,
			allAccounts,
			countAccounts,
			debts,
			allDebts,
			credits,
			allCredits,
			countCredits,
			debtsDues,
			allDebtsDues,
			loadingDebtsDues,
			creditsDues,
			allCreditsDues,
			loadingCreditsDues,
			contracts,
			allContracts,
			pageSize,
			countContracts,
			countDebts,
			countDebtsDues,
			countCreditsDues,
			countAssets,
			countAssetSales,
			countPayments,

		},
		dispatch,
	] = useReducer(reducer, {
		payments:[],
		allPayments:[],
		contracts: [],
		allContracts: [],
		debts: [],
		allDebts: [],
		credits: [],
		allCredits: [],
		debtsDues: [],
		allDebtsDues: [],
		creditsDues: [],
		allCreditsDues: [],
		accounts: [],
		allAccounts: [],
		assets:[],
		allAssets:[],
		assetSales:[],
		allAssetSales:[],
		loadingCreditsDues: false,
		loadingContracts: false,
		loadingDebts: false,
		loadingCredits: false,
		loadingDebtsDues: false,
		loadingAccounts: false,
		loadingAssets: false,
		loadingAssetSales:false,
		loadingPayments:false,
		error: '',
		pageSize: 0,
		countContracts: 0,
		countDebts: 0,
		countDebtsDues: 0,
		countAccounts: 0,
		countAssets: 0,
		countAssetSales:0,
		countPayments:0,
	});

	const { search } = useLocation();

	const reports = [
		'Deudores',
		'Pasividades',
		'Activos Fijos',
		'Ventas de Activo',
		'Alquileres',
		'Cobros',
		'Cuotas de deudas a cobrar mes actual',
		'Todas las cuotas de deudas',
		'Cuotas de pasividades a pagar mes actual',
		'Todas las cuotas de pasividades',
		'Estado Situación',
	];
	const [selectedReport, setSelectedReport] = useState();
	const [filterOptions, setFilterOptions] = useState({});
	const navigate = useNavigate();

	const sp = new URLSearchParams(search);
	let page = sp.get('page') || 1;
	const concept = sp.get('concept') || 'all';
	const priceFrom = sp.get('priceFrom') || 'all';
	const priceTo = sp.get('priceTo') || 'all';
	const name = sp.get('name') || '';
	const ubication = sp.get('ubication') || '';
	const owner = sp.get('owner') || '';
	const purchaser = sp.get('purchaser') || '';
	const sold = sp.get('sold') || '';
	const lot = sp.get('lot') || '';
	const currency = sp.get('currency') || 'all';
	const capitalTo = sp.get('capitalTo') || '';
	const capitalFrom = sp.get('capitalFrom') || '';
	const amountTo = sp.get('amountTo') || '';
	const amountFrom = sp.get('amountFrom') || 0;
	const dateFrom = sp.get('dateFrom') || '';
	const dateTo = sp.get('dateTo') || '';
	const endDateFrom = sp.get('endDateFrom') || '';
	const endDateTo = sp.get('endDateTo') || '';
	const query = sp.get('query') || 'all';
	const periodInitFrom = sp.get('periodInitFrom') || '';
	const periodInitTo = sp.get('periodInitTo') || '';
	const periodEndFrom = sp.get('periodEndFrom') || '';
	const periodEndTo = sp.get('periodEndTo') || '';
	const queryString = search.substring(1);
	const accountId = sp.get('accountId') || '';
	const lastPaymentFrom =sp.get('lastPaymentFrom') || '';
	const lastPaymentTo =sp.get('lastPaymentTo') || '';
	const [accountsList, setAccountsList] = useState([]);

	const currentDate = moment.utc();
	const firstDayOfMonth = moment.utc(currentDate).startOf('month').format('YYYY-MM-DD');
	const lastDayOfMonth = moment.utc(currentDate).endOf('month').format('YYYY-MM-DD');

	const [filtered, setFiltered] = useState(false);
	const [totalGeneratedDebts, setTotalGeneratedDebts] = useState();

	const onPageChange = (page) => {
		navigate(getFilterUrl({ page: page }));
	};

	function handleInputReportChange(e) {
		setSelectedReport(e.value);
		if (e.value === 'Deudores') {
			setFilterOptions({
				formType: 'Deudas',
				concept: concept,
				dateFrom: dateFrom,
				dateTo: dateTo,
				endDateFrom: endDateFrom,
				endDateTo: endDateTo,
				amountFrom: amountFrom,
				amountTo: amountTo,
				capitalFrom: capitalFrom,
				capitalTo: capitalTo,
				currency: currency,
				query: query,
				page: page,
			});
		}
		if (e.value === 'Pasividades') {
			setFilterOptions({
				formType: 'Pasividades',
				concept: concept,
				dateFrom: dateFrom,
				dateTo: dateTo,
				endDateFrom: endDateFrom,
				endDateTo: endDateTo,
				amountFrom: amountFrom,
				amountTo: amountTo,
				capitalFrom: capitalFrom,
				capitalTo: capitalTo,
				currency: currency,
				query: query,
				page: page,
			});
		}
		if (e.value === 'Alquileres') {
			setFilterOptions({
				formType: 'Alquileres',
				periodInitFrom: periodInitFrom,
				periodInitTo: periodInitTo,
				periodEndFrom: periodEndFrom,
				periodEndTo: periodEndTo,
				amountFrom: amountFrom,
				amountTo: amountTo,
				currency: currency,
				query: query,
				page: page,
			});
		}
		if (e.value === 'Ventas de Activo') {
			setFilterOptions({
				formType: 'VentasActivo',
				dateFrom: dateFrom,
				dateTo: dateTo,
				currency: currency,
				lot: lot,
				purchaser: purchaser,
				query: query,
				page: page,
			});
		}
		if (e.value === 'Cobros') {
			setFilterOptions({
				formType: 'Cobros',
				concept: concept,
				dateFrom: dateFrom,
				dateTo: dateTo,
				currency: currency,
				page: page,
			});
		}
		if (
			e.value === 'Todas las cuotas de deudas' ||
			e.value === 'Cuotas de deudas a cobrar mes actual'
		) {
			setFilterOptions({
				formType: 'CuotasDeudas',
				concept: concept,
				dateFrom: dateFrom,
				dateTo: dateTo,
				currency: currency,
				query: query,
				lastPaymentFrom:lastPaymentFrom,
				lastPaymentTo:lastPaymentTo,
				page: page,
			});
		}
		if (
			e.value === 'Todas las cuotas de pasividades' ||
			e.value === 'Cuotas de pasividades a pagar mes actual'
		) {
			setFilterOptions({
				formType: 'CuotasCreditos',
				concept: concept,
				dateFrom: dateFrom,
				dateTo: dateTo,
				currency: currency,
				query: query,
				page: page,
			});
		}
		if (e.value === 'Estado Situación') {
			setFilterOptions({
				formType: 'EstadoCuenta',
				accountId: accountId,
				concept: concept,
				dateFrom: dateFrom,
				dateTo: dateTo,
				currency: currency,
				query: query,
				page: page,
			});
		}
		if (e.value === 'Activos Fijos') {
			setFilterOptions({
				formType: 'ActivosFijos',
				owner:owner,
				priceFrom: priceFrom,
				priceTo: priceTo,
				currency: currency,
				sold:sold,
				name:name,
				ubication:ubication,
				lot:lot,
				page: page,
			});
		}
	}

	const handleClearFilter = () => {
		if (selectedReport === 'Alquileres') {
			setFilterOptions({
				formType: 'Alquileres',
				periodInitFrom: '',
				periodInitTo: '',
				periodEndFrom: '',
				periodEndTo: '',
				amountFrom: 0,
				amountTo: '',
				currency: 'all',
				query: 'all',
			});
			setFiltered(false);
		}
		if (selectedReport === 'Deudores') {
			setFilterOptions({
				formType: 'Deudas',
				concept: 'all',
				dateFrom: '',
				dateTo: '',
				endDateFrom: '',
				endDateTo: '',
				amountFrom: 0,
				amountTo: '',
				capitalFrom: '',
				capitalTo: '',
				currency: 'all',
				query: 'all',
			});
			setFiltered(false);
		}
		if (selectedReport === 'Pasividades') {
			setFilterOptions({
				formType: 'Pasividades',
				concept: 'all',
				dateFrom: '',
				dateTo: '',
				endDateFrom: '',
				endDateTo: '',
				amountFrom: 0,
				amountTo: '',
				capitalFrom: '',
				capitalTo: '',
				currency: 'all',
				query: 'all',
			});
			setFiltered(false);
		}
		if (selectedReport === 'Cobros') {
			setFilterOptions({
				formType: 'Cobros',
				concept: 'all',
				dateFrom: '',
				dateTo: '',
				currency: 'all',
				page: page,
			});
			setFiltered(false);
		}
		if (
			selectedReport === 'Todas las cuotas de deudas' ||
			selectedReport === 'Cuotas de deudas a cobrar mes actual'
		) {
			setFilterOptions({
				formType: 'CuotasDeudas',
				concept: 'all',
				dateFrom: '',
				dateTo: '',
				currency: 'all',
				lastPaymentFrom:'',
				lastPaymentTo:'',
				query: 'all',
			});
			setFiltered(false);
		}
		if (
			selectedReport === 'Todas las cuotas de pasividades' ||
			selectedReport === 'Cuotas de pasividades a pagar mes actual'
		) {
			setFilterOptions({
				formType: 'CuotasCreditos',
				concept: 'all',
				dateFrom: '',
				dateTo: '',
				currency: 'all',
				query: 'all',
			});
			setFiltered(false);
		}
		if (selectedReport === 'Estado Situación') {
			setFilterOptions({
				formType: 'EstadoCuenta',
				accountId: 'all',
				concept: 'all',
				dateFrom: '',
				dateTo: '',
				currency: 'all',
				query: 'all',
			});
			setFiltered(false);
		}
		if (selectedReport === 'Activos Fijos') {
			setFilterOptions({
				formType: 'ActivosFijos',
				currency: 'all',
				priceFrom: 0,
				priceTo: '',
				name: '',
				owner:'',
				sold:'',
				ubication:'',
				lot:'',
			});
			setFiltered(false);
		}
		if (selectedReport === 'Ventas de Activo') {
			setFilterOptions({
				formType: 'VentasActivo',
				dateFrom: '',
				dateTo: '',
				currency: 'all',
				lot: '',
				purchaser: 'all',
				query: 'all',
			});
			setFiltered(false);
		}
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFilterOptions((prevOptions) => ({
			...prevOptions,
			[name]: value,
			formType: filterOptions.formType,
		}));
	};

	const handleApplyFilter = () => {
		const filterUrl = getFilterUrl(filterOptions);
		navigate(filterUrl);
		setFiltered(true);
	};

	const getFilterUrl = (filter) => {
		const {
			accountId,
			purchaser,
			concept,
			query,
			periodInitFrom,
			periodInitTo,
			periodEndFrom,
			periodEndTo,
			dateFrom,
			dateTo,
			lastPaymentFrom,
			lastPaymentTo,
			endDateFrom,
			endDateTo,
			amountFrom,
			amountTo,
			capitalFrom,
			capitalTo,
			currency,
			paid,
			priceFrom,
			priceTo,
			name,
			lot,
			ubication,
			owner,
			sold,

		} = filterOptions;
		const filterId = accountId || accountId;
		const filterConcept = concept || concept;
		const filterQuery = filter.query || query;
		const filterPage = filter.page || 1;
		const filterDateFrom = dateFrom || '';
		const filterDateTo = dateTo || '';
		const filterEndDateFrom = endDateFrom || '';
		const filterEndDateTo = endDateTo || '';
		const filterAmountFrom = amountFrom || '';
		const filterAmountTo = amountTo || '';
		const filterCapitalFrom = capitalFrom || '';
		const filterCapitalTo = capitalTo || '';
		const filterCurrency = currency || '';
		const filterPaid = paid || '';
		const filterPeriodInitFrom = periodInitFrom || '';
		const filterPeriodInitTo = periodInitTo || '';
		const filterPeriodEndFrom = periodEndFrom || '';
		const filterPeriodEndTo = periodEndTo || '';
		const filterPriceTo = priceTo || '';
		const filterPriceFrom = priceFrom || '';
		const filterName = name || '';
		const filterPurchaser = purchaser || '';
		const filterLot = lot || '';
		const filterUbication = ubication || '';
		const filterOwner = owner || '';
		const filterSold = sold || '';
		const filterLastPaymentFrom = lastPaymentFrom || '';
		const filterLastPaymentTo = lastPaymentTo || '';



		const params = new URLSearchParams();

		if (filterOptions.formType === 'Deudas') {
			params.append('page', filterPage);
			params.append('concept', filterConcept);
			params.append('query', filterQuery);
			params.append('dateFrom', filterDateFrom);
			params.append('dateTo', filterDateTo);
			params.append('endDateFrom', filterEndDateFrom);
			params.append('endDateTo', filterEndDateTo);
			params.append('amountFrom', filterAmountFrom);
			params.append('amountTo', filterAmountTo);
			params.append('capitalFrom', filterCapitalFrom);
			params.append('capitalTo', filterCapitalTo);
			params.append('currency', filterCurrency);

			return `/AdminScreen/reports/debts/search?${params.toString()}`;
		}

		if (filterOptions.formType === 'Pasividades') {
			params.append('page', filterPage);
			params.append('concept', filterConcept);
			params.append('query', filterQuery);
			params.append('dateFrom', filterDateFrom);
			params.append('dateTo', filterDateTo);
			params.append('endDateFrom', filterEndDateFrom);
			params.append('endDateTo', filterEndDateTo);
			params.append('amountFrom', filterAmountFrom);
			params.append('amountTo', filterAmountTo);
			params.append('capitalFrom', filterCapitalFrom);
			params.append('capitalTo', filterCapitalTo);
			params.append('currency', filterCurrency);

			return `/AdminScreen/reports/credits/search?${params.toString()}`;
		}

		if (filterOptions.formType === 'Alquileres') {
			params.append('amountFrom', filterAmountFrom);
			params.append('amountTo', filterAmountTo);
			params.append('periodInitFrom', filterPeriodInitFrom);
			params.append('periodInitTo', filterPeriodInitTo);
			params.append('periodEndFrom', filterPeriodEndFrom);
			params.append('periodEndTo', filterPeriodEndTo);
			params.append('currency', filterCurrency);
			params.append('query', filterQuery);
			params.append('page', filterPage);

			return `/AdminScreen/reports/contracts/search?${params.toString()}`;
		}
		if (filterOptions.formType === 'ActivosFijos') {
			params.append('priceFrom', filterPriceFrom);
			params.append('priceTo', filterPriceTo);
			params.append('currency', filterCurrency);
			params.append('name', filterName);
			params.append('ubication', filterUbication);
			params.append('lot', filterLot);
			params.append('sold', filterSold);
			params.append('owner', filterOwner);
			params.append('page', filterPage);

			return `/AdminScreen/reports/assets/search?${params.toString()}`;
		}
		if (filterOptions.formType === 'VentasActivo') {
			params.append('dateFrom', filterDateFrom);
			params.append('dateTo', filterDateTo);
			params.append('currency', filterCurrency);
			params.append('purchaser', filterPurchaser);
			params.append('lot', filterLot);
			params.append('page', filterPage);
			return `/AdminScreen/reports/assetSales/search?${params.toString()}`;
		}
		if (filterOptions.formType === 'Cobros') {
			params.append('page', filterPage);
			params.append('concept', filterConcept);
			params.append('dateFrom', filterDateFrom);
			params.append('dateTo', filterDateTo);
			params.append('currency', filterCurrency);

			return `/AdminScreen/reports/payments/search?${params.toString()}`;
		}
		if (filterOptions.formType === 'CuotasDeudas') {
			params.append('concept', filterConcept);
			params.append('dateFrom', filterDateFrom);
			params.append('dateTo', filterDateTo);
			params.append('lastPaymentFrom', filterLastPaymentFrom);
			params.append('lastPaymentTo', filterLastPaymentTo);
			params.append('paid', filterPaid);
			params.append('currency', filterCurrency);
			params.append('query', filterQuery);
			params.append('page', filterPage);
			console.log(params.toString());
			return `/AdminScreen/reports/debtDues/search?${params.toString()}`;
		}

		if (filterOptions.formType === 'CuotasCreditos') {
			params.append('concept', filterConcept);
			params.append('dateFrom', filterDateFrom);
			params.append('dateTo', filterDateTo);
			params.append('paid', filterPaid);
			params.append('currency', filterCurrency);
			params.append('query', filterQuery);
			params.append('page', filterPage);
			return `/AdminScreen/reports/creditDues/search?${params.toString()}`;
		}
		if (filterOptions.formType === 'EstadoCuenta') {
			params.append('accountId', filterId);
			params.append('concept', filterConcept);
			params.append('dateFrom', filterDateFrom);
			params.append('dateTo', filterDateTo);
			params.append('paid', filterPaid);
			params.append('currency', filterCurrency);
			params.append('query', filterQuery);
			params.append('page', filterPage);

			return `/AdminScreen/reports/accountsDetail/search?${params.toString()}`;
		}
		return '/AdminScreen/reports';
	};

	useEffect(() => {
		const fetchDataBasedOnURL = async () => {
			try {
				if (filterOptions.formType === 'Deudas') {
					dispatch({ type: 'FETCH_DEBTS_REQUEST' });
					const { data } = await DebtService.search(queryString);
					dispatch({ type: 'FETCH_DEBTS_SUCCESS', payload: data });
				}
				if (filterOptions.formType === 'Pasividades') {
					dispatch({ type: 'FETCH_CREDITS_REQUEST' });
					const { data } = await CreditService.search(queryString);
					dispatch({ type: 'FETCH_CREDITS_SUCCESS', payload: data });
				}
				if (filterOptions.formType === 'Alquileres') {
					dispatch({ type: 'FETCH_CONTRACTS_REQUEST' });
					const { data } = await ContractService.search(queryString);
					dispatch({ type: 'FETCH_CONTRACTS_SUCCESS', payload: data });
				}
				if (filterOptions.formType === 'ActivosFijos') {
					dispatch({ type: 'FETCH_CONTRACTS_REQUEST' });
					const { data } = await AssetService.search(queryString);
					dispatch({ type: 'FETCH_ASSETS_SUCCESS', payload: data });
				}
				if (filterOptions.formType === 'VentasActivo' || filterOptions.formType === 'ActivosFijos') {
					dispatch({ type: 'FETCH_ACCOUNTSIDS_REQUEST' });
					const onlyIds = true;
					const { data } = await AccountService.list(onlyIds);
					dispatch({ type: 'FETCH_ACCOUNTSIDS_SUCCESS', payload: data });
					setAccountsList(
						data.map((account) => ({ value: account._id, label: account.name })),
					);
				}
				if (filterOptions.formType === 'VentasActivo') {
					dispatch({ type: 'FETCH_ASSETSALES_REQUEST' });
					const { data } = await AssetSaleService.search(queryString);	
					dispatch({ type: 'FETCH_ASSETSALES_SUCCESS', payload: data });
				}
				if (filterOptions.formType === 'Cobros') {
					dispatch({ type: 'FETCH_PAYMENTS_REQUEST' });
					const { data } = await PaymentService.search(queryString);	
					dispatch({ type: 'FETCH_PAYMENTS_SUCCESS', payload: data });
				}
				

				if (
					filterOptions.formType === 'CuotasDeudas' &&
					selectedReport === 'Cuotas de deudas a cobrar mes actual'
				) {
					const params = new URLSearchParams();
					params.set('paid', 'true');
					params.set('dateFrom', firstDayOfMonth);
					params.set('dateTo', lastDayOfMonth);
					const updatedQueryString = params.toString();
					dispatch({ type: 'FETCH_DEBTSDUES_REQUEST' });
					const { data } = await GeneratedDebtService.search(updatedQueryString);
					dispatch({ type: 'FETCH_DEBTSDUES_SUCCESS', payload: data });
				}
				if (
					filterOptions.formType === 'CuotasDeudas' &&
					selectedReport === 'Todas las cuotas de deudas'
				) {
					dispatch({ type: 'FETCH_DEBTSDUES_REQUEST' });
					const { data } = await GeneratedDebtService.search(queryString);
					dispatch({ type: 'FETCH_DEBTSDUES_SUCCESS', payload: data });
				}
				if (
					filterOptions.formType === 'CuotasCreditos' &&
					selectedReport === 'Cuotas de pasividades a pagar mes actual'
				) {
					const params = new URLSearchParams();
					params.set('paid', 'true');
					params.set('dateFrom', firstDayOfMonth);
					params.set('dateTo', lastDayOfMonth);
					const updatedQueryString = params.toString();
					dispatch({ type: 'FETCH_CREDITSDUES_REQUEST' });
					const { data } = await GeneratedCreditService.search(updatedQueryString);
					dispatch({ type: 'FETCH_CREDITSDUES_SUCCESS', payload: data });
				}
				if (
					filterOptions.formType === 'CuotasCreditos' &&
					selectedReport === 'Todas las cuotas de pasividades'
				) {
					dispatch({ type: 'FETCH_CREDITSDUES_REQUEST' });
					const { data } = await GeneratedCreditService.search(queryString);
					dispatch({ type: 'FETCH_CREDITSDUES_SUCCESS', payload: data });
				}
				if (filterOptions.formType === 'EstadoCuenta' && selectedReport === 'Estado Situación') {
					dispatch({ type: 'FETCH_ACCOUNTS_REQUEST' });
					const queryStringWithReport = `${queryString}&report=true`;
					const { data } = await AccountService.search(queryStringWithReport);
					dispatch({ type: 'FETCH_ACCOUNTS_SUCCESS', payload: data });
					setAccountsList(
						data.accountsIds.map((account) => ({ value: account._id, label: account.name })),
					);
					setTotalGeneratedDebts(
						data.accounts
							.map((account) =>
								account.debts.reduce((total, debt) => total + debt.generatedDebts.length, 0),
							)
							.reduce((total, value) => total + value, 0),
					);
				}
			} catch (error) {
				dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
			}
		};
		fetchDataBasedOnURL();
	}, [search, page, filterOptions.formType, selectedReport]);

	useEffect(() => {
		handleApplyFilter();
	}, [filtered]);

	return (
		<div className='container admin-con align-items-center'>
			<Row style={{ margin: '5% 0 2.5%' }}>
				<Col className='d-flex align-items-start'>
					<h1 className='text-right m-0'>
						<MdAddchart></MdAddchart> Generación de Reportes
					</h1>
				</Col>
				<Col>
					<GenericDropdown
						label='Reporte a crear'
						items={reports}
						selected={selectedReport}
						onChange={handleInputReportChange}
						name='reporte'
					/>
				</Col>
			</Row>
			<Row>
				{selectedReport &&
				selectedReport !== 'Cuotas de deudas a cobrar mes actual' &&
				selectedReport !== 'Cuotas de pasividades a pagar mes actual' ? (
						<div>
							<h2 className='mb-3'>Filtrar {selectedReport} por:</h2>
							<FilterForm
								filterOptions={filterOptions}
								handleInputChange={handleInputChange}
								// isOpen={isOpen}
								// handleDropdownToggle={handleDropdownToggle}
								clearFilter={() => handleClearFilter(filterOptions.formType)}
								handleApplyFilter={() => handleApplyFilter(filterOptions.formType)}
								accountsList={accountsList ? accountsList : ''}
							/>
						</div>
					) : (
						''
					)}
			</Row>
			{selectedReport === 'Alquileres' && contracts && contracts.length !== 0 ? (
				<Row className=''>
					<DynamicTable
						data={contracts}
						allData={allContracts}
						loading={loadingContracts}
						error={error}
						dataName='contracts'
					/>
					<Paginator
						className='pagination-bar'
						totalCount={countContracts}
						onPageChange={onPageChange}
						currentPage={parseInt(page)}
						pageSize={pageSize}
					/>
				</Row>
			) : null}
			{selectedReport === 'Activos Fijos' && assets && assets.length !== 0 ? (
				<Row className=''>
					<DynamicTable
						data={assets}
						allData={allAssets}
						loading={loadingAssets}
						error={error}
						dataName='assets'
					/>
					<Paginator
						className='pagination-bar'
						totalCount={countAssets}
						onPageChange={onPageChange}
						currentPage={parseInt(page)}
						pageSize={pageSize}
					/>
				</Row>
			) : null}
			{selectedReport === 'Ventas de Activo' && assetSales && assetSales.length !== 0 ? (
				<Row className=''>
					<DynamicTable
						data={assetSales}
						allData={allAssetSales}
						loading={loadingAssetSales}
						error={error}
						dataName='assetSales'
					/>
					<Paginator
						className='pagination-bar'
						totalCount={countAssetSales}
						onPageChange={onPageChange}
						currentPage={parseInt(page)}
						pageSize={pageSize}
					/>
				</Row>
			) : null}
			{selectedReport === 'Deudores' && debts && debts.length !== 0 ? (
				<Row className=''>
					<DynamicTable
						data={debts}
						allData={allDebts}
						loading={loadingDebts}
						error={error}
						dataName='debts'
					/>
					<Paginator
						className='pagination-bar'
						totalCount={countDebts}
						onPageChange={onPageChange}
						currentPage={parseInt(page)}
						pageSize={pageSize}
					/>
				</Row>
			) : null}
			{selectedReport === 'Pasividades' && credits && credits.length !== 0 ? (
				<Row className=''>
					<DynamicTable
						data={credits}
						allData={allCredits}
						loading={loadingCredits}
						error={error}
						dataName='credits'
					/>
					<Paginator
						className='pagination-bar'
						totalCount={countCredits}
						onPageChange={onPageChange}
						currentPage={parseInt(page)}
						pageSize={pageSize}
					/>
				</Row>
			) : null}
			{selectedReport === 'Cobros' && payments && payments.length !== 0 ? (
				<Row className=''>
					<DynamicTable
						data={payments}
						allData={allPayments}
						loading={loadingPayments}
						error={error}
						dataName='payments'
					/>
					<Paginator
						className='pagination-bar'
						totalCount={countPayments}
						onPageChange={onPageChange}
						currentPage={parseInt(page)}
						pageSize={pageSize}
					/>
				</Row>
			) : null}
			{(selectedReport === 'Cuotas de deudas a cobrar mes actual' ||
				selectedReport === 'Todas las cuotas de deudas') &&
			debtsDues &&
			debtsDues.length !== 0 ? (
					<Row className=''>
						<DynamicTable
							data={debtsDues}
							allData={allDebtsDues}
							loading={loadingDebtsDues}
							error={error}
							dataName='debtsDues'
						/>
						<Paginator
							className='pagination-bar'
							totalCount={countDebtsDues}
							onPageChange={onPageChange}
							currentPage={parseInt(page)}
							pageSize={pageSize}
						/>
					</Row>
				) : null}
			{(selectedReport === 'Cuotas de pasividades a pagar mes actual' ||
				selectedReport === 'Todas las cuotas de pasividades') &&
			creditsDues &&
			creditsDues.length !== 0 ? (
					<Row className=''>
						<DynamicTable
							data={creditsDues}
							allData={allCreditsDues}
							loading={loadingCreditsDues}
							error={error}
							dataName='creditDues'
						/>
						<Paginator
							className='pagination-bar'
							totalCount={countCreditsDues}
							onPageChange={onPageChange}
							currentPage={parseInt(page)}
							pageSize={pageSize}
						/>
					</Row>
				) : null}
			{selectedReport === 'Estado Situación' && accounts && accounts.length !== 0 ? (
				<Row className=''>
					<GroupedTable
						data={accounts}
						totalGeneratedDebts={totalGeneratedDebts}
						allData={allAccounts}
						loading={loadingAccounts}
						error={error}
						dataName='accounts'
					/>
					<Paginator
						className='pagination-bar'
						totalCount={countAccounts}
						onPageChange={onPageChange}
						currentPage={parseInt(page)}
						pageSize={pageSize}
					/>
				</Row>
			) : null}
		</div>
	);
}
export default Reports;

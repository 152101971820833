import moment from 'moment';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Button, Card, Modal, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Helmet } from 'react-helmet-async';
import { MdInfoOutline, MdOutlineEdit, MdWarning } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import CustomTypeahead from '../../components/CustomTypeahead';
import FormatedNumberInput from '../../components/FormatedNumberInput';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import GenericDropdown from '../../components/SelectDropdown';
import accountService from '../../services/account.service';
import creditService from '../../services/credit.service';
import { currencies, dueDays, getError, monthOptions, periodicities } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {

	case 'FETCH_ACCOUNTS_SUCCESS':
		return { ...state, accounts: action.payload, loadingAccounts: false };
	case 'FETCH_CREDIT_SUCCESS':
		return { ...state, credit: action.payload, };
	case 'FETCH_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_SUCCESS':
		return { ...state, loading: false };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'UPDATE_REQUEST':
		return { ...state, loadingUpdate: true, successUpdate: false };
	case 'UPDATE_SUCCESS':
		return { ...state, loadingUpdate: false, successUpdate: true };
	case 'UPDATE_FAIL':
		return { ...state, loadingUpdate: false, successUpdate: false };
	default:
		return state;
	}
};

function EditCredit() {
	const params = useParams();
	const navigate = useNavigate();

	const { id } = params;

	const [{ loading, error, credit, loadingAccounts, accounts, loadingUpdate }, dispatch] =
		useReducer(reducer, {
			accounts: [],
			loadingAccounts: true,
			credit: {},
			loading: true,
			loadingUpdate: false,
			error: '',
		});

	const { state, dispatch: ctxDispatch } = useContext(Store);
	const [capital, setCapital] = useState();
	const [amount, setAmount] = useState(0);
	const [interest, setInterest] = useState();
	const [selectedConcept, setSelectedConcept] = useState();
	const [concept, setConcept] = useState();

	const concepts = ['prestamos-amortizable', 'prestamos-interés'];

	const updateDuesOptions = () => {
		if (
			moment
				.utc(newFirstDueExpiration)
				.startOf('month')
				.isBefore(moment.utc(firstDueExpiration).startOf('month')) &&
			generatedCredits
		) {
			return ['Mover cuotas', 'Crear cuotas faltantes'];
		} else if (
			moment
				.utc(newFirstDueExpiration)
				.startOf('month')
				.isAfter(moment.utc(firstDueExpiration).startOf('month')) &&
			generatedCredits
		) {
			return ['Mover cuotas', 'Eliminar cuotas sobrantes'];
		} else if (generatedCredits) {
			return ['Mover cuotas'];
		} else {
			return ['Crear cuotas faltantes'];
		}
	};

	const [dues, setDues] = useState(1);
	const [date, setDate] = useState();
	const [contract, setContract] = useState();
	const [periodicity, setPeriodicity] = useState('');
	const [owner, setOwner] = useState();
	const [disabledInput, setDisabledInput] = useState(false);
	const [hasPreviousCredits, setHasPreviousCredits] = useState(false);
	const [previousCredit, setPreviousCredit] = useState();
	const [previousCreditDate, setPreviousCreditDate] = useState();
	const [currency, setCurrency] = useState();
	const [endDate, setEndDate] = useState();
	const [disabledAmount, setDisabledAmount] = useState(false);
	const [disabledOwner, setDisabledOwner] = useState(false);
	const [disabledEndDate] = useState(false);

	const [createWithPreviousDues, setCreateWithPreviousDues] = useState();
	const [createWithPreviousPayments, setCreateWithPreviousPayments] = useState();
	const [firstDueExpiration, setFirstDueExpiration] = useState();
	const [newFirstDueExpiration, setNewFirstDueExpiration] = useState();
	const [numMonths, setNumMonths] = useState();
	const [selectedMonthOption, setSelectedMonthOption] = useState();
	const [dueDay, setDueDay] = useState();
	const [showDateModal, setShowDateModal] = useState(false);
	const [updateDateActive, setUpdateDateActive] = useState(false);
	const [selectedUpdateDateOption, setSelectedUpdateDateOption] = useState('');
	const [generatedCredits, setgeneratedCredits] = useState();
	const [periodicityNumMonths] = useState();

	const handleCloseDateModal = () => {
		setShowDateModal(false);
		setUpdateDateActive(false);
	};

	const handleShowDateModal = () => {
		setShowDateModal(true);
		setUpdateDateActive(true);
	};

	const fetchCreditData = async () => {
		const result = await creditService.getDetails(id);
		dispatch({ type: 'FETCH_CREDIT_SUCCESS', payload: result.data });
		setAmount(result.data.amount);
		setConcept(result.data.concept);
		setDate(result.data.date);
		setOwner(result.data.owner);
		setContract([result.data.contract]);
		setEndDate(result.data.endDate);
		setPeriodicity(result.data.periodicity);
		setInterest(result.data.interest);
		setCapital(result.data.capital);
		setDues(result.data.dues);
		setHasPreviousCredits(result.data.hasPreviousCredits);
		setPreviousCredit(result.data.previousCredit);
		setPreviousCreditDate(result.data.previousCreditDate);
		setSelectedConcept(result.data.concept);
		setCurrency(result.data.currency);
		setFirstDueExpiration(result.data.firstDueExpiration);
		setSelectedMonthOption(result.data.selectedMonthOption);
		setDueDay(result.data.dueDay);
		setNumMonths(result.data.numMonths);
		setCreateWithPreviousDues(result.data.createWithPreviousDues);
		setCreateWithPreviousPayments(result.data.createWithPreviousPayments);
		setgeneratedCredits(result.data.generatedCredits);
	};
	const fetchAccounts = async () => {
		const result = await accountService.list();
		dispatch({ type: 'FETCH_ACCOUNTS_SUCCESS', payload: result.data });
	};
	const fetchData = async () => {
		dispatch({ type: 'FETCH_REQUEST' });
		await Promise.all([fetchAccounts(), fetchCreditData()]);
		try {
			dispatch({ type: 'FETCH_SUCCESS' });
		} catch (ex) {
			dispatch({ type: 'FETCH_FAIL', payload: getError(ex) });
		}
	};

	useEffect(() => {
		fetchData();
	}, [id]);

	const editCreditHandler = async () => {
		const emptyFields = [];

		if (isNaN(parseInt(interest))) {
			emptyFields.push('Interés');
		}
		if (!capital) {
			emptyFields.push('Capital');
		}
		if (!periodicity) {
			emptyFields.push('Periodicidad');
		}
		if (!dues && (selectedConcept === 'prestamos-amortizable')) {
			emptyFields.push('Cuotas');
		}
		if (!owner) {
			emptyFields.push('Cuenta');
		}
		if (!currency) {
			emptyFields.push('Moneda');
		}
		if (emptyFields.length > 0) {
			const errorMessages = `Debe completar el/los campo/s: ${emptyFields.join(', ')}`;
			toast.error(errorMessages);
			return;
		}

		try {
			dispatch({ type: 'CREATE_REQUEST' });
			if (!owner) {
				toast.error('Debe seleccionar una cuenta.');
				return;
			}
			const requestData = {
				amount,
				concept,
				date,
				owner: owner[0] || owner,
				currency,
				periodicity,
			};
			//check if is 'alquileres' to set contract
			//check if is 'alquileres' to set contract
			if (concept == 'alquileres' && contract.length > 0) {
				requestData.contract = contract[0];
				requestData.endDate = endDate;
				requestData.periodicity = '';
				requestData.interest = '';
				requestData.dues = '';
				requestData.capital = '';
				requestData.dues = '';
			}
			//check if is 'prestamos' to set other fields
			if (selectedConcept.includes('prestamos')) {
				requestData.interest = interest;
				requestData.capital = capital;
			}
			if (selectedConcept === 'prestamos-interés') {
				requestData.endDate = endDate;
			}
			if (selectedConcept === 'prestamos-amortizable') {
				requestData.dues = dues;
			}
			if (hasPreviousCredits) {
				requestData.hasPreviousCredits = hasPreviousCredits;
				requestData.previousCredit = previousCredit;
				requestData.previousCreditDate = previousCreditDate;
			}
			//check if is dont 'hasPreviousCredits' to unset other fields
			if (!hasPreviousCredits) {
				requestData.hasPreviousCredits = false;
				requestData.previousCredit = '';
				requestData.previousCreditDate = '';
			}

			const { data } = await creditService.edit(credit._id, requestData);
			dispatch({
				type: 'UPDATE_SUCCESS',
			});
			ctxDispatch({ type: 'CONTACT_UPDATE_SUCCESS', payload: data });
			toast.success('Pasividad actualizada Correctamente');
			navigate(-1);
		} catch (err) {
			dispatch({ type: 'FETCH_FAIL' });
			toast.error(getError(err));
		}
	};
	const [isInvalid, setIsInvalid] = useState(false);

	const handleSelectedConcept = (e) => {
		dispatch({ type: 'CALC' });
		setSelectedConcept(e.value);
		if (concept === 'prestamos-amortizable') {
			setDisabledInput(false);
			setDisabledOwner(false);
		}
		if (e.value !== 'otro') {
			setConcept(e.value);
		}
	};
	//for calc button
	function AmountCalc() {
		dispatch({ type: 'CALC' });
		if (concept === 'prestamos-amortizable') {
			getValorDeCuotaFijaMensualAmortizable();
			setDisabledAmount(false);
			dispatch({ type: 'CALC_SUCCESS' });
		}
		if (concept === 'prestamos-interés') {
			getValorDeCuotaFijaMensualInteres();
			dispatch({ type: 'CALC_SUCCESS' });
		}
	}
	function getValorDeCuotaFijaMensualAmortizable() {
		if (!capital || !interest || !dues) {
			toast.error('Debe ingresar Capital, interés y cuotas');
			return;
		} else {
			if (interest == 0) {
				const valorCuota = capital / dues;
				setAmount(valorCuota);
			} else {
				const tasa = interest / 100;
				const valorCuota =
					capital * ((tasa * Math.pow(1 + tasa, dues)) / (Math.pow(1 + tasa, dues) - 1));
				setAmount(Math.ceil(valorCuota));
			}
		}
	}

	function getValorDeCuotaFijaMensualInteres() {
		if (!capital || !interest) {
			toast.error('Debe ingresar Capital e interés');
			return;
		}
		const tasa = interest / 100;
		const valorCuota = capital * tasa;
		setAmount(Math.ceil(valorCuota));
	}
	//account set only if 'alquileres'
	const handleAccountChange = (selected) => {
		if (!selected || selected.length === 0) {
			const selectedAccount = selected[0];
			const isValid = selectedAccount ? accounts.includes(selectedAccount) : false;
			setIsInvalid(!isValid);
			setOwner(null);
			setCurrency('');
			setDisabledAmount(false);
		} else {
			const selectedAccount = selected[0];
			const isValid = selectedAccount ? accounts.includes(selectedAccount) : false;
			setIsInvalid(!isValid);

			setOwner(selected[0]);
			setCurrency(selectedAccount.currency);
			setDisabledAmount(true);
		}
	};
	//contract handle if 'alquileres'
	// const handlePropertyChange = (selected) => {
	//   if (!selected || selected.length === 0) {
	//     // Reset the state variables if nothing is selected
	//     const selectedProperty = selected[0];
	//     const isPropertyInvalid = contracts.includes(selectedProperty);
	//     setIsPropertyInvalid(!isPropertyInvalid);
	//     setContract(null);
	//     setOwner('');
	//     setAmount('');
	//     setCurrency('');
	//     setDisabledAmount(false);
	//     setDisabledOwner(false);
	//     setDisabledEndDate(false)
	//   } else {
	//     // Update the state variables with the selected property
	//     const selectedProperty = selected[0];
	//     const isPropertyInvalid = contracts.includes(selectedProperty);
	//     setIsPropertyInvalid(!isPropertyInvalid);
	//     setContract(selected);
	//     setOwner(selectedProperty.owner);
	//     setAmount(selectedProperty.amount);
	//     setCurrency(selectedProperty.currency);
	//     setEndDate(selectedProperty.periodEnd);
	//     setDisabledEndDate(true)
	//     setDisabledAmount(true);
	//     setDisabledOwner(true);
	//   }
	// };
	const generateDate = () => {
		if (date && selectedMonthOption && dueDay) {
			const currentDate = moment.utc(date, 'YYYY-MM-DD');
			let futureDate;
			if (selectedMonthOption === 'Mes actual') {
				futureDate = currentDate.clone();
			} else if (selectedMonthOption === 'Mes siguiente') {
				futureDate = currentDate.clone().add(1, 'month');
			} else if (
				selectedMonthOption === 'En tres meses' ||
				selectedMonthOption === 'En seis meses'
			) {
				const monthsToAdd = selectedMonthOption === 'En tres meses' ? 3 : 6;
				futureDate = currentDate.clone().add(monthsToAdd, 'month');
			} else if (selectedMonthOption === 'Otro' && numMonths) {
				futureDate = currentDate.clone().add(numMonths, 'month');
			} else if (selectedMonthOption === 'Otro' && !numMonths) {
				return;
			}
			futureDate.date(Math.min(dueDay, futureDate.daysInMonth()));

			setNewFirstDueExpiration(moment.utc(futureDate).format('YYYY-MM-DD'));
			return firstDueExpiration;
		}
	};
	const updateCreditDateHandler = async () => {
		const emptyFields = [];
		if (!date) {
			emptyFields.push('Dia de vencimiento');
		}
		if (!dueDay) {
			emptyFields.push('Dia de vencimiento');
		}
		if (!selectedMonthOption) {
			emptyFields.push('generar a partir de ');
		}
		if (selectedMonthOption && selectedMonthOption === 'Otro' && !numMonths) {
			emptyFields.push('cantidad de meses');
		}
		if (!selectedUpdateDateOption) {
			emptyFields.push('Acción sobre los datos existentes');
		}
		if (emptyFields.length > 0) {
			const errorMessages = `Debe completar el/los campo/s: ${emptyFields.join(', ')}`;
			toast.error(errorMessages);
			return;
		}
		generateDate();
		if (moment.utc(newFirstDueExpiration).isBefore(moment.utc(date))) {
			toast.error('La fecha de vencimiento no puede ser menor a la fecha seleccionada');
			return;
		}

		try {
			dispatch({ type: 'UPDATE_REQUEST' });
			if (!owner) {
				toast.error('Debe seleccionar una cuenta.');
				return;
			}
			const requestData = {
				date: moment.utc(date),
				dueDay,
				selectedMonthOption,
				firstDueExpiration: moment.utc(newFirstDueExpiration),
				updateDateOption:selectedUpdateDateOption,
				updatedDateAt: moment.utc(),
				updatedDateBy: state.userInfo._id,
				updateDateActive,
			};
			if (selectedMonthOption === 'Otro') {
				requestData.numMonths = numMonths;
			}
			if (periodicity === 'otra') {
				requestData.periodicityNumMonths = periodicityNumMonths;
			}
			await creditService.editDate(id, requestData);
			dispatch({
				type: 'UPDATE_SUCCESS',
			});
			fetchCreditData();
			toast.success('Fecha de inicio de credito actualizada correctamente ');
			setShowDateModal(false);
		} catch (err) {
			dispatch({ type: 'UPDATE_FAIL' });
			toast.error(getError(err));
		}
	};

	useEffect(() => {
		generateDate();
	}, [date, selectedMonthOption, dueDay, numMonths]);

	return (
		<div>
			<Modal size='xl' show={true} animation={false}>
				{loading ? (
					<LoadingBox></LoadingBox>
				) : (
					error ? (
						<MessageBox variant='danger'>{error}</MessageBox>
					) : null
				)}
				<div className='container admin-con align-items-center'>
					<Helmet>
						<title>Editar Pasividad</title>
					</Helmet>
					<h1>
						<MdOutlineEdit></MdOutlineEdit>Editar Pasividad
					</h1>
					<Form className='admin-form' onSubmit={(e) => e.preventDefault()}>
						<div className='row align-items-center'>
							<Card className='d-flex mb-3 justify-content-center card-form-credits'>
								<OverlayTrigger
									placement='top'
									overlay={
										<Tooltip id='tooltip-previous-credit'>
											<MdWarning />
											Estos datos solo se pueden editar luego de creado el credito mediante la
											opción:Cambiar fecha de inicio.
										</Tooltip>
									}
								>
									<Row>
										<h2 className='text-center m-3'> Datos para la generación de cuotas</h2>
									</Row>
								</OverlayTrigger>
								<Row className='d-flex mb-3 justify-content-center'>
									<Form.Group className='mb-3 col-3 form-group required' controlId='Date'>
										<Form.Label>Fecha de inicio de la crédito</Form.Label>
										<Form.Control
											type='date'
											value={date ? moment(date).utc().format('YYYY-MM-DD') : null}
											disabled
										></Form.Control>
									</Form.Group>
									<Form.Group className='mb-3 col-3 form-group required' controlId='DaysDropdown'>
										<Form.Label>Día vencimiento de 1er cuota:</Form.Label>
										<Form.Control value={dueDay ? dueDay : 'No hay datos'} />
									</Form.Group>
									<Form.Group
										className='mb-3 col-2 form-group required'
										controlId='selectedMonthOption'
									>
										<Form.Label>Generar a partir de:</Form.Label>
										<Form.Control
											value={selectedMonthOption ? credit.selectedMonthOption : 'No hay datos'}
										/>
									</Form.Group>
									{credit.selectedMonthOption === 'Otro' && (
										<Form.Group className='mb-3 col-3 form-group required' controlId='numMonths'>
											<Form.Label>En cuantos meses:</Form.Label>
											<Form.Control type='number' min='1' max ='240' value={numMonths} 
												onInput={(e) => {
													const inputValue = parseInt(e.target.value, 10);
													const maxValue = 240;
													if (maxValue !== null && inputValue > maxValue) {
														e.target.value = maxValue;
													}
												}}/>
										</Form.Group>
									)}
								</Row>
								<Row>
									<div className='d-flex d-inline justify-content-center align-items-center'>
										<p>
											{firstDueExpiration
												? `Primer Vencimiento: ${moment.utc(firstDueExpiration).format('DD-MM-YYYY')}  `
												: ''}
										</p>
									</div>
								</Row>
								<Row>
									<div className='d-flex d-inline justify-content-center align-items-center'>
										<Button className='mb-3 col-3' onClick={handleShowDateModal}>
											Cambiar Fecha de inicio
										</Button>
									</div>
								</Row>
								<Modal size={'xl'} show={showDateModal} onHide={handleCloseDateModal}>
									{(loading || loadingAccounts || loadingUpdate) && <LoadingBox></LoadingBox>}
									<Modal.Header>
										<h1>Editar Fecha de inicio</h1>
									</Modal.Header>
									<Modal.Body className='container admin-con align-items-center'>
										<Row className='d-flex mb-3 justify-content-center'>
											<Form.Group className='mb-3 col-3 form-group required' controlId='Date'>
												<Form.Label>Fecha de inicio de crédito</Form.Label>
												<Form.Control
													type='date'
													value={moment(date).utc().format('YYYY-MM-DD')}
													onChange={(e) => setDate(e.target.value)}
												></Form.Control>
											</Form.Group>
											<Form.Group
												className='mb-3 col-4 form-group required'
												controlId='DaysDropdown'
											>
												<GenericDropdown
													label='Día vencimiento de 1er cuota:'
													items={dueDays}
													selected={dueDay ? dueDay.toString() : ''}
													onChange={(e) => setDueDay(e.value)}
												/>
											</Form.Group>
											<Form.Group
												className='mb-3 col-4 form-group required'
												controlId='selectedMonthOption'
											>
												<GenericDropdown
													label='Generar a partir de:'
													items={monthOptions}
													selected={selectedMonthOption}
													onChange={(e) => setSelectedMonthOption(e.value)}
												/>
											</Form.Group>
											{selectedMonthOption === 'Otro' && (
												<Form.Group
													className='mb-3 col-3 form-group required'
													controlId='numMonths'
												>
													<Form.Label>Ingrese en cuantos meses:</Form.Label>
													<Form.Control
														type='number'
														min='1'
														max='240'
														value={numMonths}
														onChange={(e) => setNumMonths(parseInt(e.target.value, 10))}
														onInput={(e) => {
															const inputValue = parseInt(e.target.value, 10);
															const maxValue = 240;
															if (maxValue !== null && inputValue > maxValue) {
																e.target.value = maxValue;
															}
														}}
													/>
												</Form.Group>
											)}
										</Row>
										<Row>
											<div className='d-flex d-inline justify-content-center align-items-center'>
												<p>
													{date
														? `Primer Vencimiento: ${moment.utc(newFirstDueExpiration).format('DD-MM-YYYY')}  `
														: ''}
												</p>
											</div>
										</Row>
										<Row className='d-flex mb-3 justify-content-center'>
											<Form.Group
												className='mb-3 col-10 form-group  required'
												controlId='selectedMonthOption'
											>
												<GenericDropdown
													name='duesOptions'
													label={
														<>
															<OverlayTrigger
																trigger='click'
																right='left'
																overlay={
																	<Popover id='tooltip-previous-credit' style={{ minWidth: '50%' }}>
																		<Popover.Header as='h3'> A tener en cuenta:</Popover.Header>
																		<Popover.Body style={{ maxHeight: '200px', overflowY: 'auto' }}>
																			<ul style={{ listStyleType: 'circle', paddingLeft: '20px' }}>
																				<li>
																					{' '}
																					Si selecciona Mover Cuotas, se recorren las cuotas
																					existentes y se corrigen las fechas y nombres en base a la
																					fecha de primer vencimiento seleccionada.
																				</li>
																				<li>
																					{' '}
																					Si la fecha de primer vencimiento es previa a la anterior,
																					tiene la opción de: Mover cuotas o Crear cuotas faltantes.
																					<li><b>Crear cuotas faltantes:</b>
																						Se mantienen las fechas de las cuotas actuales, y se
																						agregan cuotas faltantes al inicio.
																					</li>
																				</li>
																				<li>
																					{' '}
																					Si la fecha de primer vencimiento es posterior a la
																					anterior, tiene la opción de: Mover cuotas o Eliminar
																					cuotas sobrantes.
																					<li>
																						<b>Eliminar cuotas sobrantes:</b>
																						Se mantienen las fechas de las cuotas
																						actuales, y se eliminan las cuotas sobrantes desde el
																						inicio.
																						ADVERTENCIA: Si las cuotas a eliminar tienen cobros relacionados, éstos se eliminaran
																					</li>
																				</li>
																			</ul>
																		</Popover.Body>
																	</Popover>
																}
															>
																<span style={{ cursor: 'pointer' }}>
																	<MdInfoOutline />
																</span>
															</OverlayTrigger>
															Que hacer con los Datos existentes:
														</>
													}
													items={updateDuesOptions()}
													onChange={(e) => setSelectedUpdateDateOption(e.value)}
												/>
											</Form.Group>
										</Row>
										<Row className='modal-btns'>
											<Col>
												<Button className='cancelar' onClick={handleCloseDateModal}>
													Cancelar
												</Button>
											</Col>
											<Col>
												<Button className='guardar' onClick={updateCreditDateHandler}>
													Guardar
												</Button>
											</Col>
										</Row>
									</Modal.Body>
								</Modal>
							</Card>
							{createWithPreviousDues ? (
								<Card className='d-flex align-items-center justify-content-center mb-3 card-form-credits'>
									<Row>
										<h2 className='text-center m-3'>
											<MdWarning />
											Crédito con cuotas previas{' '}
											{createWithPreviousPayments ? 'y pagas' : ''}
										</h2>
									</Row>
									<Row>
										<p>
											Ésta crédito fue creado con fecha anterior, por lo que fueron generadas las
											cuotas previas a la fecha de creación
										</p>
									</Row>
								</Card>
							) : (
								''
							)}
							<Card className='d-flex justify-content-center mb-3 card-form-credits'>
								<Row>
									<h2 className='text-center m-3'>Información contable</h2>
								</Row>
								<Row>
									<Form.Group className='mb-3 col-4 form-group required'>
										<GenericDropdown
											label='Concepto'
											items={concepts}
											selected={selectedConcept}
											onChange={handleSelectedConcept}
										/>
									</Form.Group>
									{/* {(selectedConcept || concept) === "alquileres" ?
                  (
                    <Form.Group className="mb-3 col-4 form-group required">
                      <Form.Label>Propiedad:</Form.Label>
                      {credit.contract && credit.contract != null ? (
                        <>
                          <CustomTypeahead
                            id='contracts'
                            labelKey={(option) =>
                              `${option.customId}`
                            }
                            onChange={handlePropertyChange}
                            options={contracts}
                            selected={contract}
                            placeholder='Buscar una propiedad...'
                            linkToCreate='/AdminScreen/newContract'
                            newOption='nuevo alquiler'
                          />
                          {isPropertyInvalid && <div style={{ color: 'red' }}>Alquiler no seleecionado o invalido</div>}
                        </>
                      ) : (
                        <>
                          <CustomTypeahead
                            id='contracts'
                            labelKey={(option) =>
                              `${option.customId}`
                            }
                            onChange={handlePropertyChange}
                            options={contracts}
                            placeholder='Buscar una propiedad...'
                            linkToCreate='/AdminScreen/newContract'
                            newOption='nuevo alquiler'
                          />
                          {isPropertyInvalid && <div style={{ color: 'red' }}>Alquiler no seleccionado o invalido</div>}
                        </>
                      )}
                    </Form.Group>) : ('')
                } */}
								</Row>
								<Row>
									<Form.Group className='mb-3 col-4 form-group required'>
										<Form.Label>Deudor</Form.Label>
										{credit.owner && credit.owner != null && credit.owner != '' ? (
											<>
												<CustomTypeahead
													id='accounts'
													labelKey={(option) => `${option.name}`}
													onChange={handleAccountChange}
													options={accounts}
													placeholder='Buscar Cuenta... '
													disabled={disabledOwner || concept === 'alquileres'}
													selected={owner ? [owner] : null}
													isInvalid={isInvalid}
													linkToCreate='/AdminScreen/newAccount'
													newOption='nueva cuenta'
												/>
												{isInvalid && (
													<div style={{ color: 'red' }}>Cuenta no seleccionada o invalida</div>
												)}
											</>
										) : (
											<>
												<CustomTypeahead
													id='accounts'
													labelKey={(option) => `${option.name}`}
													onChange={handleAccountChange}
													options={accounts}
													placeholder='Buscar Cuenta... '
													isInvalid={isInvalid}
													disabled={disabledOwner}
													linkToCreate='/AdminScreen/newAccount'
													newOption='nueva cuenta'
												/>
												{isInvalid && (
													<div style={{ color: 'red' }}>Cuenta no seleccionada o invalida</div>
												)}
											</>
										)}
									</Form.Group>
									<Form.Group as={Col} className='mb-3 col-3 form-group required' controlId='currency' >
										<GenericDropdown
											label='Moneda'
											items={currencies}
											selected={currency}
											onChange={(e) => setCurrency(e.value)}
										/>
									</Form.Group>
									{selectedConcept && selectedConcept.includes('prestamos') && (
										<>
											<Form.Group className='mb-3 col-4 form-group required'>
												<GenericDropdown
													label='Periodicidad'
													items={periodicities}
													selected={periodicity}
													onChange={(e) => setPeriodicity(e.value)}
												/>
											</Form.Group>
											<Form.Group className='mb-3 col-4 form-group required' controlId='amount'>
												<Form.Label>Capital</Form.Label>
												<FormatedNumberInput
													type='number'
													defaultValue={capital}
													onChange={(e) => setCapital(e.target.value)}
													min={0}
												></FormatedNumberInput>
											</Form.Group>
											<Form.Group className='mb-3 col-4 form-group required' controlId='amount'>
												<Form.Label>Interes %</Form.Label>
												<FormatedNumberInput
													type='number'
													defaultValue={interest}
													onChange={(e) => setInterest(e.target.value)}
													min={0}
												></FormatedNumberInput>
											</Form.Group>
											{selectedConcept === 'prestamos-amortizable' && (
												<Form.Group className='mb-3 col-2 form-group required' controlId='dues'>
													<Form.Label>Cuotas</Form.Label>
													<FormatedNumberInput
														type='number'
														defaultValue={dues}
														onChange={(e) => setDues(e.target.value)}
														min={1}
														noDecimal={true}
													></FormatedNumberInput>
												</Form.Group>
											)}
											<Button className='mb-3 col-2' onClick={AmountCalc} disabled={disabledInput}>
												Calcular
											</Button>
										</>
									)}
									<Form.Group className='mb-3 col-2 form-group required' controlId='amount'>
										<Form.Label>
											Monto{' '}
											{selectedConcept && selectedConcept.includes('prestamos') ? 'cuota' : ''}
										</Form.Label>
										<Form.Control
											type='number'
											value={amount}
											disabled={disabledAmount || concept === 'alquileres'}
											onChange={(e) => setAmount(e.target.value)}
											min={0}
										></Form.Control>
									</Form.Group>
									{selectedConcept !== 'prestamos-amortizable' && (
										<Form.Group className='mb-3 col-3 form-group' controlId='endDate'>
											<Form.Label>Fecha de fin</Form.Label>
											<Form.Control
												type='date'
												disabled={disabledEndDate || concept === 'alquileres'}
												value={endDate ? moment(endDate).format('YYYY-MM-DD') : null}
												onChange={(e) => setEndDate(e.target.value)}
											></Form.Control>
										</Form.Group>
									)}
								</Row>
							</Card>
							<Card className='d-flex  justify-content-between mb-3 card-form-credits'>
								<Row>
									<h2 className='text-center m-3'>Registro fuera del sistema</h2>
								</Row>
								<Row className='d-flex flex-row align-items-center'>
									<OverlayTrigger
										placement='left'
										overlay={
											<Tooltip id='tooltip-previous-credit'>
												Los datos que se completan aqui son únicamente informativos, con el fin de
												registrar la contabilidad fuera del sistema
											</Tooltip>
										}
									>
										<Form.Group
											className=' col-3 form-group d-flex align-items-center'
											controlId='hasPreviouscredits'
											style={{ height: '60px' }}
										>
											<Form.Check
												className='d-flex align-items-center'
												type='checkbox'
												label='Tiene credito anterior?'
												checked={previousCredit}
												onChange={(e) => setHasPreviousCredits(e.target.checked)}
											/>
										</Form.Group>
									</OverlayTrigger>
									<Form.Group className='mb-3 col-4 form-group ' controlId='previousCredit'>
										<Form.Label>Credito Anterior</Form.Label>
										<FormatedNumberInput
											type='number'
											onChange={(e) => setPreviousCredit(e.target.value)}
											disabled={!hasPreviousCredits}
											value={previousCredit}
											min={0}
										></FormatedNumberInput>
									</Form.Group>
									<Form.Group className='mb-3 col-4 form-group ' controlId='previousCreditDate'>
										<Form.Label>Fecha credito anterior</Form.Label>
										<Form.Control
											type='date'
											disabled={!hasPreviousCredits}
											value={previousCreditDate}
											onChange={(e) => setPreviousCreditDate(e.target.value)}
										></Form.Control>
									</Form.Group>
								</Row>
							</Card>
						</div>
						<Row className='modal-btns'>
							<Col>
								<Button className='cancelar' onClick={() => navigate(-1)}>
									Cancelar
								</Button>
							</Col>
							<Col>
								<Button className='guardar' onClick={editCreditHandler}>
									Guardar
								</Button>
							</Col>
						</Row>
					</Form>
				</div>
			</Modal>
		</div>
	);
}
export default EditCredit;

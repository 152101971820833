import React, { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import LoadingTableBox from '../components/LoadingTableBox';
import MessageBox from '../components/MessageBox';
import { Row, Col, Table, Form, Button, Modal } from 'react-bootstrap';
import moment from 'moment';
import { showCurrency } from '../utils';
import { downloadExcel } from 'react-export-table-to-excel';
import { FaFileExcel, FaEye } from 'react-icons/fa';

const DynamicTable = ({ data, loading, error, dataName, allData, toCollectUSD, toCollectUYU }) => {
	const tableRef = useRef(null);
	const [show, setShow] = useState(false);
	const [showAmounts, setShowAmounts] = useState(false);
	const [selectedFields, setSelectedFields] = useState(['customId']);
	const [exportName, setExportName] = useState('');
	const todayDate = moment().format('DD-MM-YYYY');

	const handleClose = () => setShow(false);
	const handleCloseAmounts = () => setShowAmounts(false);
	const handleShow = () => setShow(true);
	const handleShowAmounts = () => setShowAmounts(true);

	const { pathname } = useLocation();

	//columnMap to show table
	const columnMap = {
		contracts: {
			customId: 'Inquilino:Cuenta',
			amount: 'Monto',
			periodInit: 'Inicio',
			periodEnd: 'Fin',
			'owner.name': 'Inquilino',
			folder: 'Carpeta',
		},
		debts: {
			customId: 'Cuenta:Concepto',
			'owner.name': 'Deudor',
			capital: 'Capital',
			concept: 'Concepto',
			dues: 'Cuotas',
			amount: 'Monto cuota',
			date: 'Fecha',
			endDate: 'Fecha vto',
			periodicity: 'Periodicidad',
		},
		credits: {
			customId: 'Cuenta:Concepto',
			'owner.name': 'Cuenta',
			capital: 'Capital',
			concept: 'Concepto',
			dues: 'Cuotas',
			amount: 'Monto cuota',
			date: 'Fecha',
			endDate: 'Fecha vto',
			periodicity: 'Periodicidad',
		},
		debtsDues: {
			date: 'Fecha',
			'debt.customId': 'Deudor',
			'debt.concept': 'Concepto',
			customId: 'Cuota',
			amount: 'Monto',
			expiration: 'Vencimiento',
			unpaidAmount: 'Monto pendiente',
			paid: 'Pago',
			lastPaymentDate:'Fecha ultimo pago',
		},
		creditDues: {
			date: 'Fecha',
			'credit.customId': 'Deudor',
			'credit.concept': 'Concepto',
			customId: 'Cuota',
			amount: 'Monto',
			expiration: 'Vencimiento',
			unpaidAmount: 'Monto pendiente',
			paid: 'Pago',
		},
		accounts: {
			name: 'Nombre',
			type: 'Tipo',
			currency: 'moneda',
			folder: 'Carpeta',
		},
		assets: {
			name: 'Nombre',
			'owner.name':'Propietario',
			sold:'Vendido?',
			ubication:'Ubicación',
			lot:'Padrón',
			price:'precio',
		},
		assetSales:{
			customId:'Id',
			date:'Fecha',
			lot:'Padrón',
			amount:'Monto',
			'purchaser.name':'Comprador',
			sumDebts:'Total Deudas',
			'asset.name':'Propiedad',
		},
		payments: {
			customId: 'ID',
			'debtDue.customId': 'Cuota',
			amount: 'monto',
			date: 'Fecha',
			folder: 'carpeta',
		},
	};
	//columnMap to export table
	const columnMapToExport = {
		contracts: {
			customId: 'Inquilino:Cuenta',
			currency: 'Moneda',
			amount: 'Monto',
			periodInit: 'Inicio',
			periodEnd: 'Fin',
			'owner.name': 'Inquilino',
			folder: 'Carpeta',
			previousDebt: 'Saldo Anterior',
			previousDebtDate: 'Fecha de saldo Anterior',
			field: 'detalles',
			paymentMethod: 'Método de pago',
			observations: 'Observaciones',
		},
		debts: {
			customId: 'Cuenta:Concepto',
			'owner.name': 'Deudor',
			capital: 'Capital',
			concept: 'Concepto',
			dues: 'Cuotas',
			currency: 'Moneda',
			amount: 'Monto cuota',
			date: 'Fecha',
			endDate: 'Fecha vto',
			periodicity: 'Periodicidad',
			previousDebt: 'Deuda Anterior',
			previousDebtDate: 'Fecha de Deuda Anterior',
			interest: '% Interés',
		},
		credits: {
			customId: 'Cuenta:Concepto',
			'owner.name': 'Cuenta',
			capital: 'Capital',
			concept: 'Concepto',
			dues: 'Cuotas',
			currency: 'Moneda',
			amount: 'Monto cuota',
			date: 'Fecha',
			endDate: 'Fecha vto',
			periodicity: 'Periodicidad',
			previousCredit: 'Credito Anterior',
			previousCreditDate: 'Fecha de Credito Anterior',
			interest: '% Interés',
		},
		debtsDues: {
			'debt.customId': 'Deudor',
			date: 'Fecha',
			'debt.concept': 'Concepto',
			customId: 'Cuota',
			'debt.currency': 'Moneda',
			amount: 'Monto',
			expiration: 'Vencimiento',
			unpaidAmount: 'Monto pendiente',
			paid: 'Pago',
			lastPaymentDate:'Fecha ultimo pago',
		},
		creditDues: {
			date: 'Fecha',
			'credit.customId': 'Deudor',
			'credit.concept': 'Concepto',
			customId: 'Cuota',
			'credit.currency': 'Moneda',
			amount: 'Monto',
			expiration: 'Vencimiento',
			unpaidAmount: 'Monto pendiente',
			paid: 'Pago',
		},
		assets: {
			name: 'Nombre',
			type: 'Tipo',
			currency: 'Moneda',
			price:'Precio',
			ubication:'Ubicacion',
			folder: 'Carpeta',
			'owner.name':'Cuenta',
			observations:'Observaciones',
			guaranteedAmount:'Monto garantizado',
			multipleLots:'Multiples padrones?',
			lot:'Padron'
		},
		assetSales:{
			customId:'Id',
			date:'Fecha',
			lot:'Padrón',
			currency:'Moneda',
			amount:'Monto',
			'purchaser.name':'Comprador',
			sumDebts:'Total Deudas',
			initialPayment:'Pago inicial',
			'asset.name':'Propiedad',
		},
		payments: {
			customId: 'ID',
			'debtDue.customId': 'Cuota',
			'debt.currency': 'Moneda',
			amount: 'monto',
			date: 'Fecha',
			folder: 'carpeta',
			observations: 'Observaciones',
		},
	};

	const columnsToExport = Object.keys(columnMapToExport[dataName] || {});
	const columns = Object.keys(columnMap[dataName] || {});

	//to show with currency UYU:USD
	const currencyColumns = ['amount', 'capital','price'];

	//to show values like owner.name
	const getNestedValue = (item, column) => {
		const keys = column.split('.');
		let nestedValue = item;
		for (const key of keys) {
			if (
				nestedValue &&
				nestedValue[key] !== 'undefined' &&
				nestedValue[key] !== 0 &&
				nestedValue[key] !== null &&
				nestedValue[key] !== ''
			) {
				nestedValue = nestedValue[key];
			} else {
				return 'N/A';
			}
		}
		return nestedValue;
	};

	const formatValue = (value) => {
		if (value === true) {
			return 'Si';
		} else if (value === false) {
			return 'No';
		}

		const isValidDateFormat = moment.utc(value, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid();
		if (isValidDateFormat) {
			return moment.utc(value).format('DD-MM-YYYY');
		} else if (!value) {
			return 'N/A';
		} else {
			return value;
		}
	};

	//download xls with selected fields
	const handleDownloadExcel = () => {
		const selectedColumnMap = columnMapToExport[dataName];
		const header = selectedFields.map((field) => selectedColumnMap[field]);
		const body = allData.map((item) =>
			selectedFields.map((field) => formatValue(getNestedValue(item, field))),
		);
		downloadExcel({
			fileName: exportName ? `${exportName}` : `${todayDate}- ${dataName}`,
			sheet: 'react-export-table-to-excel',
			tablePayload: {
				header,
				body,
			},
		});
		handleClose();
	};

	//show two colums of checkboxes in modal
	const columnsPerRow = 2;

	const rows = Array.from(
		{ length: Math.ceil(columnsToExport.length / columnsPerRow) },
		(_, rowIndex) =>
			columnsToExport.slice(rowIndex * columnsPerRow, (rowIndex + 1) * columnsPerRow),
	);

	return (
		<div className='container m-3'>
			<Row>
				<Col className='col-3'>
					<Button
						size={'sm'}
						className='m-3'
						onClick={handleShow}
						style={{ backgroundColor: 'Green' }}
					>
						<FaFileExcel></FaFileExcel>Exportar
					</Button>
					{pathname.includes('/predictions') ? (
						<Button
							size={'sm'}
							className='m-3'
							onClick={handleShowAmounts}
							style={{ backgroundColor: 'Blue' }}
						>
							<FaEye></FaEye>Ver Totales
						</Button>
					) : (
						''
					)}
				</Col>
			</Row>
			<Modal show={showAmounts} onHide={handleCloseAmounts}>
				<Modal.Header closeButton>
					<Modal.Title>
						<FaEye></FaEye>Totales en fechas seleccionadas
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Table bordered hover responsive size='sm'>
						<thead>
							<tr>
								<th>Moneda</th>
								<th>Total a {dataName === 'debtsDues' ? 'Cobrar' : 'Pagar'}</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>USD</td>
								<td>${toCollectUSD ? toCollectUSD.toFixed(2) : ''}</td>
							</tr>
							<tr>
								<td>UYU</td>
								<td>${toCollectUYU ? toCollectUYU.toFixed(2) : ''}</td>
							</tr>
						</tbody>
					</Table>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={handleCloseAmounts}>
						Cerrar
					</Button>
				</Modal.Footer>
			</Modal>

			<Modal show={show} onHide={handleClose}>
				<Modal.Header>
					<Modal.Title>Exportar datos</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group as={Row} className='mb-3 align-items-center'>
						<Form.Label column md={6}>
							Nombre del archivo:
						</Form.Label>
						<Col>
							<Form.Control
								type='text'
								placeholder='Ingrese el nombre'
								value={exportName}
								onChange={(e) => setExportName(e.target.value)}
							/>
						</Col>
					</Form.Group>
					<>
						<p>Marque los campos que desea incluir</p>
						{rows.map((row, rowIndex) => (
							<Row key={rowIndex}>
								{row.map((column) => (
									<Col key={column} md={6}>
										<Form.Group
											as={Row}
											className='mb-3 align-items-center'
											controlId={`fieldsToInclude_${column}`}
										>
											<Form.Check
												className='col-8'
												type='checkbox'
												value={column}
												label={columnMapToExport[dataName][column]}
												defaultChecked={column === 'customId'}
												disabled={column === 'customId'}
												onChange={(e) => {
													const selected = e.target.checked;
													setSelectedFields((prevSelectedFields) =>
														selected
															? [...prevSelectedFields, column]
															: prevSelectedFields.filter((field) => field !== column),
													);
												}}
											/>
										</Form.Group>
									</Col>
								))}
							</Row>
						))}
					</>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={handleClose}>
						Cancelar
					</Button>
					<Button variant='primary' onClick={handleDownloadExcel}>
						Exportar XLS
					</Button>
				</Modal.Footer>
			</Modal>
			<>
				<Row>
					<p>Total Encontrados:{allData.length}</p>
				</Row>
				<Table bordered hover responsive size='sm' ref={tableRef}>
					<thead>
						<tr>
							{columns.map((column) => (
								<th key={column}>{columnMap[dataName][column]}</th>
							))}
						</tr>
					</thead>
					<tbody>
						{loading ? (
							<tr>
								<td colSpan={columns.length}>
									<LoadingTableBox loading={loading} />
								</td>
							</tr>
						) : error ? (
							<tr>
								<td colSpan={columns.length}>
									<MessageBox variant='danger'>{error}</MessageBox>
								</td>
							</tr>
						) : !data ? (
							<tr colSpan={columns.length}>
								<td colSpan={columns.length}>
									<MessageBox variant='light'>No hay resultados...</MessageBox>
								</td>
							</tr>
						) : (
							data.map((item) => (
								<tr key={item}>
									{columns.map((column) => (
										<td key={column}>
											{currencyColumns.includes(column)
												? dataName === 'debtsDues' || dataName === 'creditDues'
													? showCurrency(
														dataName === 'creditDues'
															? getNestedValue(item, 'credit.currency')
															: getNestedValue(item, 'debt.currency'),
														getNestedValue(item, column),
														dataName,
													) +
														' ' +
														getNestedValue(item, column)
													: showCurrency(
														getNestedValue(item, 'currency'),
														getNestedValue(item, column),
														dataName,
													) +
														' ' +
														getNestedValue(item, column)
												: formatValue(getNestedValue(item, column))}
										</td>
									))}
								</tr>
							))
						)}
					</tbody>
				</Table>
			</>
		</div>
	);
};

export default DynamicTable;

import axiosInstance from '../RestClient';

const BASE_URI = '/accounts';
class AccountService {
	async list(onlyIds) {
		const uri = `${BASE_URI}/`;
		const response = await axiosInstance.get(uri,onlyIds);
		return response;
	}

	async getDetails(recordId) {
		const uri = `${BASE_URI}/${recordId}`;
		const response = await axiosInstance.get(uri);
		return response;
	}

	async create(payload) {
		const response = await axiosInstance.post(`${BASE_URI}/`, payload);
		return response;
	}

	async edit(recordId, payload) {
		const response = await axiosInstance.put(`${BASE_URI}/${recordId}`, payload);
		return response;
	}

	async delete(recordId) {
		const uri = `${BASE_URI}/${recordId}`;
		const response = await axiosInstance.delete(uri);
		return response;
	}

	async search(query) {
		const uri = `${BASE_URI}/search?${query}`;
		const response = await axiosInstance.get(uri);
		return response;
	}

	async getDebtsForAccountId(recordId) {
		const uri = `${BASE_URI}/accountDebts/${recordId}`;
		const response = await axiosInstance.get(uri);
		return response;
	}

	async getCreditsForAccountId(recordId) {
		const uri = `${BASE_URI}/accountCredits/${recordId}`;
		const response = await axiosInstance.get(uri);
		return response;
	}
}
export default new AccountService();

import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Button, Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Helmet } from 'react-helmet-async';
import { MdInfoOutline, MdOutlineEdit } from 'react-icons/md';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import { getError } from '../../utils';

import moment from 'moment';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useNavigate, useParams } from 'react-router-dom';
import GenericDropdown from '../../components/SelectDropdown';
import debtService from '../../services/debt.service';
import { dueDays, monthOptions } from '../../utils';

const reducer = (state, action) => {
	switch (action.type) {
	case 'FETCH_DEBT_SUCCESS':
		return { ...state, debt: action.payload, loading:false};
	case 'FETCH_DEBT_REQUEST':
		return { ...state, loading: true };
	case 'FETCH_FAIL':
		return { ...state, loading: false, error: action.payload };
	case 'UPDATE_REQUEST':
		return { ...state, loadingUpdate: true, successUpdate: false };
	case 'UPDATE_SUCCESS':
		return { ...state, loadingUpdate: false, successUpdate: true };
	case 'UPDATE_FAIL':
		return { ...state, loadingUpdate: false, successUpdate: false };
	default:
		return state;
	}
};

function EditFirstDueDate() {
	const params = useParams();
	const navigate = useNavigate();

	const { id } = params;

	const [
		{ loading, error, loadingUpdate },
		dispatch,
	] = useReducer(reducer, {
		debt: {},
		loading: true,
		loadingUpdate: false,
		error: '',
	});

	const { state } = useContext(Store);


	const updateDuesOptions = () => {
		if (
			moment
				.utc(newFirstDueExpiration)
				.startOf('month')
				.isBefore(moment.utc(firstDueExpiration).startOf('month')) &&
			generatedDebts
		) {
			return ['Mover cuotas', 'Crear cuotas faltantes'];
		} else if (
			moment
				.utc(newFirstDueExpiration)
				.startOf('month')
				.isAfter(moment.utc(firstDueExpiration).startOf('month')) &&
			generatedDebts
		) {
			return ['Mover cuotas', 'Eliminar cuotas sobrantes'];
		} else if (generatedDebts) {
			return ['Mover cuotas'];
		} else {
			return ['Crear cuotas faltantes'];
		}
	};

	const [date, setDate] = useState();
	const [periodicity, setPeriodicity] = useState();
	const [owner, setOwner] = useState();
	const [firstDueExpiration, setFirstDueExpiration] = useState();
	const [newFirstDueExpiration, setNewFirstDueExpiration] = useState();
	const [numMonths, setNumMonths] = useState();
	const [selectedMonthOption, setSelectedMonthOption] = useState();
	const [dueDay, setDueDay] = useState();
	const [selectedUpdateDateOption, setSelectedUpdateDateOption] = useState('');
	const [generatedDebts, setGeneratedDebts] = useState();
	const [periodicityNumMonths, setPeriodicityNumMonths] = useState();
	const [customId,setCustomId]=useState();

	const fetchDebtData = async () => {
		dispatch({type: 'FETCH_DEBT_REQUEST'});
		try{
			const result = await debtService.getDetails(id);
			dispatch({ type: 'FETCH_DEBT_SUCCESS', payload: result.data });
			setDueDay(result.data.dueDay);
			setDate(result.data.date);
			setOwner(result.data.owner);
			setPeriodicity(result.data.periodicity);
			setFirstDueExpiration(result.data.firstDueExpiration);
			setSelectedMonthOption(result.data.selectedMonthOption);
			setNumMonths(result.data.numMonths);
			setGeneratedDebts(result.data.generatedDebts);
			setPeriodicityNumMonths(result.data.periodicityNumMonths);
			setCustomId(result.data.customId);
		}catch(ex) {
			console.error(ex);
			dispatch({type: 'FETCH_DEBT_FAIL', payload: getError(ex)});
			toast.error(getError(ex));
		}};



	useEffect(() => {
		fetchDebtData();
	}, [id]);

	const generateDate = () => {
		if (date && selectedMonthOption && dueDay) {
			const currentDate = moment.utc(date, 'YYYY-MM-DD');
			let futureDate;
			if (selectedMonthOption === 'Mes actual') {
				futureDate = currentDate.clone();
			} else if (selectedMonthOption === 'Mes siguiente') {
				futureDate = currentDate.clone().add(1, 'month');
			} else if (
				selectedMonthOption === 'En tres meses' ||
				selectedMonthOption === 'En seis meses'
			) {
				const monthsToAdd = selectedMonthOption === 'En tres meses' ? 3 : 6;
				futureDate = currentDate.clone().add(monthsToAdd, 'month');
			} else if (selectedMonthOption === 'Otro' && numMonths) {
				futureDate = currentDate.clone().add(numMonths, 'month');
			} else if (selectedMonthOption === 'Otro' && !numMonths) {
				return;
			}
			futureDate.date(Math.min(dueDay, futureDate.daysInMonth()));

			setNewFirstDueExpiration(moment.utc(futureDate).format('YYYY-MM-DD'));
			return firstDueExpiration;
		}
	};

	const updateDebtDateHandler = async () => {
		const emptyFields = [];
		if (!date) {
			emptyFields.push('Dia de vencimiento');
		}
		if (!dueDay) {
			emptyFields.push('Dia de vencimiento');
		}
		if (!selectedMonthOption) {
			emptyFields.push('generar a partir de ');
		}
		if (selectedMonthOption && selectedMonthOption === 'Otro' && !numMonths) {
			emptyFields.push('cantidad de meses');
		}
		if (!selectedUpdateDateOption) {
			emptyFields.push('Acción sobre los datos existentes');
		}
		if (emptyFields.length > 0) {
			const errorMessages = `Debe completar el/los campo/s: ${emptyFields.join(', ')}`;
			toast.error(errorMessages);
			return;
		}
		generateDate();
		if (moment.utc(newFirstDueExpiration).isBefore(moment.utc(date))) {
			toast.error('La fecha de vencimiento no puede ser menor a la fecha seleccionada');
			return;
		}

		try {
			dispatch({ type: 'UPDATE_REQUEST' });
			if (!owner) {
				toast.error('Debe seleccionar una cuenta.');
				return;
			}
			const requestData = {
				date: moment.utc(date),
				dueDay,
				selectedMonthOption,
				firstDueExpiration: moment.utc(newFirstDueExpiration),
				updateDateOption:selectedUpdateDateOption,
				updatedDateAt: moment.utc(),
				updatedDateBy: state.userInfo._id,
				updateDateActive:true,
			};
			if (selectedMonthOption === 'Otro') {
				requestData.numMonths = numMonths;
			}
			if (periodicity === 'otra') {
				requestData.periodicityNumMonths = periodicityNumMonths;
			}
			await debtService.editDate(id, requestData);
			dispatch({
				type: 'UPDATE_SUCCESS',
			});
			fetchDebtData();
			toast.success('Fecha de inicio de deuda actualizada correctamente ');
			navigate(-1);			
		} catch (err) {
			dispatch({ type: 'UPDATE_FAIL' });
			toast.error(getError(err));
		}
	};

	useEffect(() => {
		generateDate();
	}, [date, selectedMonthOption, dueDay, numMonths]);

	return (
		<div>
			<Modal size='xl' show={true} animation={false}>
				{loading ? <LoadingBox></LoadingBox> : 
					error ? <MessageBox variant='danger'>{error}</MessageBox>
						:
						<div className='container admin-con align-items-center'>
							<Helmet>
								<title>Editar Deuda</title>
							</Helmet>
							<h1>
								<MdOutlineEdit></MdOutlineEdit>Editar Deuda
							</h1>
							<Form className='admin-form' onSubmit={(e) => e.preventDefault()}>
								<div className='row align-items-center'>
									
									<Modal size={'xl'} show={true}>
										{(loading  || loadingUpdate) && <LoadingBox></LoadingBox>}
										<Modal.Header>
											<h1>Editar fecha de inicio - {customId} -</h1>
										</Modal.Header>
										<Modal.Body className='container admin-con align-items-center'>
											<Row className='d-flex mb-3 justify-content-center'>
												<Form.Group className='mb-3 col-3 form-group required' controlId='Date'>
													<Form.Label>Fecha de inicio de deuda</Form.Label>
													<Form.Control
														type='date'
														value={moment(date).utc().format('YYYY-MM-DD')}
														onChange={(e) => setDate(e.target.value)}
													></Form.Control>
												</Form.Group>
												<Form.Group
													className='mb-3 col-4 form-group required'
													controlId='DaysDropdown'
												>
													<GenericDropdown
														label='Día vencimiento de 1er cuota:'
														items={dueDays}
														selected={dueDay? dueDay.toString():''}
														onChange={(e) => setDueDay(e.value)}
													/>
												</Form.Group>
												<Form.Group
													className='mb-3 col-4 form-group required'
													controlId='selectedMonthOption'
												>
													<GenericDropdown
														label='Generar a partir de:'
														items={monthOptions}
														selected={selectedMonthOption}
														onChange={(e) => setSelectedMonthOption(e.value)}
													/>
												</Form.Group>
												{selectedMonthOption === 'Otro' && (
													<Form.Group
														className='mb-3 col-3 form-group required'
														controlId='numMonths'
													>
														<Form.Label>Ingrese en cuantos meses:</Form.Label>
														<Form.Control
															type='number'
															min='1'
															max='240'
															value={numMonths}
															onChange={(e) => setNumMonths(parseInt(e.target.value, 10))}
															onInput={(e) => {
																const inputValue = parseInt(e.target.value, 10);
																const maxValue = 240;
																if (maxValue !== null && inputValue > maxValue) {
																	e.target.value = maxValue;
																}
															}}
														/>
													</Form.Group>
												)}
											</Row>
											<Row>
												<div className='d-flex d-inline justify-content-center align-items-center'>
													<p>
														{date
															? `Primer Vencimiento: ${moment.utc(newFirstDueExpiration).format('DD-MM-YYYY')}  `
															: ''}
													</p>
												</div>
											</Row>
											<Row className='d-flex mb-3 justify-content-center'>
												<Form.Group
													className='mb-3 col-10 form-group  required'
													controlId='selectedMonthOption'
												>
													<GenericDropdown
														name='duesOptions'
														label={
															<>
																<OverlayTrigger
																	trigger='click'
																	right='left'
																	overlay={
																		<Popover id='tooltip-previous-debt' style={{ minWidth: '50%' }}>
																			<Popover.Header as='h3'> A tener en cuenta:</Popover.Header>
																			<Popover.Body style={{ maxHeight: '200px', overflowY: 'auto' }}>
																				<ul style={{ listStyleType: 'circle', paddingLeft: '20px'}}>
																					<li>
																						{' '}
																					Si selecciona Mover Cuotas, se recorren las cuotas
																					existentes y se corrigen las fechas y nombres en base a la
																					fecha de primer vencimiento seleccionada.
																					</li>
																					<li>
																						{' '}
																					Si la fecha de primer vencimiento es previa a la anterior,
																					tiene la opción de: Mover cuotas o Crear cuotas faltantes.
																						<li><b>Crear cuotas faltantes:</b>
																					Se mantienen las fechas de las cuotas actuales, y se
																					agregan cuotas faltantes al inicio.
																						</li>
																					</li>
																					<li>
																						{' '}
																					Si la fecha de primer vencimiento es posterior a la
																					anterior, tiene la opción de: Mover cuotas o Eliminar
																					cuotas sobrantes.
																						<li>
																							<b>Eliminar cuotas sobrantes:</b>
																					Se mantienen las fechas de las cuotas
																					actuales, y se eliminan las cuotas sobrantes desde el
																					inicio.
																					ADVERTENCIA: Si las cuotas a eliminar tienen cobros relacionados, éstos se eliminaran
																						</li>
																					</li>
																				</ul>
																			</Popover.Body>
																		</Popover>
																	}
																>
																	<span style={{ cursor: 'pointer' }}>
																		<MdInfoOutline />
																	</span>
																</OverlayTrigger>
															Que hacer con los Datos existentes:
															</>
														}
														items={updateDuesOptions()}
														onChange={(e) => setSelectedUpdateDateOption(e.value)}
													/>
												</Form.Group>
											</Row>
											<Row className='modal-btns'>
												<Col>
													<Button className='cancelar' onClick={() => navigate(-1)}
													>
													Cancelar
													</Button>
												</Col>
												<Col>
													<Button className='guardar' onClick={updateDebtDateHandler}>
													Guardar
													</Button>
												</Col>
											</Row>
										</Modal.Body>
									</Modal>
								</div>
							</Form>
						</div>
				}
			</Modal>
		</div>
	);
}
export default EditFirstDueDate;